import React from "react";
import { BsArrowRightShort } from "react-icons/bs";
import "../Onboarding.css";

const Step2 = ({
  onboardingInputs,
  setOnboardingInputs,
  setOnboardingStepNumber,
}) => {
  return (
    <>
      <div className="appOnboardingHeader">
        <img
          src="/blueTransparent.png"
          className="appOnboardingLogo"
          alt="Airstrip Logo"
        />
        <h1 className="appOnboardingHeaderText">Tell us about your company</h1>
        <p className="appOnboardingHeaderDescription">
          This helps us personalize your legal documents and provide more
          accurate assistance.
        </p>
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          setOnboardingStepNumber(3);
        }}
        className="appOnboardingMain"
      >
        <p className="stepLabel">Step 2 of 3</p>

        <div className="appOnboardingInputContainer">
          <label className="label" htmlFor="companyDescription">
            Short Description about your business
          </label>
          <textarea
            className="input textarea"
            autoFocus
            placeholder="Describe what your company does, your industry, and any specific legal needs you might have..."
            id="companyDescription"
            value={onboardingInputs.description}
            onChange={(e) => {
              setOnboardingInputs({
                ...onboardingInputs,
                description: e.target.value,
              });
            }}
            required
            minLength={50}
            style={{ minHeight: "120px" }}
          />
          <p className="input-helper-text">
            {onboardingInputs.description.length < 50
              ? `Please enter at least ${
                  50 - onboardingInputs.description.length
                } more characters`
              : "✓ Looks good!"}
          </p>
        </div>

        <div className="appOnboardingMainButtons">
          <button
            type="submit"
            className="onboardingNextButton"
            disabled={onboardingInputs.description.length < 50}
            style={{
              opacity: onboardingInputs.description.length < 50 ? 0.6 : 1,
            }}
          >
            <p className="onboardingButtonText">Continue</p>
            <BsArrowRightShort size={18} />
          </button>

          <div
            className="appOnboardingBackButton"
            onClick={() => setOnboardingStepNumber(1)}
          >
            Back
          </div>
        </div>
      </form>
    </>
  );
};

export default Step2;
