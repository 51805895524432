import { animated } from "@react-spring/web";
import { useEffect, useState } from "react";
import {
  BsCheckCircle,
  BsInfo,
  BsInfoCircle,
  BsQuestionCircleFill,
} from "react-icons/bs";
import { SiCodereview } from "react-icons/si";
import { useDispatch, useSelector } from "react-redux";
import {
  getDocumentChatMessagesAPI,
  getDocumentChatsAPI,
  getUserAPI,
  getUserChatLimitsAPI,
  saveDocumentChatAPI,
} from "../../api/apiCalls";

import { useParams } from "react-router-dom";
import { v4 } from "uuid";

import { ArrowUp } from "lucide-react";
import {
  BiChalkboard,
  BiHistory,
  BiQuestionMark,
  BiUpvote,
} from "react-icons/bi";
import { v4 as uuidv4 } from "uuid";
import { updateDocument as updateDocumentReducer } from "../../redux/actions/documentActions";
import { useDocumentElementsContext } from "./FinalDocumentContext";

import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { MdAdd, MdClear, MdClearAll, MdTipsAndUpdates } from "react-icons/md";
import { CgAdd, CgInfo, CgInsights } from "react-icons/cg";
import AvatarDemo from "../Avatar";
import { AssistantMessage } from "../AssistantMessage";
import DropdownMenuDemo from "../DropdownMenu";
import { Tooltip } from "../Tooltip";
import { updateUser } from "../../redux/actions/userAction";
import {
  PiSealWarningFill,
  PiWarningCircle,
  PiWarningCircleFill,
} from "react-icons/pi";
import { UpdateIcon } from "@radix-ui/react-icons";
import { IoWarning } from "react-icons/io5";

export const FinalDocumentChat = ({
  chatSectionProps,
  toggleChat,
  chatInfo,
  updatingElementId,
  updatingTextStream,
  setUpdatingElementId,
  setUpdatingTextStream,
  updatingElementData,
  refreshDocument: refreshDocumentState,
}) => {
  const icons = {
    question: () => (
      <BsQuestionCircleFill className="finalDocumentChatsHeaderIcon" />
    ),
    overview: () => <SiCodereview className="finalDocumentChatsHeaderIcon" />,
  };

  const { id, versionId } = useParams();
  const dispatch = useDispatch();

  const [refreshDocument, setRefreshDocument] = refreshDocumentState;

  const { documentElementsArray, updateArray: setDocumentElementsArray } =
    useDocumentElementsContext();
  const { documentInfo, currentChatId } = useSelector(
    (state) => state.document
  );
  const {
    token,
    name,
    chatInput = "",
    chatLimits = { total_available: 0, used: 0 },
  } = useSelector((state) => state.user);

  const [updatingElementInfo, setUpdatingElementInfo] = updatingElementData;

  const [messages, setMessages] = useState([]);

  const setChatInput = (input) => {
    dispatch(updateUser({ chatInput: input }));
  };

  const [chats, setChats] = useState([]);

  const [streamMessage, setStreamMessage] = useState("");
  const [streamStatus, setStreamStatus] = useState("");

  const scrollChat = () => {
    if (document) {
      const element = document.getElementsByClassName(
        "finalDocumentPopupChats"
      );
      element[0].scrollTo({
        top: element[0].scrollHeight - 150, // Scroll a little bit above the end
        behavior: "smooth",
      });

      setTimeout(() => {
        element[0].scrollTo({
          top: element[0].scrollHeight - 150, // Scroll a little bit above the end
          behavior: "smooth",
        });
      }, 1500);
    }
  };

  const updateDocument = (targetId, newParagraph, action = "ADD") => {
    const updatedArray = [];
    let foundTarget = false;

    for (const item of documentElementsArray) {
      if (action === "UPDATE_CLAUSE" && item.id === targetId) {
        updatedArray.push({
          ...item,
          text: newParagraph.text,
        });
        foundTarget = true;
      } else if (item.type === "SUBTITLE" && item.id === targetId) {
        foundTarget = true;
        updatedArray.push(item);
        updatedArray.push(newParagraph);
      } else if (foundTarget && item.type === "SUBTITLE") {
        foundTarget = false;
        updatedArray.push(item);
      } else {
        updatedArray.push(item);
      }
    }

    if (
      foundTarget &&
      action === "ADD" &&
      !updatedArray.includes(newParagraph)
    ) {
      updatedArray.push(newParagraph);
    }

    return updatedArray;
  };

  useEffect(() => {
    getDocumentChats();
  }, []);

  const updateFinalChatObject = (object) => {
    const updatedDocumentArray = updateDocument(object.id, {
      type: "paragraph",
      id: uuidv4(),
      text: object.content,
    });

    setDocumentElementsArray(updatedDocumentArray);
    setUpdatingElementId("");

    dispatch(
      updateDocumentReducer({
        unsavedChanges: true,
      })
    );

    setTimeout(() => {
      let newChats = [...chats];
      newChats.push({
        role: "assistant",
        content: `${object.summary.map((eachSummary) => `${eachSummary}\n\n`)}`,
      });
      setChats(newChats);
      setStreamMessage("");
      setStreamStatus("NOT_STARTED");
    }, 5000);
  };

  const [finalDocumentObject, setFinalDocumentObject] = useState(null);

  let newElement = null;

  let localUpdatingObject = {};

  async function readStream(stream) {
    const reader = stream.getReader();

    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        setStreamStatus("COMPLETED");
        focusSearchInput();

        if (streamMessage && streamMessage) {
          const hasToolTags =
            streamMessage.includes("<tool>") &&
            streamMessage.includes("<tool_result>");
          if (hasToolTags) {
            setRefreshDocument(true);

            const interval = setInterval(() => {
              const buttons = document.querySelectorAll(
                ".latestDocumentButton"
              );
              if (buttons.length > 0) {
                const lastButton = buttons[buttons.length - 1];
                console.log("Last button found:", lastButton); // Log when the last button is found
                lastButton.classList.add("highlighted");

                // Scroll to the button
                lastButton.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                });

                setTimeout(() => {
                  lastButton.classList.remove("highlighted");
                  // Scroll to the top after 2 seconds
                  setTimeout(() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }, 2000);
                }, 2000); // Remove the class after 2 seconds
                clearInterval(interval); // Stop checking once the last button is found
              }
            }, 500); // Check every 500ms

            setTimeout(() => {
              console.log("Stopping interval after 10 seconds"); // Log when the interval is stopped
              clearInterval(interval); // Stop checking after 10 seconds
            }, 10000);
          }
        }

        // let chatId = await saveDocumentChatAPI({
        //   draftId: id,
        //   docChatID: currentChatId,
        //   messages: [...chats, { role: "assistant", content: streamMessage }],
        // });

        // setCurrentChatId(chatId);

        return;
      }

      const textDecoder = new TextDecoder();
      const chunk = textDecoder.decode(value);

      const jsonStrings = chunk.match(/\{[^}]+\}/g) || [];

      for (const jsonString of jsonStrings) {
        try {
          const parsed = JSON.parse(jsonString);
          if (parsed.type === "stream") {
            setStreamMessage((prev) => prev + parsed.data);
            scrollChat();
          }
        } catch (err) {
          console.log("Error processing JSON:", err);
          continue;
        }
      }
    }
  }

  const focusSearchInput = () => {
    if (document) {
      const input = document.getElementById("chatInput");
      setTimeout(function () {
        input?.focus();
      }, 50);
    }
  };

  const getDocumentTexts = () => {};

  const addToChat = async (loadedChats) => {
    setStreamStatus("PENDING");

    setTimeout(() => {
      const element = document.querySelector(".loadingChat");
      if (element) element.scrollIntoView();
    }, 200);

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/chatbot/question-document`,
      {
        method: "post",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          api_key: "krishna",
          Authorization: token,
        },
        body: JSON.stringify({
          draftId: id,
          messages: loadedChats,
          version: versionId
            ? parseInt(versionId) - 1
            : documentInfo?.document_drafts.length - 1,
        }),
      }
    );

    if (response.ok) {
      await readStream(response.body);
    } else {
      setStreamStatus("NOT_STARTED");
    }
  };

  const [userInfo, setUserInfo] = useState(null);

  const getUserInfo = async () => {
    const response = await getUserAPI();
    if (response.data) {
      setUserInfo(response.data);
    }
  };

  const [documentChats, setDocumentChats] = useState([]);

  const getDocumentChats = async () => {
    const response = await getDocumentChatsAPI({
      draftId: id,
    });
    if (response.data) {
      setDocumentChats(response.data);
    }
  };

  const getCurrentDocumentChat = async () => {
    console.log("getting messages");
    const response = await getDocumentChatMessagesAPI({
      draftId: id,
      docChatID: currentChatId,
    });
    if (response.data) {
      console.log("got", response.data);
      setChats(response.data.messages);
    }
  };

  useEffect(() => {
    getUserInfo();

    console.log("currentChatId", currentChatId);

    if (currentChatId) {
      getCurrentDocumentChat();
    }
  }, [currentChatId]);

  const chatWithDocument = async (e, needToAddMessage = true) => {
    if (e) e.preventDefault();

    setStreamStatus("PENDING");
    let loadedChats = [];
    setChats((prev) => {
      loadedChats = [...prev, { role: "user", content: chatInput }];
      return [...prev, { role: "user", content: chatInput }];
    });
    setChatInput("");

    // set the height of the textarea to 110px
    const chatInputTextarea = document.querySelector(".finalDocumentChatInput");
    if (chatInputTextarea) {
      chatInputTextarea.style.height = "110px";
    }

    const response = await getUserChatLimits();
    if (response.remaining === 0) {
      scrollChat();

      // If no remaining chats, remove the last user input from chats and add it back to the input
      setChats((prev) => {
        const updatedChats = prev.slice(0, -1);
        return updatedChats;
      });
      setChatInput(loadedChats[loadedChats.length - 1].content);
      setStreamStatus("NOT_STARTED");

      dispatch(
        updateUser({
          showUpgradeModal: true,
        })
      );
    } else {
      if (chatInfo.length !== 0 && streamStatus !== "PENDING") {
        addToChat(loadedChats);
      }
    }
  };

  useEffect(() => {
    getDocumentTexts();

    if (chatInfo) {
      if (chatInfo.data && chatInfo.data.autoSend) {
        let loadedChats = [];
        setChats((prev) => {
          loadedChats = [
            ...prev,
            { role: "user", content: chatInfo.data.question },
          ];
          return [...prev, { role: "user", content: chatInfo.data.question }];
        });
      }

      addToMessages("user", chatInfo.title);
    }
  }, [chatInfo]);

  const addToMessages = (type, text) => {
    setMessages([
      ...messages,
      {
        id: v4(),
        role: type,
        content: text,
      },
    ]);
  };

  const handleSubmit = async (e) => {
    chatWithDocument(e);
  };

  const saveChats = async (chatsArray) => {
    let response = await saveDocumentChatAPI({
      draftId: id,
      docChatID: currentChatId,
      messages: chatsArray,
    });

    dispatch(
      updateDocumentReducer({
        currentChatId: response.data.id,
      })
    );
  };

  useEffect(() => {
    if (
      streamMessage.length > 0 &&
      streamStatus === "COMPLETED" &&
      (!updatingElementId || updatingElementId?.length === 0)
    ) {
      let newChats = [...chats, { role: "assistant", content: streamMessage }];

      setChats(newChats);
      setStreamMessage("");
      setStreamStatus("NOT_STARTED");

      saveChats(newChats);
    }
  }, [streamStatus, chats]);

  useEffect(() => {
    if (streamStatus === "COMPLETED") {
      dispatch(
        updateUser({
          chatLimits: {
            total_available:
              typeof chatLimits.total_available === "number"
                ? chatLimits.total_available
                : 0,
            used: typeof chatLimits.used === "number" ? chatLimits.used + 1 : 0,
          },
        })
      );
    }
  }, [streamStatus]);

  const [showFullChats, setShowFullChats] = useState(false);

  let timer;
  var scrolling = false;

  function makeTransparent() {
    let overlay = document.getElementById("overlay");

    overlay.style.backgroundColor = "transparent";
  }

  function resetTimer() {
    let overlay = document.getElementById("overlay");

    makeTransparent();
    clearTimeout(timer);
    timer = setTimeout(function () {
      if (!scrolling) {
        overlay.style.backgroundColor = "#191f2fcf";
      }
    }, 1000);
  }

  function startTimer() {
    let overlay = document.getElementById("overlay");

    clearTimeout(timer);
    timer = setTimeout(function () {
      if (!scrolling) {
        overlay.style.backgroundColor = "#191f2fcf";
      }
    }, 1000);
  }

  const handleScroll = () => {
    let overlay = document.getElementById("overlay");

    if (overlay) {
      scrolling = true;
      clearTimeout(timer);
      makeTransparent();
      timer = setTimeout(function () {
        scrolling = false;
        overlay.style.backgroundColor = "#191f2fcf";
      }, 1000);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [showingResearch, setShowingResearch] = useState([-1]);
  const [showingReasoning, setShowingReasoning] = useState([-1]);

  const toggleShowingResearch = (id) => {
    setShowingResearch((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const toggleShowingReasoning = (id) => {
    setShowingReasoning((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const changeChat = (chatId) => {
    dispatch(
      updateDocumentReducer({
        currentChatId: chatId,
      })
    );
  };

  const ChatHeader = () => {
    return (
      <div className="finalDocumentPopupChatsTopbar">
        <div className="headerMainChat">
          <p className="documentChatName">Chat · </p>
          {chatLimits.total_available === 5 && (
            <p
              className={`chatLimitText ${
                chatLimits.used >= chatLimits.total_available
                  ? "chatLimitReached"
                  : ""
              }`}
              style={{ marginLeft: "5px" }}
            >
              {"  "}
              {chatLimits.used < chatLimits.total_available && (
                <PiSealWarningFill
                  color="orange"
                  className="warningIcon"
                  style={{ marginRight: "4px" }}
                />
              )}
              <p>
                {chatLimits.used} / <b>{chatLimits.total_available}</b> messages
                used{" "}
              </p>
              {chatLimits.total_available === 5 && (
                <p
                  className="upgradeChatLimitText"
                  onClick={() => {
                    dispatch(
                      updateUser({
                        showUpgradeModal: true,
                      })
                    );
                  }}
                  style={{ fontWeight: "500", backgroundColor: "#4f37e8" }}
                >
                  <BiUpvote />
                  <p style={{ marginLeft: "3px" }}>
                    {" "}
                    Unlock <b>200</b> more with{" "}
                    <span className="mini_pro_badge">Pro</span>
                  </p>
                </p>
              )}
            </p>
          )}
        </div>
        <div className="documentChatTools">
          <MdAdd
            className="documentChatToolIcon"
            onClick={() => {
              dispatch(
                dispatch(
                  updateDocumentReducer({
                    currentChatId: null,
                  })
                )
              );
              setChats([]);
            }}
          />
          {/* <MdClear className="documentChatToolIcon" /> */}
          {documentChats && (
            <DropdownMenuDemo
              items={documentChats.map((eachChat) => {
                const updatedAt = new Date(eachChat.updatedAt);
                const formattedUpdatedAt = `${updatedAt.toLocaleString(
                  "default",
                  {
                    month: "short",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                  }
                )}`.slice(0, 16);
                return {
                  disabled: false,
                  label: `${eachChat.title}`,
                  shortcut: formattedUpdatedAt,
                  onClick: changeChat,
                  id: eachChat.id,
                };
              })}
              length={documentChats.length}
              size="small"
            />
          )}
        </div>
      </div>
    );
  };

  const documentUpdatingFunction = (type) => {
    if (type === "doing") {
      dispatch(
        updateDocumentReducer({
          documentUpdating: true,
        })
      );
    } else if (type === "done") {
      dispatch(
        updateDocumentReducer({
          documentUpdating: false,
        })
      );
    }
  };

  const getUserChatLimits = async () => {
    const response = await getUserChatLimitsAPI();
    if (response.data) {
      dispatch(
        updateUser({
          chatLimits: {
            used: response.data.totalMessages,
            total_available: response.data.messageLimit,
          },
        })
      );
    }

    return response.data;
  };

  useEffect(() => {
    getUserChatLimits(); // Fetch immediately
    const interval = setInterval(() => {
      getUserChatLimits(); // Fetch every 10 seconds
    }, 10000);
    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  if (userInfo) {
    return (
      <>
        <animated.div
          style={chatSectionProps}
          className={`finalDocumentRightSectionPopup finalDocumentRightSectionPopupWithChats
        `}
        >
          <div className="finalDocumentPopupChatSection">
            {chats.length === 0 && (
              <div className="getStartedFinalDocumentChat">
                <ChatHeader />

                <div className="getStartedFinalDocumentChatContainer">
                  <div className="getStartedFinalDocumentChatHeader">
                    <p className="getStartedFinalDocumentChatHeaderText">
                      Hey, it's Pilot!
                    </p>
                    <p className="getStartedFinalDocumentChatDescription">
                      Here's what I can help you with:
                    </p>
                  </div>

                  <div className="getStartedFinalDocumentSection">
                    <p
                      className="getStartedFinalDocumentChatSectionText"
                      onClick={() =>
                        setChatInput(
                          "Change the contact email to support@example.com"
                        )
                      }
                    >
                      <MdTipsAndUpdates className="getStartedFinalDocumentChatSectionTextIcon" />{" "}
                      <div className="getStartedFinalDocumentChatSectionTextContent">
                        <p style={{ fontWeight: "500", fontSize: "13px" }}>
                          Make Accurate Changes to the document
                        </p>
                        <p className="getStartedFinalDocumentChatSectionTextSection">
                          Change the contact email to support@example.com
                        </p>
                      </div>
                    </p>
                    <p
                      className="getStartedFinalDocumentChatSectionText"
                      onClick={() =>
                        setChatInput(
                          "Summarize the key points of this document"
                        )
                      }
                    >
                      <BiQuestionMark className="getStartedFinalDocumentChatSectionTextIcon" />{" "}
                      <div className="getStartedFinalDocumentChatSectionTextContent">
                        <p style={{ fontWeight: "500", fontSize: "13px" }}>
                          Clarify your questions
                        </p>
                        <p className="getStartedFinalDocumentChatSectionTextSection">
                          Summarize the key points of this document
                        </p>
                      </div>
                    </p>
                    <p
                      className="getStartedFinalDocumentChatSectionText"
                      onClick={() =>
                        setChatInput("Highlight the important clauses")
                      }
                    >
                      <CgInsights className="getStartedFinalDocumentChatSectionTextIcon" />{" "}
                      <div className="getStartedFinalDocumentChatSectionTextContent">
                        <p style={{ fontWeight: "500", fontSize: "13px" }}>
                          Get Document Insights
                        </p>
                        <p className="getStartedFinalDocumentChatSectionTextSection">
                          Highlight the important clauses
                        </p>
                      </div>
                    </p>
                  </div>

                  {chatLimits.total_available - chatLimits.used <= 2 && (
                    <div className="chatCreditsLowContainer">
                      <IoWarning className="creditsLowIcon" />
                      <div>
                        <h1>
                          You only have{" "}
                          {Math.max(
                            0,
                            chatLimits.total_available - chatLimits.used
                          )}{" "}
                          messages left
                        </h1>
                        <p>
                          Upgrade to pro to get <b>advanced insights</b> and{" "}
                          <b>document updates</b> from Pilot. Plus, 200{" "}
                          messages/month.
                        </p>

                        <div
                          className="upgradeChatLimitText"
                          onClick={() => {
                            dispatch(
                              updateUser({
                                showUpgradeModal: true,
                              })
                            );
                          }}
                          style={{
                            fontWeight: "500",
                            backgroundColor: "green !important",
                            color: "white",
                            width: "fit-content",
                            margin: "0px",
                            padding: "3px 6px",
                            borderRadius: "15px",
                            display: "flex",
                            alignItems: "center",
                            marginTop: "10px",
                          }}
                        >
                          <BiUpvote style={{ marginRight: "5px" }} />
                          <span style={{ fontSize: "12px" }}>
                            Upgrade to Pro
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            {chats.length !== 0 && (
              <div className="finalDocumentPopupChats">
                <ChatHeader />

                {chatLimits.total_available - chatLimits.used <= 2 && (
                  <div
                    className="chatCreditsLowContainer"
                    style={{ marginLeft: "20px", marginTop: "10px" }}
                  >
                    <IoWarning className="creditsLowIcon" />
                    <div>
                      <h1>
                        You only have{" "}
                        {Math.max(
                          0,
                          chatLimits.total_available - chatLimits.used
                        )}{" "}
                        messages left
                      </h1>
                      <p>
                        Upgrade to pro to get <b>200</b> messages
                      </p>

                      <div
                        className="upgradeChatLimitText"
                        onClick={() => {
                          dispatch(
                            updateUser({
                              showUpgradeModal: true,
                            })
                          );
                        }}
                        style={{
                          fontWeight: "500",
                          background: "#4f37e820 !important",
                          color: "white",
                          width: "fit-content",
                          margin: "0px",
                          padding: "3px 6px",
                          borderRadius: "15px",
                          display: "flex",
                          alignItems: "center",
                          marginTop: "10px",
                        }}
                      >
                        <BiUpvote style={{ marginRight: "5px" }} />
                        <span style={{ fontSize: "11px" }}>Upgrade to Pro</span>
                      </div>
                    </div>
                  </div>
                )}

                {chats.map((eachMessage, chatIndex) => {
                  if (eachMessage.role === "user") {
                    return (
                      <div className="eachChat rightChat">
                        <AvatarDemo name={userInfo.name} />

                        {eachMessage.content.includes("<accepted>") ? (
                          <div className="approvedDocumentUpdate">
                            <BsCheckCircle className="approvedIcon" />
                            <p>Approved document update</p>
                          </div>
                        ) : (
                          <p className="eachChatText">{eachMessage.content}</p>
                        )}
                      </div>
                    );
                  } else {
                    return (
                      <>
                        <div className="eachChat leftChat">
                          {/* <p className="userChatName">PILOT</p> */}

                          <div className="eachChatMarkdown">
                            {/* <Markdown remarkPlugins={[remarkGfm]}>
                              {eachMessage.content}
                            </Markdown> */}

                            <AssistantMessage
                              content={eachMessage.content}
                              chatIndex={chatIndex}
                              stream={true}
                              role="assistant"
                              toggleShowingReasoning={toggleShowingReasoning}
                              toggleShowingResearch={toggleShowingResearch}
                              showingReasoning={showingReasoning}
                              showingResearch={showingResearch}
                              documentUpdating={documentUpdatingFunction}
                            />

                            <hr />

                            <p
                              style={{
                                fontSize: "12px",
                                color: "gray",
                                fontStyle: "italic",
                              }}
                            >
                              <BsInfoCircle /> {"  "} Ensure you're viewing the{" "}
                              <a
                                href="#"
                                onClick={() => window.location.reload()}
                              >
                                latest version
                              </a>{" "}
                              for updates.
                            </p>
                          </div>
                        </div>
                      </>
                    );
                  }
                })}

                {streamStatus === "PENDING" && (
                  <div className="eachChat leftChat loadingChat">
                    {/* <p className="userChatName">PILOT</p> */}

                    {streamMessage.length === 0 ? (
                      "Thinking..."
                    ) : (
                      <div className="eachChatMarkdown prose prose-sm max-w-none my-4">
                        <AssistantMessage
                          content={streamMessage}
                          chatIndex={-1}
                          stream={true}
                          role="assistant"
                          toggleShowingReasoning={toggleShowingReasoning}
                          toggleShowingResearch={toggleShowingResearch}
                          showingReasoning={showingReasoning}
                          showingResearch={showingResearch}
                          documentUpdating={documentUpdatingFunction}
                        />
                      </div>
                    )}

                    <div className="loadingCircle"></div>
                  </div>
                )}
              </div>
            )}

            <div
              className={`finalDocumentChatInputContainer ${
                showFullChats && "focusedInput"
              }`}
            >
              <form
                onSubmit={(e) => chatWithDocument(e)}
                className="finalDocumentChatInputContent"
              >
                <textarea
                  type="text"
                  className="finalDocumentChatInput"
                  placeholder={
                    showFullChats
                      ? "Try 'Give me the key points of this document'"
                      : "Hi, I am Pilot, I'll help you with this document"
                  }
                  value={chatInput}
                  onChange={(e) => setChatInput(e.target.value)}
                  onFocus={() => setShowFullChats(true)}
                  onBlur={() => setShowFullChats(false)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      chatWithDocument(e);
                    }
                  }}
                />

                <button className={`finalDocumentChatInputContentArrow`}>
                  <ArrowUp
                    type="submit"
                    fontSize={14}
                    onClick={(e) => chatWithDocument(e)}
                  />
                </button>
              </form>
            </div>
            {chatLimits.total_available === 200 && (
              <p
                className="chatLimitText"
                style={{ flexDirection: "column", alignItems: "baseline" }}
              >
                <div
                  title="Resets every month"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <p style={{ marginLeft: "3px" }}>
                    {chatLimits.used} / <b>{chatLimits.total_available}</b> used{" "}
                  </p>
                </div>

                {chatLimits.total_available === 5 && (
                  <p
                    className="upgradeChatLimitText"
                    onClick={() => {
                      dispatch(
                        updateUser({
                          showUpgradeModal: true,
                        })
                      );
                    }}
                  >
                    Get <b>200</b> messages with{" "}
                    <span className="mini_pro_badge">Pro</span>
                  </p>
                )}
              </p>
            )}
          </div>
        </animated.div>
      </>
    );
  }
};
