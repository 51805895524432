import api from ".";

export const getUserAPI = async () => {
  const response = await api.get(`/user/`);
  return response;
};

export const getUserChatLimitsAPI = async () => {
  const response = await api.get(`/user/chat-limits`);
  return response;
};

export const registerAPI = async (data) => {
  const response = await api.post(`/user/register`, data);
  return response;
};

export const resetPasswordAPI = async (data) => {
  const response = await api.post(`/user/reset-password`, data);
  return response;
};

export const signinAPI = async (data) => {
  const response = await api.post(`/user/login`, data);
  return response;
};

export const sendVerificationCodeAPI = async (data) => {
  const response = await api.post(`user/send-verification-code`, data);
  return response;
};

export const customisePilotAPI = async (data) => {
  const response = await api.post(`/user/customise-pilot`, data);
  return response;
};

export const customisePilotFromWebsiteAPI = async (data) => {
  const response = await api.post(`/user/customise-from-website`, data);
  return response;
};

export const getCustomisedDetailsAPI = async () => {
  const response = await api.get(`/user/customise-details`);
  return response;
};

export const verifyCodeAPI = async (data) => {
  const response = await api.post(`user/verify-code`, data);
  return response;
};

export const getLifetimeAccessPlan = async (data) => {
  const response = await api.post(`/user/check-lifetime-access-status`, data);
  return response;
};

export const addUpdateAPIKeyAPI = async (data) => {
  const response = await api.post(`/user/add-update-apikey`, data);
  return response;
};

export const getAPIKeyAPI = async () => {
  const response = await api.post(`/user/get-apikey`);
  return response;
};

export const upgradePlanAPI = async (data) => {
  const response = await api.post(`payment/upgrade-plan`, data);
  return response;
};

export const addStartupOnboardingDetailsAPI = async (data) => {
  const response = await api.post(`user/onboarding`, data);
  return response;
};

export const getAllDocumentsAPI = async () => {
  const response = await api.get("/document");
  return response;
};

export const getMyDraftsAPI = async () => {
  const response = await api.get(`/draft`);
  return response;
};

export const searchDraftsAPI = async (query) => {
  const response = await api.get(`/draft/search/${query === "" ? "a" : query}`);
  return response;
};

export const searchDocumentsAPI = async (data) => {
  const response = await api.post(`/document/search-documents`, data);
  return response;
};

export const createCustomDraftAPI = async (data) => {
  const response = await api.post(`/document/create-custom-draft`, data);
  return response;
};

export const getDocumentAPI = async (documentId) => {
  const response = await api.get(`document/${documentId}`);
  return response;
};

export const getDocumentProcessAPI = async (processId) => {
  const response = await api.get(`/process/${processId}`);
  return response;
};

export const validateAnswerAPI = async (data) => {
  const response = await api.post(`/process/validate-answer`, data);
  return response;
};

export const getDocumentDraftAPI = async (draftId) => {
  const response = await api.get(`/draft/${draftId}`);
  return response;
};

export const downloadDraftAPI = async (draftId) => {
  const response = await api.post(`/draft/${draftId}/download`);
  return response;
};

export const getDocumentRemindersAPI = async (draftId) => {
  const response = await api.get(`/draft/${draftId}/reminders`);
  return response;
};

export const createDocumentDraftAPI = async (data) => {
  const response = await api.post("/draft/create", data);
  return response;
};

export const deleteDocumentDraftAPI = async (data) => {
  const response = await api.post("/draft/delete", data);
  return response;
};

export const updateName = async (data) => {
  const response = await api.post("/draft/update-name", data);
  return response;
};

export const streamDocumentDraftAPI = async (data) => {
  const response = await api.post("/draft/draft-document", data);
  return response;
};

export const getDocumentOverview = async (data) => {
  const response = await api.post("/draft/overview", data);
  return response;
};

export const uploadOwnDocument = async (data) => {
  const response = await api.post("/draft/upload-own-document", data);
  return response;
};

export const saveDocumentOverviewAPI = async (data) => {
  const response = await api.post("/draft/overview-save ", data);
  return response;
};

export const createSignatureAPI = async (data) => {
  const response = await api.post("/signature/create", data);
  return response;
};

export const sendForSignatureAPI = async (data) => {
  const response = await api.post("/signature/send-signature", data);
  return response;
};

export const saveDraftDocumentAPI = async (data) => {
  const response = await api.post("/draft/save-drafted-document", data);
  return response;
};

export const submitForReviewAPI = async (data) => {
  const response = await api.post("/draft/submit-for-review", data);
  return response;
};

export const getSignedDocument = async (draftId) => {
  const response = await api.get(`/document/docusign-pdf/${draftId}`, {
    responseType: "arraybuffer",
  });
  return response;
};

export const getSignLinkAPI = async (requestId) => {
  const response = await api.post(`/signature/signature-request-url`, {
    requestId: requestId,
  });

  return response;
};

export const getSignatureStatusAPI = async (draftId) => {
  const response = await api.get(
    `/signature/signature-status?draft=${draftId}`
  );

  return response;
};

export const sendContextAndCreateProcess = async (data) => {
  const response = await api.get(`/process/create`, data);
  return response;
};

export const chatAPI = async (data) => {
  const response = await api.get(`/chat`, data);
  return response;
};

export const documentChatAPI = async (data) => {
  const response = await api.get(`/chat/question-document`, data);
  return response;
};

export const saveDocumentChatAPI = async (data) => {
  const response = await api.post(`/chatbot/question-document/save`, data);
  return response;
};

export const getDocumentChatsAPI = async (data) => {
  const response = await api.post(
    `/chatbot/question-document/document-chats`,
    data
  );
  return response;
};

export const getDocumentChatMessagesAPI = async (data) => {
  const response = await api.post(
    `/chatbot/question-document/document-chat`,
    data
  );
  return response;
};

export const getDocumentsForScenarioAPI = async (data) => {
  const response = await api.get(`/document/find-documents-scenario`, data);
  return response;
};

export const getDocumentPaidStatusAPI = async (data) => {
  const response = await api.post(`/payment/get-access-status`, data);
  return response;
};

export const getDocumentPaymentLinkAPI = async (data) => {
  const response = await api.post(`/payment/payment-link`, data);
  return response;
};

export const createBlackFridayPlanLink = async (data) => {
  const response = await api.post(`/payment/special-offer-plan`, data);
  return response;
};

// flow

export const createFlowAPI = async (data) => {
  const response = await api.post(`/flow/create-flow`, data);
  return response;
};

export const getInitialInformationAPI = async (data) => {
  const response = await api.post(`/flow/collect-intial-information`, data);
  return response;
};

export const makeFlowPlanAPI = async (data) => {
  const response = await api.post(`/flow/process-plan`, data);
  return response;
};

export const getFlowStatusAPI = async (data) => {
  const response = await api.post(`/flow/get-flow-status`, data);
  return response;
};

// export const getInitialInformationChat = async (data) => {
//   const response = await api.post(`/flow/get-initial-information-chat`, data);
//   return response;
// };

export const getPlanReviewChecklistAPI = async (data) => {
  const response = await api.post(`/flow/get-plan`, data);
  return response;
};

export const updateClauseAPI = async (data) => {
  const response = await api.post(`/flow/update-clause`, data);
  return response;
};

export const getPendingFlowsAPI = async () => {
  const response = await api.post(`/flow/get-pending-flows`);
  return response;
};

export const shareDocumentAPI = async (data) => {
  const response = await api.post(`/draft/share`, data);
  return response;
};

export const getShareableLinkAPI = async (draftId) => {
  const response = await api.post(`/draft/share`, { draftId });
  return response;
};

export const getSharedDraftAPI = async (token) => {
  const response = await api.get(`/draft/shared/${token}`);
  return response;
};
