import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NextStepButton } from "./NextStepButton";
import { Suggestions } from "./Suggestions";
import { updateStepper } from "../../redux/actions/stepperActions";
import { useSearchParams } from "react-router-dom";
import { DocumentDetailLoader } from "../Loaders/DocumentDetailLoader";

import { animated, easings, useSpring } from "@react-spring/web";
import { AiOutlineExclamation } from "react-icons/ai";
import { PiListFill, PiWarningCircleFill } from "react-icons/pi";
import { RiLightbulbFlashFill } from "react-icons/ri";
import { Oval } from "react-loader-spinner";
import { CgAdd, CgStark } from "react-icons/cg";
import { IoAdd } from "react-icons/io5";
import { IoIosAddCircle } from "react-icons/io";
import { BiListMinus, BiTab } from "react-icons/bi";
import { ChevronDown, CommandIcon, RefreshCcw } from "lucide-react";

export const GeneralInputStep = () => {
  const { steps, documentDetails, stepTitles, warnings, currentStep } =
    useSelector((state) => state.stepper);
  const { creatingProcess, location } = useSelector((state) => state.document);
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  const [showSuggestions, setShowSuggestions] = useState(false);

  const [openedSuggestions, setOpenedSuggestions] = useState([]);

  const inputRef = useRef();

  const isSuggestionOpened = (id) =>
    openedSuggestions.filter((suggestion) => suggestion === id)[0];

  const toggleSuggestionOpen = (id) => {
    if (isSuggestionOpened(id)) {
      const newOpenedSuggestionsArray = openedSuggestions.filter(
        (suggestion) => suggestion !== id
      );
      setOpenedSuggestions(newOpenedSuggestionsArray);
    } else {
      setOpenedSuggestions([...openedSuggestions, id]);
    }
  };

  const [selectedSuggestions, setSelectedSuggestions] = useState([]);

  const updateInput = (value) => {
    let newStepsArray = [...steps];

    newStepsArray[currentStep - 1] = {
      ...newStepsArray[currentStep - 1],
      error: null,
      inputs: {
        value: value,
      },
    };

    dispatch(
      updateStepper({
        steps: newStepsArray,
      })
    );
  };

  // // // //"console", steps[currentStep - 1]);

  useEffect(() => {
    // // //steps[currentStep - 1]);
    // // //creatingProcess);

    // // //stepTitles);

    setSelectedSuggestions([]);

    // setSelectedSuggestion({
    //   index: null,
    //   title: null,
    //   description: null,
    // });
    setOpenedSuggestions([]);
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      if (inputRef) inputRef?.current?.focus();
    }, 200);
  }, [currentStep]);

  const [modalProps, setmodalProps] = useSpring(() => ({
    from: {
      opacity: 0,
      marginTop: 20,
    },
    to: {
      opacity: 1,
      marginTop: 0,
    },
  }));

  const getExampleSuggestion = (message) => {
    const regex = /"([^"]*)"/;
    const matches = message?.match(regex);
    const extractedText = matches ? matches[1] : null;

    return extractedText;
  };

  const currentStepWarning = () => {
    return warnings.filter(
      (eachWarning) => eachWarning.step + 1 === currentStep
    )[0];
  };

  useEffect(() => {
    console.log(steps);
  }, []);

  // Extract prefilled content if it exists
  const currentStepTitle = steps[currentStep - 1]?.title || "";
  const hasPrefilled = currentStepTitle.includes("<prefilled>");
  const prefilledContent = hasPrefilled
    ? currentStepTitle.split("<prefilled>")[1].split("</prefilled>")[0]
    : null;

  // Placeholder text: Append [Tab to autofill] if prefilled content exists, truncate if too long
  const maxPlaceholderLength = 300; // Adjust this value based on your UI needs
  const placeholderText =
    hasPrefilled && prefilledContent
      ? prefilledContent.length > maxPlaceholderLength
        ? `${prefilledContent.substring(
            0,
            maxPlaceholderLength
          )}... \n\n [Tab to autofill]`
        : `${prefilledContent} \n\n [Tab to autofill]`
      : "Type your answer here... or ask it for suggestions (new)";

  console.log(steps && steps[currentStep - 1]);

  // Define helper sections as an array of objects with toggleable content
  const [openSections, setOpenSections] = useState({});

  const toggleSection = (id) => {
    setOpenSections((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return (
    <>
      {!steps ||
      creatingProcess ||
      !steps[currentStep - 1] ||
      !steps[currentStep - 1].title ? (
        <div
          className={`createDocumentGeneralInput createDocumentRight`}
          id="createDocumentGeneralInput"
        >
          <div className="skeletonLoader">
            <div className="skeletonLoaderElement skeletonHeader"></div>
            <div className="skeletonParagraphGroup">
              <div className="skeletonLoaderElement skeletonParagraph"></div>
              <div className="skeletonLoaderElement skeletonParagraph"></div>
              <div className="skeletonLoaderElement skeletonParagraph"></div>
            </div>
            <div className="skeletonLoaderElement skeletonButton"></div>
          </div>
        </div>
      ) : (
        <animated.div
          className={`createDocumentGeneralInput createDocumentRight`}
          style={modalProps}
          id="createDocumentGeneralInput"
        >
          <div className="createDocumentFirst">
            <div style={{ position: "relative" }}>
              <img src="/logowhiteblue.png" className="generalInputLogoIcon" />
              <p className="generalInputLogoName">Airstrip AI</p>
            </div>
            <div className="generalInputHeaderText documentDetailHeaderText">
              {steps[currentStep - 1] &&
                (steps[currentStep - 1].title.length > 450 ? (
                  <>
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        lineHeight: "1.7em",
                      }}
                    >
                      {steps[currentStep - 1].title
                        .replace(/<[^>]*>.*?<\/[^>]*>/gs, "") // Remove HTML tags and their content
                        .replace(/[*_~`]/g, "")
                        .slice(0, 450)}{" "}
                      [Tab to autofill]
                    </div>
                    <p
                      style={{
                        fontSize: "13px",
                        fontWeight: "500",
                        lineHeight: "1.7em",
                      }}
                    >
                      {steps[currentStep - 1].title
                        .replace(/<[^>]*>.*?<\/[^>]*>/gs, "") // Remove HTML tags and their content
                        .replace(/[*_~`]/g, "")
                        .slice(450)}{" "}
                    </p>
                  </>
                ) : (
                  <p>
                    {steps[currentStep - 1].title
                      .replace(/<[^>]*>.*?<\/[^>]*>/gs, "") // Remove HTML tags and their content
                      .replace(/[*_~`]/g, "")}
                  </p>
                ))}

              {/* HELPER SECTIONS - DO MORE ON THIS ONCE SEO FEATURES ARE DONE */}

              <div className="helperSections">
                <div
                  className={`eachHelperSection ${
                    openSections["prefill"] ? "" : "inactiveHelperSection"
                  }`}
                  onClick={() => {
                    if (prefilledContent) {
                      updateInput(prefilledContent);
                    }
                  }}
                >
                  <div
                    className="eachHelperSectionHeader"
                    onClick={() => toggleSection("prefill")}
                  >
                    <div>
                      <BiListMinus className="helperSectionIcon" />
                      <p className="helperSectionLabel">AI Suggestions</p>
                    </div>
                    <ChevronDown size={17} />
                  </div>
                  {openSections["prefill"] && (
                    <div className="helperSectionContent">
                      {prefilledContent ? (
                        <>
                          <p
                            className="helperSectionDesc"
                            style={{
                              fontStyle: "italic",
                              opacity: 0.8,
                              backgroundColor: "#f9f9f9",
                              borderLeft: "4px solid #356bf3",
                              padding: "10px 15px",
                              borderRadius: "5px",
                              margin: "10px 0",
                              fontSize: "13px",
                            }}
                          >
                            {prefilledContent}
                          </p>
                          <button
                            className="approveButton"
                            style={{
                              padding: "3px 13px",
                              backgroundColor: "rgba(0,0,0,0.05)",
                              color: "black",
                              borderRadius: "10px",
                              fontWeight: "500",
                              cursor: "pointer",
                              fontSize: "12px",
                              marginBottom: "15px",
                            }}
                          >
                            <RefreshCcw
                              size={14}
                              style={{ marginRight: "7px", opacity: 0.8 }}
                            />
                            <span>Regenerate</span>
                          </button>
                        </>
                      ) : (
                        <div className="helperSectionDesc">
                          <p
                            style={{
                              fontSize: "13px",
                              marginBottom: "10px",
                              opacity: 0.8,
                            }}
                          >
                            Get tailored suggestions for this terms.
                          </p>
                          <button className="approveButton">Generate</button>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                {/* <div
                  className={`eachHelperSection ${
                    openSections["commonUses"] ? "" : "inactiveHelperSection"
                  }`}
                  onClick={() => console.log("Common uses clicked")}
                >
                  <div
                    className="eachHelperSectionHeader"
                    onClick={() => toggleSection("commonUses")}
                  >
                    <div>
                      <CommandIcon className="helperSectionIcon" size={14} />
                      <p className="helperSectionLabel">Common uses</p>
                    </div>
                    <ChevronDown size={17} />
                  </div>
                  {openSections["commonUses"] && (
                    <div className="helperSectionContent">
                      <p className="helperSectionDesc">
                        Add description if needed
                      </p>
                    </div>
                  )}
                </div> */}
              </div>
            </div>
          </div>

          <div className="createDocumentSecond">
            <div className="textareaContainer">
              <textarea
                ref={inputRef}
                type="text"
                className="generalInput"
                id="generalInput"
                placeholder={placeholderText}
                autoFocus
                value={steps[currentStep - 1].inputs?.value ?? ""}
                onChange={(e) => updateInput(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Tab" && hasPrefilled) {
                    e.preventDefault();
                    updateInput(prefilledContent);
                  }
                }}
              ></textarea>
              {steps && steps[currentStep - 1] && <NextStepButton />}
            </div>

            {steps[currentStep - 1].error && (
              <p className="contextInputError stepperErrorText">
                <AiOutlineExclamation className="contextInputErrorIcon" />
                <p className="stepperErrorTextLabel">
                  {steps[currentStep - 1].error}
                </p>
              </p>
            )}
          </div>

          {currentStepWarning() && currentStepWarning()?.loading && (
            <div className="answerMistakeSuggestionLoading">
              <div className="answerMistakeSuggestionLoadingContent">
                <p className="mistakeLoadingContentHeader">
                  Checking your answer
                </p>
                <p className="mistakeLoadingContentDescription">
                  This helps us make sure you the document does not have any
                  inaccuracies and to get an accurate document that fits your
                  needs
                </p>
              </div>

              <Oval
                height={20}
                width={20}
                color="#1252f3"
                wrapperStyle={{}}
                visible={true}
                wrapperClass="answerMistakeLoadingIcon"
                ariaLabel="oval-loading"
                secondaryColor="#1252f320"
                strokeWidth={5}
                strokeWidthSecondary={5}
              />
            </div>
          )}

          {currentStepWarning() && !currentStepWarning()?.loading && (
            <div
              className={`answerMistakeSuggestion ${
                currentStepWarning()?.accuracy <= 7 &&
                "importantMistakeSuggestion"
              }`}
            >
              <div className="answerMistakeSuggestionLeft">
                {currentStepWarning()?.accuracy <= 7 ? (
                  <PiWarningCircleFill className="answerMistakeSuggestionIcon" />
                ) : (
                  <RiLightbulbFlashFill
                    className="answerMistakeSuggestionIcon"
                    style={{ outline: "none" }}
                  />
                )}

                <p className="answerMistakeSuggestionTextHeader">
                  {currentStepWarning()?.accuracy <= 7
                    ? "Suggestion to improve your answer"
                    : "Ways to improve your answer more (optional)"}
                </p>
              </div>
              <div className="answerMistakeSuggestionRight">
                <p className="answerMistakeSuggestionText">
                  {currentStepWarning()?.message}
                </p>

                {getExampleSuggestion(currentStepWarning()?.message) && (
                  <div
                    className="answerMistakeSuggestionButton"
                    onClick={() =>
                      updateInput(
                        getExampleSuggestion(currentStepWarning()?.message)
                      )
                    }
                  >
                    <IoIosAddCircle className="answerMistakeSuggestionButtonIcon" />
                    <p>Add this answer</p>
                  </div>
                )}
              </div>
            </div>
          )}
        </animated.div>
      )}
    </>
  );
};
