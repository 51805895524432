import JsPDF from "jspdf";
import html2canvas from "html2canvas";

import { v4 as uuidv4 } from "uuid";

export const shortenText = (text, length) => {
  return text.substring(0, length);
};

export const changeCurrentStep = (
  value,
  currentStep,
  navigate,
  steps,
  params
) => {
  // // // //currentStep, steps.length);
  switch (value) {
    case "next":
      if (currentStep === steps.length) {
        return navigate(`/document/${params.id}/draft`);
      } else if (currentStep !== steps.length) {
        return navigate(
          `/document/${params.id}/create?step=${currentStep + 1}`
        );
      }
      break;
    case "previous":
      if (currentStep <= 1) {
        navigate(`/document/${params.id}`);
      } else navigate(`/document/${params.id}/create?step=${currentStep - 1}`);
      break;
    default:
      break;
  }
};

export const toSentenceCase = (camelCase) => {
  if (camelCase) {
    const result = camelCase.replace(/([A-Z])/g, " $1");
    return result[0].toUpperCase() + result.substring(1).toLowerCase();
  }
  return "";
};

export const convertToCamelCase = (text) => {
  return text
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
};

export const htmlStringToPdfBase64 = async (htmlString) => {
  let iframe = document.createElement("iframe");
  iframe.style.visibility = "hidden";
  document.body.appendChild(iframe);
  let iframedoc = iframe.contentDocument || iframe.contentWindow.document;
  iframedoc.body.innerHTML = htmlString;

  let canvas = await html2canvas(iframedoc.body, {});

  // Convert the iframe into a PNG image using canvas.
  let imgData = canvas.toDataURL("image/png");

  // Create a PDF document and add the image as a page.
  const doc = new JsPDF({
    format: "a4",
    unit: "mm",
  });
  doc.addImage(imgData, "PNG", 0, 0, 210, 297);

  doc.setFont("Helvetica");
  doc.setFontType("bold");

  // Get the file as blob output.
  let blob = doc.output("datauri");

  // // // //"doc: ", blob);

  // Remove the iframe from the document when the file is generated.
  document.body.removeChild(iframe);
};

export const convertToPDF = async (clonedDiv) => {
  const _document = clonedDiv;
  const doc = new JsPDF("p", "px", [1162, 898]);

  let output;

  // doc.addFont("Arimo-Regular.ttf", "Arimo", "normal");
  // doc.addFont("Arimo-Bold.ttf", "Arimo", "bold");
  doc.setFont("Helvetica");
  doc.setFontType("bold");

  if (_document) {
    doc.html(_document, {
      callback: async function (doc) {
        // doc.save("newpdf.pdf");
        output = await doc.output("datauristring");
        // // // //output);
        return output;
      },
    });
  }

  if (output) {
    return output;
  }
};

export const downloadPDF = (base64String, fileName) => {
  // const byteCharacters = atob(base64String);
  // const byteArrays = [];
  // for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
  //   const slice = byteCharacters.slice(offset, offset + 1024);
  //   const byteNumbers = new Array(slice.length);
  //   for (let i = 0; i < slice.length; i++) {
  //     byteNumbers[i] = slice.charCodeAt(i);
  //   }
  //   const byteArray = new Uint8Array(byteNumbers);
  //   byteArrays.push(byteArray);
  // }
  // const blob = new Blob(byteArrays, { type: "application/pdf" });
  // const url = URL.createObjectURL(blob);
  // return url;

  const linkSource = `data:application/pdf;base64,${base64String}`;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const convertStringToHTML = (htmlString) => {
  const parser = new DOMParser();
  const html = parser.parseFromString(htmlString, "text/html");

  return html.body;
};

let incompleteObject = null;
const completeObjects = [];

export const processArrayStream = (chunk, sendObject) => {
  if (chunk[0] === "*" && !incompleteObject) {
    // // // //"Object started");
    incompleteObject = " ";
    return;
  }

  if (
    chunk[0] === "*" ||
    chunk[0] === "* " ||
    (chunk === "}*" && incompleteObject)
  ) {
    try {
      // // //JSON.parse(incompleteObject));
      completeObjects.push(JSON.parse(incompleteObject));

      sendObject(completeObjects[completeObjects.length - 1]);
    } catch {
      incompleteObject += "}";
      // // //JSON.parse(incompleteObject));
      completeObjects.push(JSON.parse(incompleteObject));

      sendObject(completeObjects[completeObjects.length - 1]);
    }
    incompleteObject = null;
    return;
  }

  if (incompleteObject) {
    // // //"Object appending");
    incompleteObject += chunk;
    return;
  }

  return;
};

export async function readStream(stream, streamChunk) {
  const reader = stream.getReader();
  while (true) {
    const { done, value } = await reader.read();
    if (done) {
      streamChunk("done");
      return;
    }

    const textDecoder = new TextDecoder();
    try {
      const object = JSON.parse(textDecoder.decode(new Uint8Array(value)));

      if (object.data?.delta) {
        // // //object.data?.delta?.content);

        streamChunk(object.data?.delta?.content);
      }
    } catch {
      const input = `${textDecoder.decode(new Uint8Array(value))}`;

      const objects = input
        .trim()
        .split("\n")
        .map((str) => {
          const object = JSON.parse(str);

          if (object.data?.delta) {
            // // //object.data?.delta?.content);

            streamChunk(object.data?.delta?.content);
          }
        });
    }
  }
}

export const formatDate = (passedDate) => {
  const date = new Date(passedDate);

  const options = { day: "numeric", month: "long", year: "numeric" };
  const formattedDate = date.toLocaleDateString("en-US", options);

  const day = date.getDate();
  const suffix = getDaySuffix(day);
  const parts = formattedDate.split(" ");
  // // //"parts", parts);

  const finalFormattedDate = `${day}${suffix} ${parts[0]} ${parts[2]}`;

  function getDaySuffix(day) {
    if (day >= 11 && day <= 13) {
      return "th";
    }

    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }

  return finalFormattedDate;
};

export const formatDateAndTime = (date) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const period = hour >= 12 ? "PM" : "AM";

  const formattedDate = `${day}${getDaySuffix(day)} ${month} ${year} ${
    hour % 12 || 12
  }:${minute.toString().padStart(2, "0")} ${period}`;
  return formattedDate;
};

function getDaySuffix(day) {
  if (day >= 11 && day <= 13) {
    return "th";
  }
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

export const isAllowedToContinueForm = (state) => {
  const areAllValuesEmpty = Object.entries(state).map(
    ([inputLabel, inputValue]) => {
      if (inputValue.length === 0) {
        return false;
      } else return true;
    }
  );

  return areAllValuesEmpty.filter((valuesEmpty) => valuesEmpty === false)
    .length === 0
    ? true
    : false;
};

export const convertTextToSlug = (str) => {
  return str.toLowerCase().replace(/\s+/g, "-");
};

export const returnFormattedDocumentsArray = () => {
  let formattedDocumentElementsArray = [];

  const elementsDivOriginal = document.querySelector(".documentContent");

  let elementsDivClone = elementsDivOriginal.cloneNode(true);
  // const versionsDiv = elementsDivClone.querySelector(".versionsContainer");
  // versionsDiv.remove();

  const childrenArray = Array.from(elementsDivClone.children);

  //"CHILDREN", childrenArray);

  const getType = (element) => {
    if (element.className === "finalDocumentViewerHeading") {
      return "HEADER";
    } else if (element.className === "finalDocumentViewerSubHeading") {
      return "SUBTITLE";
    } else if (element.className === "finalDocumentViewerParagraph") {
      return "PARAGRAPH";
    }
  };

  childrenArray.map((eachElement, index) => {
    if (eachElement.innerHTML !== "<br>") {
      if (eachElement.id === childrenArray[index - 1]?.id) {
        formattedDocumentElementsArray.push({
          type: getType(childrenArray[index - 1]) || "PARAGRAPH",
          id: uuidv4(),
          text: eachElement.textContent,
        });
      } else {
        formattedDocumentElementsArray.push({
          type: getType(eachElement),
          id: eachElement.id,
          text: eachElement.textContent,
        });
      }
    }
  });

  return formattedDocumentElementsArray;
};

export const isValidFileType = (file) => {
  const validTypes = [
    ".doc",
    ".docx",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ".pdf",
    "application/pdf",
  ];

  return validTypes.includes(file.type.toLowerCase());
};

export const getSimplifiedCardsData = (text) => {
  const regex = /(?:- )(\$?[^:]+): ([^-<]+)/g;
  let match;
  const result = [];
  while ((match = regex.exec(text)) !== null) {
    result.push({
      label: match[2].trim(),
      data: match[1].trim(),
    });
  }
  return result;
};

export const downloadFile = (fileURL, fileName) => {
  fetch(fileURL, {
    mode: "cors",
  })
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((error) => {
      console.error("Error fetching the file: ", error);
    });
};
