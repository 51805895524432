import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { HiOutlineDownload } from "react-icons/hi";
import { Share2Icon } from "@radix-ui/react-icons";
import { MdDelete } from "react-icons/md";
import { Oval } from "react-loader-spinner";
import RadixModal from "../../components/Modal/RadixModal";
import ShareDialog from "../../components/ShareModal/ShareDialog";
import DropdownMenuDemo from "../../components/DropdownMenu";
import { downloadDraftAPI, deleteDocumentDraftAPI } from "../../api/apiCalls";

const DocumentActions = ({ documentInfo, id, userData, documentStatus }) => {
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [downloadStatus, setDownloadStatus] = useState(""); // Status message for download process
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const navigate = useNavigate();

  const downloadPdfClick = async () => {
    setDownloadLoading(true);
    setDownloadStatus("Processing document...");

    setTimeout(() => setDownloadStatus("Preparing to download..."), 3000);

    try {
      const downloadLink = await downloadDraftAPI(id);
      window.location.href = downloadLink.data.link;

      setTimeout(() => {
        setDownloadLoading(false);
        setDownloadStatus("");
      }, 2000);
    } catch (error) {
      console.error("Error downloading document:", error);
      setDownloadStatus("Download failed. Please try again.");
      setTimeout(() => {
        setDownloadLoading(false);
        setDownloadStatus("");
      }, 2000);
    }
  };

  const deleteDocument = async () => {
    setDeleteLoading(true);

    const response = await deleteDocumentDraftAPI({ id: id });

    if (response.data) {
      setDeleteLoading(false);
      setShowDeleteModal(false);

      navigate("/my-documents");
    }
  };

  return (
    <div className="finalDocumentHeaderButtons">
      {documentInfo?.document_drafts?.length !== 0 && (
        <>
          <button
            className={`saveButton signButton downloadButton ${
              downloadLoading && "disabledButton"
            }`}
            disabled={
              downloadLoading ||
              documentInfo?.document_drafts?.length === 0 ||
              (documentInfo &&
                userData &&
                userData.plan === "FREE_PLAN" &&
                documentInfo?.resourceType === "FREE_RESOURCE" &&
                documentInfo?.payment === false &&
                documentStatus !== "ACCESS_GRANTED_FOR_DOCUMENT")
            }
            onClick={() => {
              downloadPdfClick();
            }}
          >
            <HiOutlineDownload className="saveButtonIcon" />
            <div className="saveButtonText">
              {downloadLoading ? downloadStatus : "Download"}
            </div>

            {downloadLoading && (
              <Oval
                height={14}
                width={14}
                color="white"
                wrapperStyle={{}}
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#191f2f20"
                wrapperClass="saveButtonLoadingIcon"
                strokeWidth={5}
                strokeWidthSecondary={5}
              />
            )}
          </button>

          <button
            className="saveButton signButton downloadButton shareButton"
            onClick={() => setShowShareModal(true)}
            style={{ backgroundColor: "#1252f315", color: "#1252f3" }}
          >
            <Share2Icon className="saveButtonIcon" />
            <div className="saveButtonText">Share</div>
          </button>
        </>
      )}

      {showDeleteModal && (
        <RadixModal
          title="Permanent Delete"
          description="This change cannot be reversed. Confirm if you want to proceed."
          buttonText="Yes, Permanently delete this Document"
          openModal={showDeleteModal}
          setOpenModal={setShowDeleteModal}
          className="deleteDocumentModal"
          onClick={() => {
            deleteDocument();
          }}
          loading={deleteLoading}
        >
          <div className="deleteDocumentModalContainer">
            <p className="deleteDocumentText">
              Once you permanently delete this document, you will not able be
              able access this document again.{" "}
              <b>Confirm if you want to proceed with this.</b>
            </p>
          </div>
        </RadixModal>
      )}

      <DropdownMenuDemo
        items={[
          {
            disabled: false,
            label: "Delete",
            icon: <MdDelete />,
            onClick: () => {
              setShowDeleteModal(true);
            },
            red: true,
          },
        ]}
      />

      <ShareDialog
        isOpen={showShareModal}
        onClose={() => setShowShareModal(false)}
        documentId={id}
      />
    </div>
  );
};

export default DocumentActions;
