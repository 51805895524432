import React, { useEffect, useState } from "react";
import { BsArrowRightShort, BsStars } from "react-icons/bs";
import { FiChevronRight } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { animated, easings, useSpring } from "@react-spring/web";
import { useDispatch, useSelector } from "react-redux";
import {
  addStartupOnboardingDetailsAPI,
  getAllDocumentsAPI,
  getLifetimeAccessPlan,
} from "../api/apiCalls";
import { Navbar } from "../components/Navbar";
import { processArrayStream, readStream } from "../functions";
import { updateDocument } from "../redux/actions/documentActions";
import { ChatWithAI } from "./Chat";
import { Oval } from "react-loader-spinner";
import { updateUser } from "../redux/actions/userAction";
import { Crisp } from "crisp-sdk-web";
import { CgClose, CgFileDocument } from "react-icons/cg";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { updateStepper } from "../redux/actions/stepperActions";
import Onboarding from "../components/Onboarding";

import "../radix-styles/dialog.css";

export const App = () => {
  const navigate = useNavigate();

  const [findDocumentsPopupProps, setFindDocumentsPopupProps] = useSpring(
    () => ({
      x: 0,
      y: 0,
      width: "700px",
      minHeight: "400px",
      opacity: 0,
    })
  );

  const [findDocumentsContainerProps, setFindDocumentsContainerProps] =
    useSpring(() => ({
      opacity: 0,
    }));

  const [findDocumentsOverlayProps, setFindDocumentsOverlayProps] = useSpring(
    () => ({
      opacity: 0,
    })
  );

  const [modalProps, setmodalProps] = useSpring(() => ({
    from: {
      opacity: 0,
      marginTop: 40,
    },
    to: {
      opacity: 1,
      marginTop: 0,
    },
    config: {
      easing: easings.easeInElastic(20),
    },
  }));

  const [modalContainerProps, setModalContainerProps] = useSpring(() => ({
    opacity: 1,
  }));

  const [documentsListProps, setDocumentsListProps] = useSpring(() => ({
    opacity: 0,
  }));

  const [showFindDocuments, setShowFindDocuments] = useState(false);
  const [showAllDocuments, setShowAllDocuments] = useState(false);

  const [documentsLoading, setDocumentsLoading] = useState(false);

  const scenarioTextState = useState("");

  const [documentsForScenario, setDocumentsForScenario] = useState([]);
  const [fetchingDocuments, setFetchingDocuments] = useState([]);

  const {
    token,
    onboardingShown,
    name,
    email,
    onboardingSteps: onboardingMessages,
    isOnboardingCompleted,
    showAppSumoModal,
    customDocumentMessage,
  } = useSelector((state) => state.user);
  const { allDocuments } = useSelector((state) => state.document);
  const { pendingFlows } = useSelector((state) => state.stepper);
  const dispatch = useDispatch();

  const [showOnboardingModal, setShowOnboardingModal] = useState(true);

  const toggleFindDocuments = (state) => {
    if (state === "open") {
      setShowFindDocuments(true);

      setFindDocumentsOverlayProps({
        to: {
          opacity: 1,
        },
      });

      setFindDocumentsPopupProps({
        from: {
          x: 0,
          y: 0,
          width: "0px",
          minHeight: "400px",
          opacity: 0,
        },
        to: {
          x: 0,
          y: 0,
          width: "700px",
          minHeight: "400px",
          opacity: 1,
        },
        config: {
          easing: easings.easeInElastic(10),
        },
      });

      setTimeout(() => {
        setFindDocumentsContainerProps({
          from: {
            opacity: 0,
          },
          to: {
            opacity: 1,
          },
          config: {
            easing: easings.easeInElastic(10),
          },
        });
      }, 500);
    } else if (state === "close") {
      setFindDocumentsContainerProps({
        from: {
          opacity: 1,
        },
        to: {
          opacity: 0,
        },
        config: {
          easing: easings.easeInElastic(10),
        },
      });

      setFindDocumentsOverlayProps({
        to: {
          opacity: 0,
        },
      });

      setFindDocumentsPopupProps({
        from: {
          x: 0,
          y: 0,
          opacity: 1,
        },
        to: {
          x: 0,
          y: 30,
          opacity: 0,
        },
        config: {
          easing: easings.easeInElastic(10),
        },
      });

      setDocumentsListProps({
        to: {
          opacity: 0,
        },
      });

      setTimeout(() => {
        setShowAllDocuments(false);
      }, 500);

      setFindDocumentsPopupProps({
        from: {
          minHeight: "400px",
        },
        to: {
          minHeight: "900px",
        },
      });

      const [_, setScenarioText] = scenarioTextState;

      setScenarioText("");
      setFetchingDocuments(false);
      setDocumentsForScenario([]);

      setTimeout(() => {
        setShowFindDocuments(false);
      }, 500);
    }
  };

  useEffect(() => {
    if (showAllDocuments) {
      document.querySelector(`#foundDocuments`).scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [showAllDocuments]);

  const findDocuments = async () => {
    const [scenarioText] = scenarioTextState;

    if (scenarioText.length !== 0) {
      setDocumentsLoading(true);

      const [scenarioText] = scenarioTextState;

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/document/find-documents-scenario`,
        {
          method: "post",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            api_key: "krishna",
            Authorization: token,
          },
          body: JSON.stringify({
            scenario: scenarioText,
          }),
        }
      );

      let documentsArray = [];

      if (response.ok) {
        setFetchingDocuments(true);

        await readStream(response.body, (streamChunk) => {
          if (streamChunk !== "done") {
            processArrayStream(streamChunk, (object) => {
              setDocumentsForScenario([...documentsArray, { ...object }]);

              // // //"object:", object);
              documentsArray.push(object);

              // // //"documents array: ", documentsForScenario);
              setDocumentsLoading(false);

              setShowAllDocuments(true);

              setTimeout(() => {
                setDocumentsListProps({
                  to: {
                    opacity: 1,
                  },
                });
              }, 200);
            });
          } else if (streamChunk === "done") {
            setFetchingDocuments(false);

            // // //"Array: ", documentsArray);
          }
        });
        //
      }

      setFindDocumentsPopupProps({
        from: {
          minHeight: "400px",
        },
        to: {
          minHeight: "900px",
        },
      });

      setTimeout(() => {
        setShowAllDocuments(true);

        setDocumentsListProps({
          to: {
            opacity: 1,
          },
        });
      }, 500);
    }
  };

  const [allDocumentsLoading, setAllDocumentsLoading] = useState(false);

  useEffect(() => {
    if (token.length !== 0 && name.length !== 0 && email.length !== 0) {
      Crisp.setTokenId(token);
      Crisp.user.setEmail(email);
      Crisp.user.setNickname(name);
    }
  }, [token]);

  const [onboardingStep, setOnboardingStep] = useState(0);

  const closeOnboardingModal = () => {
    setModalContainerProps({
      from: {
        opacity: 1,
      },
      to: {
        opacity: 0,
      },
      config: {
        easing: easings.easeInElastic(1),
      },
    });

    setTimeout(() => {
      setShowOnboardingModal(false);
      dispatch(
        updateUser({
          onboardingShown: true,
        })
      );
    }, 300);
  };

  //   useEffect(() => {
  //     const formId = 'mVQJll';
  // if(Tally) {
  //   // Open the popup
  // Tally.openPopup(formId, options);
  // }
  //   },[])
  const [openedOnboardingStep, setOpenedOnboardingStep] = useState(0);

  const addOnboardingStepCompleted = (stepData, index) => {
    const filteredData = onboardingMessages.map(
      (eachOnboardingMessage, onboardingIndex) => {
        if (onboardingIndex === index) {
          //("this: ", eachOnboardingMessage);
          return {
            ...eachOnboardingMessage,
            completed: !eachOnboardingMessage.completed,
          };
        } else return { ...eachOnboardingMessage };
      }
    );

    //("filtered: ", filteredData);
    dispatch(
      updateUser({
        onboardingSteps: filteredData,
      })
    );
  };

  let documentsList = [];

  const [docsInfo, setDocsInfo] = useState([]);
  const [filteredAllDocuments, setFilteredAllDocuments] =
    useState(allDocuments);

  const getAllDocuments = async () => {
    const response = await getAllDocumentsAPI();

    if (response.data) {
      let localDocsInfo = response.data;
      const filteredDocsInfo = localDocsInfo.sort((a, b) => {
        const textA = a.type.toUpperCase(); // Convert to uppercase for case-insensitive sorting
        const textB = b.type.toUpperCase();

        if (textA < textB) {
          return -1;
        }
        if (textA > textB) {
          return 1;
        }
        return 0;
      });
      setDocsInfo(filteredDocsInfo);

      response.data.map((documentObject) => {
        documentObject.documents.map((eachDocument) => {
          documentsList.push(eachDocument);
        });
      });

      dispatch(
        updateDocument({
          allDocuments: documentsList,
        })
      );
      setFilteredAllDocuments(documentsList);
    }
  };

  useEffect(() => {
    getAllDocuments();

    dispatch(
      updateDocument({
        signatures: [],
        documentInfo: null,
      })
    );

    dispatch(
      updateStepper({
        currentStep: 1,
        steps: [],
        warnings: [],
        mainStep: 1,
        reviewItems: [],
        flowStatus: "",
        loading: false,
      })
    );

    if (!onboardingMessages) {
      dispatch(
        updateUser({
          onboardingSteps: [
            {
              title: "Provide more context",
              description:
                "Upload any documents with information about your business for Airstrip AI to provide you personalised and accurate assistance.",
              link: "/settings",
              buttonText: "Add Context",
              completed: false,
            },
            {
              title: "Get Answers to your Legal Questions - from Pilot",
              description:
                "Ask your legal questions from Pilot and get personalised and actionable assistance, that will help you make the right decision.",
              link: "/chat",
              buttonText: "Chat with Pilot",
              completed: false,
            },
            {
              title: "Create a New Document",
              description:
                "Browse through available documents, choose what document you need, and get a personalised and lawyer-level document.",
              link: "/browse-documents",
              buttonText: "Browse Documents",
              completed: false,
            },
            {
              title: "View your documents",
              description:
                "Have your documents stored securely all-in-one place, and keep track of them here.",
              link: "/my-documents",
              buttonText: "View saved documents",
              completed: false,
            },
          ],
        })
      );
    }
  }, []);

  const findCompletedOnboardingSteps = () => {
    return (
      onboardingMessages &&
      onboardingMessages?.filter(
        (eachOnboardingMessage) => eachOnboardingMessage.completed === true
      ).length
    );
  };

  const [openModal, setOpenModal] = useState(true);

  const [onboardingStepNumber, setOnboardingStepNumber] = useState(1);
  const [onboardingSuccess, setOnboardingSucess] = useState(false);

  const [documentFilterTag, setDocumentFilterTag] = useState(" ");

  const [documentSearchInput, setDocumentSearchInput] = useState("");

  const [selectedDocument, setSelectedDocument] = useState(null);

  const [onboardingInputs, setOnboardingInputs] = useState({
    company: "",
    description: "",
  });

  useEffect(() => {
    let newFilteredAllDocuments = allDocuments.filter((eachDocument) =>
      eachDocument.title
        .toLowerCase()
        .includes(documentSearchInput.toLowerCase())
    );
    setFilteredAllDocuments(newFilteredAllDocuments);
  }, [documentSearchInput]);

  useEffect(() => {
    setDocumentSearchInput("");
    let newFilteredAllDocuments = allDocuments.filter((eachDocument) =>
      eachDocument.tag.includes(documentFilterTag)
    );
    setFilteredAllDocuments(newFilteredAllDocuments);
  }, [documentFilterTag]);

  useEffect(() => {
    if (selectedDocument) {
      let newFilteredAllDocuments = allDocuments.filter(
        (eachDocument) => eachDocument === selectedDocument
      );

      setFilteredAllDocuments(newFilteredAllDocuments);
    } else {
      setSelectedDocument(null);
      setFilteredAllDocuments(allDocuments);
    }
  }, [selectedDocument]);

  const [onboardingModalProps, setOnboardingModalProps] = useSpring(() => ({
    opacity: 1,
  }));

  const createDocument = () => {
    setOnboardingModalProps({
      from: {
        opacity: 1,
      },
      to: {
        opacity: 0,
      },
      config: {
        easing: easings.easeInElastic(20),
      },
    });

    setTimeout(() => {
      dispatch(
        updateUser({
          isOnboardingCompleted: true,
        })
      );
      navigate(`/document/${selectedDocument._id}`);
    }, 500);
  };

  // location
  const [loading, setLoading] = useState(false);

  const [selectedCountry, setSelectedCountry] = useState({
    name: "United States",
    iso3: "USA",
    iso2: "US",
  });

  const [selectedState, setSelectedState] = useState({
    name: "California",
    state_code: "CA",
  });
  const [countries, setCountries] = useState([]);

  let supportedCountries = ["USA", "GBR", "CAN", "AUS", "DEU", "CHE", "ESP"];

  const getCountries = () => {
    setLoading(true);

    fetch("https://countriesnow.space/api/v0.1/countries/states").then(
      (response) => {
        response.json().then((responseData) => {
          // //responseData)
          const filteredCountries = responseData.data.filter(
            (eachCountryInfo) => {
              if (supportedCountries.includes(eachCountryInfo.iso3)) {
                return {
                  ...eachCountryInfo,
                };
              }
            }
          );

          setCountries(filteredCountries);
          setLoading(false);
        });
      }
    );
  };

  const getCountryInfo = (iso3) => {
    return countries.filter((country) => country.iso3 === iso3)[0];
  };

  const getStateInfo = (stateCode) => {
    let stateInfo;

    getCountryInfo(selectedCountry.iso3).states.filter((state) => {
      // //state)
      // //stateCode)
      if (state.state_code === stateCode) {
        stateInfo = state;
      }
    });

    return stateInfo;
  };

  const [onboardingLoading, setOnboardingLoading] = useState(false);

  const redirectLink = JSON.parse(localStorage.getItem("redirectLink"));

  const [selectedUserType, setSelectedUserType] = useState("individual");

  const [showAPIKeyModal, setShowAPIKeyModal] = useState(false);

  const addOnboardingDetailsAPI = async () => {
    setOnboardingLoading(true);

    const response = await addStartupOnboardingDetailsAPI({
      name:
        selectedUserType === "individual"
          ? "Individual"
          : onboardingInputs.company,
      aboutCompany:
        selectedUserType === "individual"
          ? "Individual"
          : onboardingInputs.company,
      country: `${selectedState.name && `${selectedState.name},`} ${
        selectedCountry.name
      }`,
    });

    if (response.data) {
      dispatch(
        updateUser({
          companyName: onboardingInputs.company,
          country: `${selectedState.name && `${selectedState.name},`} ${
            selectedCountry.name
          }`,
          isOnboardingCompleted: true,
        })
      );

      if (redirectLink) {
        if (redirectLink.includes("pricing")) {
          window.location.replace(redirectLink);
          localStorage.removeItem("redirectLink");

          dispatch(
            updateUser({
              isOnboardingCompleted: true,
            })
          );
          dispatch(
            updateUser({
              showUpgradeModal: false,
            })
          );
        }
      }

      setOnboardingLoading(false);
      setOnboardingSucess(true);

      // if a user wanted to create a custom document from the landing page
      if (customDocumentMessage) {
        // addding the user's landing page text to this document modal's text
        dispatch(
          updateDocument({
            initialText: customDocumentMessage,
            initiateTask: true,
          })
        );
        // opening the document modal
        dispatch(
          updateUser({
            showDocumentsModal: true,
          })
        );
      }

      // this is showing the appsumo modal
      setShowAPIKeyModal(false); // Set showAPIKeyModal to true after successful onboarding
      setSuperUserModalProps({
        from: { opacity: 0 },
        to: { opacity: 1 },
        config: { easing: easings.easeInElastic(20) },
      });
    }
  };

  useEffect(() => {
    getCountries();
  }, []);

  const [superUserModalProps, setSuperUserModalProps] = useSpring(() => ({
    opacity: 0,
  }));

  const SuperUserModal = () => {
    const [superUserCode, setSuperUserCode] = useState("");
    const [showInvalidCodeMessage, setShowInvalidCodeMessage] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showCongratsModal, setShowCongratsModal] = useState(false);

    const handleSubmit = async () => {
      setIsSubmitting(true);
      try {
        const response = await getLifetimeAccessPlan({
          code: superUserCode,
          apiKey: "",
        });
        if (response.data.status === true) {
          setShowCongratsModal(true);
          // Only update Redux store after showing congrats modal
          setTimeout(() => {
            window.location.reload();
            dispatch(
              updateUser({
                isSuperUser: true,
                apiKey: "",
                showAppSumoModal: false, // This will prevent the modal from showing again
              })
            );
          }, 3000); // Give user time to see congrats message
        } else {
          // Display an error message or handle the case where the code is invalid
          console.error("Invalid super user code");
          // Show a message in the modal that the code is not valid
          if (response.data.message === "Code has already been used") {
            setShowInvalidCodeMessage(
              "The super user code you entered has already been used."
            );
          } else if (response.data.message === "Invalid API key") {
            setShowInvalidCodeMessage(
              "The API key you entered is invalid. Please check and try again."
            );
          } else {
            setShowInvalidCodeMessage(
              "The super user code you entered is not valid."
            );
          }
        }
      } catch (error) {
        console.error("Error checking super user status:", error);
        // Show a message in the modal that the code is not valid
        setShowInvalidCodeMessage(
          "An error occurred while checking the super user code. Please try again later."
        );
      } finally {
        setIsSubmitting(false);
      }
    };

    return (
      <div className="homeContainer">
        {showCongratsModal && (
          <div
            className="appOnboardingModal"
            style={{ zIndex: 99999999999, textAlign: "center" }}
          >
            <animated.div
              style={{
                opacity: 1,
                animation: `fadeIn 0.5s ease-in-out`,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
              className={`appOnboardingModalContent ${
                onboardingSuccess && "appOnboardingModalContentSuccess"
              }`}
            >
              <div className="appOnboardingContent">
                <div className="appOnboardingHeader">
                  <img
                    src="/blueTransparent.png"
                    className="appOnboardingLogo"
                    style={{ marginRight: "10px" }}
                  />
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1lydyXIW9bWcls3tJchwFptuCkB7pnBQgpw&s"
                    className="appOnboardingLogo"
                  />
                  <h1 className="appOnboardingHeaderText">Congratulations!</h1>
                  <p className="appOnboardingHeaderDescription">
                    You now have a lifetime deal to AirstripAI. Thank you for
                    being an AirstripAI Pro Customer!
                  </p>
                </div>
              </div>
            </animated.div>

            <div
              className="appOnboardingModalOverlay"
              onClick={() => {
                setSuperUserModalProps({ to: { opacity: 0 } });
                setShowAPIKeyModal(false);
                window.location.reload();
              }}
              // style={{ zIndex: 99999 }}
            ></div>
          </div>
        )}

        {!showCongratsModal && (
          <div className="appOnboardingModal" style={{ zIndex: 99999999999 }}>
            <animated.div
              style={superUserModalProps}
              className={`appOnboardingModalContent ${
                onboardingSuccess && "appOnboardingModalContentSuccess"
              }`}
            >
              <div className="appOnboardingContent">
                <div className="appOnboardingHeader">
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1lydyXIW9bWcls3tJchwFptuCkB7pnBQgpw&s"
                    className="appOnboardingLogo"
                  />
                  <h1 className="appOnboardingHeaderText">
                    Are you from AppSumo?
                  </h1>
                  <p className="appOnboardingHeaderDescription">
                    If you have a super user code from our{" "}
                    <a href="#" target="_blank">
                      AppSumo lifetime deal (get it for $59)
                    </a>
                    , please enter it here to unlock your lifetime access.
                  </p>
                </div>

                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                  className="appOnboardingMain"
                >
                  {showInvalidCodeMessage && (
                    <div
                      className="appOnboardingInputContainer"
                      style={{
                        marginBottom: "20px",
                        border: "1px solid #ff0000",
                        padding: "10px",
                        backgroundColor: "#ffeeee",
                      }}
                    >
                      <div style={{ color: "#ff0000" }}>
                        {showInvalidCodeMessage}
                      </div>
                    </div>
                  )}
                  <div className="appOnboardingInputContainer">
                    <label className="label" htmlFor="superUserCode">
                      Unique Code
                    </label>
                    <input
                      className="input"
                      autoFocus
                      placeholder="Enter your super user code"
                      id="superUserCode"
                      value={superUserCode}
                      onChange={(e) => {
                        setSuperUserCode(e.target.value);
                        setShowInvalidCodeMessage(false);
                      }}
                      required
                    />
                  </div>
                  <div className="appOnboardingMainButtons">
                    <button
                      type="submit"
                      className="onboardingNextButton"
                      disabled={isSubmitting}
                    >
                      <p className="onboardingButtonText">
                        {isSubmitting ? "Submitting..." : "Get Lifetime Access"}
                      </p>
                      <BsArrowRightShort size={18} />
                    </button>
                    <div
                      className="appOnboardingBackButton"
                      onClick={() => {
                        setShowAPIKeyModal(false);
                        dispatch(updateUser({ showAppSumoModal: false }));
                      }}
                      style={{
                        backgroundColor: "#191f2f !important",
                        color: "#191f2f80",
                        marginLeft: "10px",
                      }}
                    >
                      No, I am not from AppSumo
                    </div>
                  </div>
                </form>
              </div>
            </animated.div>

            <div
              className="appOnboardingModalOverlay"
              onClick={() => {
                setSuperUserModalProps({ to: { opacity: 0 } });
                setShowAPIKeyModal(false);
                dispatch(updateUser({ showAppSumoModal: false }));
              }}
              // style={{ zIndex: 99999 }}
            ></div>
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (showAppSumoModal === true) {
      setSuperUserModalProps({
        from: { opacity: 0 },
        to: { opacity: 1 },
        config: { easing: easings.easeInElastic(20) },
      });
      setShowAPIKeyModal(true);
    }
  }, [showAppSumoModal]);

  useEffect(() => {
    // Only update Redux if modal is being shown (not when hiding after success)
    if (showAPIKeyModal && showAppSumoModal) {
      dispatch(updateUser({ showAppSumoModal: showAPIKeyModal }));
    }
  }, [showAPIKeyModal, showAppSumoModal, dispatch]);

  return (
    <div className="homeContainer">
      {window.location.href.indexOf("chat") > -1 && <ChatWithAI />}

      {showAPIKeyModal && <SuperUserModal />}
      {!isOnboardingCompleted && <Onboarding />}
      {/* <RadixModal
              triggerComponent={null}
              title="Tell us a little about your self"
              description="Let's get you setup with some information about you"
              buttonText="Continue"
              onClick={() => null}
              openModal={openModal}
              setOpenModal={setOpenModal}
              className={"onboardingModalContainer"}
            >
              <fieldset className="Fieldset">
                <label className="Label" htmlFor="username">
                  Feedback (optional)
                </label>
                <input
                  className="Input"
                  autoFocus
                  placeholder="What can be improved more?"
                  id="username"
                />
              </fieldset>
            </RadixModal> */}
      {/* {showOnboardingModal && !onboardingShown && (
        <animated.div style={modalContainerProps} className="onboardingModal">
          <div className="onboardingModalOverlay"></div>

          <animated.div style={modalProps} className="onboardingModalContent">
            <div className="onboardingModalTop">
              <p className="onboardingModalWelcomeText">
                Hello, Welcome to Airstrip!
              </p>

              <div
                style={{
                  backgroundImage:
                    onboardingStep !== onboaringSteps.length - 1 &&
                    `url(${onboaringSteps[onboardingStep].image})`,
                }}
                className={`onboardingModalImage ${
                  onboardingStep === onboaringSteps.length - 1 &&
                  "lastOnboaradingModalStep"
                }`}
              >
                {onboardingStep === onboaringSteps.length - 1 && (
                  <>
                    <div className="logoSection">
                      <img
                        src="/logoDarkBlue.png"
                        className="logoImage"
                        style={{ width: "40px", height: "40px" }}
                      />
                      <p className="onboardingLogoName">Airstrip</p>
                    </div>

                    <p className="onboardingLogoText">You're set up!</p>
                  </>
                )}
              </div>

              <div className="onboardingModalInfo">
                <h1 className="onboardingModalTitle">
                  {onboaringSteps[onboardingStep].title}
                </h1>
                <p className="onboardingModalDescription">
                  {onboaringSteps[onboardingStep].description}
                </p>
              </div>

              <div className="stepCircles">
                {onboaringSteps.map((eachStep, index) => (
                  <div
                    onClick={() => setOnboardingStep(index)}
                    className={`stepCircle ${
                      index === onboardingStep && "currentStepCircle"
                    }`}
                  ></div>
                ))}
              
              </div>
            </div>

            <div className="onboardingModalButtons">
              <div
                className="skipButton"
                onClick={() => closeOnboardingModal()}
              >
                <p className="skipButtonText">Skip</p>
              </div>

              {onboardingStep === onboaringSteps.length - 1 ? (
                <div
                  className="nextButton finishButton"
                  onClick={() => closeOnboardingModal()}
                >
                  <p className="nextButtonText">Finish</p>
                  <FiCheck className="nextButtonIcon" />
                </div>
              ) : (
                <div
                  className="nextButton"
                  onClick={() => setOnboardingStep((prev) => prev + 1)}
                >
                  <p className="nextButtonText">Next</p>
                  <FiChevronRight className="nextButtonIcon" />
                </div>
              )}
            </div>
          </animated.div>
        </animated.div>
      )} */}
      <Navbar />
      <div className="rightContainer">
        {/* <div className="rightContainerHeader">
              <h1 className="rightContainerHeaderText">Home</h1>
              <p className="rightContainerHeaderDescription">
                Get your Account set-up and view overall insights
              </p>
            </div> */}

        <div className="homeContentContainer">
          {pendingFlows &&
            pendingFlows?.length !== 0 &&
            pendingFlows?.length !== 0 && (
              <div className="homeContentPendingFlows">
                <div className="pendingFlowsHeader">
                  <p className="pendingFlowsHeaderText">Pending documents</p>
                  <p className="pendingFlowsDescription">
                    Continue creating these pending documents from where you
                    left off.
                  </p>

                  <div className="pendingFlowsLength">
                    {pendingFlows.length}
                  </div>
                </div>

                <div className="pendingFlowsList">
                  {pendingFlows.map((eachPendingFlow, index) => (
                    <div
                      className="eachPendingFlow"
                      onClick={() => {
                        navigate(`/document/${eachPendingFlow._id}/create`);
                      }}
                    >
                      <HiOutlineDotsHorizontal className="pendingFlowIcon" />
                      <div className="pendingFlowText">
                        {eachPendingFlow.documentTitle === "N/A"
                          ? `Draft document ${index + 1}`
                          : eachPendingFlow.documentTitle}
                      </div>

                      <FiChevronRight className="pendingFlowRightIcon" />
                    </div>
                  ))}
                  {/* 
              <div className="eachPendingFlow">
                <HiOutlineDotsHorizontal className="pendingFlowIcon" />
                <div className="pendingFlowText">Privacy Policy</div>

                <FiChevronRight className="pendingFlowRightIcon" />
              </div> */}
                </div>
              </div>
            )}

          <img src="/blueTransparent.png" className="homeContainerLogo" />

          {/* <p
              className="homeContentHeaderDescription versionText"
              style={{ marginBottom: "10px" }}
            >
              v1.0.5
            </p> */}

          <p className="homeContentHeaderDescription">
            Get Legal Documents created with the best-in class AI.
          </p>

          <div className="homeContentButtonsContainer">
            <Link to="/browse-documents" className="homeContentContainerLink">
              Create new document &rarr;
              <p className="homeContentContainerLinkDescription">
                Browse and create the documents you need with our best-in class
                AI.
              </p>
            </Link>
            <Link to="/my-documents" className="homeContentContainerLink">
              View your <br /> documents &rarr;
              <p className="homeContentContainerLinkDescription">
                View the documents you have created.
              </p>
            </Link>
            <Link to="/simplify" className="homeContentContainerLink">
              Simplify your legal document &rarr;
              <p className="homeContentContainerLinkDescription">
                Simplify any legal document into an understandable format.
              </p>
            </Link>
          </div>

          {/* <p className="homeContentHeaderText">
              Hey {name}, Welcome to Airstrip
            </p> */}

          {/* <div className="homeContent">
              <div className="homeContentButtons">
                <div className="homeContentButtonsHeader">
                  <BiSolidCustomize className="homeContentButtonsHeaderIcon" />
  
                  <h1 className="homeContentButtonsHeaderText">
                    Let's get you started with these steps,
                  </h1>
                  <p className="homeContentButtonsHeaderDescription">
                    Use this personalised guide to get setup with getting
                    personalied legal assistance
                  </p>
  
                  <div className="tasksCompleted">
                    <p className="tasksCompletedText">
                      {findCompletedOnboardingSteps()} / 4 Tasks Completed{" "}
                      {findCompletedOnboardingSteps() === 4 && "🎉"}
                    </p>
  
                    <div className="tasksCompletedProgressContainer">
                      <div
                        className="tasksCompletedProgress"
                        style={{
                          width: `${(findCompletedOnboardingSteps() / 4) * 100}%`,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
  
                <div className="homeContentButtonsList">
                  {onboardingMessages?.map((eachOnboardingMessage, index) => (
                    <div
                      className={`homeContentLink ${
                        openedOnboardingStep === index &&
                        "selectedHomeContentLink"
                      }`}
                      onClick={() => setOpenedOnboardingStep(index)}
                    >
                      <div
                        className={`homeContentLinkIcon ${
                          eachOnboardingMessage.completed &&
                          "completedHomeContentLinkIcon"
                        }`}
                        onClick={() =>
                          addOnboardingStepCompleted(eachOnboardingMessage, index)
                        }
                      >
                        {eachOnboardingMessage.completed && <FaCheck />}
                      </div>
                      <div className="homeContentLinkRight">
                        <p className="homeContentLinkText">
                          {eachOnboardingMessage.title}
                        </p>
                        {openedOnboardingStep === index && (
                          <>
                            <p className="homeContentLinkDescription">
                              {eachOnboardingMessage.description}
                            </p>
                            <Link
                              to={eachOnboardingMessage.link}
                              className="homeContentLinkButton"
                            >
                              {eachOnboardingMessage.buttonText}
                            </Link>
                          </>
                        )}
                      </div>
                    </div>
                  ))}
  
                  <div className="homeContentLink">
                    <div className="homeContentLinkIcon"></div>
                    <p className="homeContentLinkText">Create a New Document</p>
                  </div>
  
                  <div className="homeContentLink">
                    <div className="homeContentLinkIcon"></div>
                    <p className="homeContentLinkText">View your Documents</p>
                  </div>
  
                  <div className="homeContentLink">
                    <div className="homeContentLinkIcon"></div>
                    <p className="homeContentLinkText">Chat with Pilot</p>
                  </div>
                </div>
              </div>
            </div> */}
        </div>
      </div>
      {/*
              {showFindDocuments && (
                <FindDocumentsPopup
                  findDocumentsOverlayProps={findDocumentsOverlayProps}
                  toggleFindDocuments={toggleFindDocuments}
                  findDocumentsPopupProps={findDocumentsPopupProps}
                  findDocumentsContainerProps={findDocumentsContainerProps}
                  findDocuments={findDocuments}
                  showAllDocuments={showAllDocuments}
                  documentsListProps={documentsListProps}
                  documents={documentsForScenario}
                  scenarioTextState={scenarioTextState}
                  documentLoading={documentsLoading}
                  fetchingDocuments={fetchingDocuments}
                />
              )} */}
      {/* </div> */}
      {/* <div className="container">
                <div className="homeMain">
                  <div className="findDocumentsForYouContainer">
                    <p className="findDocumentsHeaderText">
                      Find the right documents for your startup.
                    </p>
                    <p className="findDocumentsHeaderDescription">
                      Explain your startup, what it does, and our AI will find the
                      right documents for your specific use case.
                    </p>
                    <animated.div className="findDocumentsInputContainer">
                      <input
                        type="text"
                        placeholder="ex: My Startup is an app that provides home services"
                        className="findDocumentInput"
                        onFocus={() => {
                          toggleFindDocuments("open");
                        }}
                      />
                      <button className="findDocumentButton">
                        <BsStars className="findDocumentIcon" />
                        <p className="findDocumentText">Find</p>
                      </button>
                    </animated.div>
                  </div>
                </div>
              </div> */}
      {/* <div className="chatWithMiraAiOverlay" onClick={() => navigate("/chat")}>
              <div className="chatWithMiraAiButton">
                <BsStars className="chatWithMiraAiIcon" />
                <p className="chatWithMiraAiText">Chat with Mira AI</p>
              </div>
            </div> */}
    </div>
  );
};
