import React, { useEffect, useState } from "react";
import { IoMdArrowBack } from "react-icons/io";
import { GiTicket } from "react-icons/gi";
import { BiRightArrow, BiRightArrowAlt } from "react-icons/bi";
import { BsRocketFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../redux/actions/userAction";
import {
  createBlackFridayPlanLink,
  getDocumentPaymentLinkAPI,
  upgradePlanAPI,
} from "../../api/apiCalls";
import { Oval } from "react-loader-spinner";
import { useNavigate, useSearchParams } from "react-router-dom";

export const PricingPlans = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [perDocumentLoading, setPerDocumentLoading] = useState(false);

  const document = useSelector((state) => state.document);

  const upgradePlan = async () => {
    setLoading(true);

    if (selectedPlanType === "annual") {
      const response = await createBlackFridayPlanLink({
        redirectUrl: window.location.href.includes("pricing")
          ? `https://app.useairstrip.com`
          : window.location.href,
      });

      if (response.data) {
        setLoading(false);

        //   //response.data);

        window.location.href = response.data.url;

        dispatch(
          updateUser({
            showUpgradeModal: false,
          })
        );
      }
    } else {
      const response = await upgradePlanAPI({
        redirectUrl: window.location.href,
      });

      if (response.data) {
        setLoading(false);
        dispatch(
          updateUser({
            showUpgradeModal: false,
          })
        );
        //   //response.data);

        window.location.href = response.data.url;
      }
    }
  };

  const generatePaymentLink = async (onSuccess) => {
    setPerDocumentLoading(true);

    // //document.documentInfo);

    if (!document.documentDetails) {
      setPerDocumentLoading(false);
      console.error("Document details not available");
      return;
    }

    const response = await getDocumentPaymentLinkAPI({
      documentId: document.documentDetails._id,
    });

    if (response.data) {
      window.location.href = response.data.url;
      setPerDocumentLoading(false);

      if (onSuccess) {
        onSuccess();
      }
    }
  };

  const buyDocument = () => {
    if (
      document.documentInfo &&
      document.documentDetails &&
      window.location.href.split("/").includes("document")
    ) {
      generatePaymentLink(() => {
        dispatch(
          updateUser({
            showUpgradeModal: false,
          })
        );
      });
    } else {
      dispatch(
        updateUser({
          showUpgradeModal: false,
        })
      );
      navigate("/browse-documents");
    }
  };

  const [selectedPlanType, setSelectedPlanType] = useState("annual");

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("plan") && searchParams.get("plan") === "pro") {
      upgradePlan();
    }
  }, [searchParams]);

  const getHoursRemaining = () => {
    var currentDate = new Date();

    // Specify the target date (November 24)
    var targetDate = new Date(
      currentDate.getFullYear(),
      10 /* November is 0-based in JavaScript */,
      24
    );

    // Calculate the time difference in milliseconds
    var timeDifference = targetDate - currentDate;

    // Convert the time difference to hours
    var hoursDifference = timeDifference / (1000 * 60 * 60);
    return hoursDifference.toFixed(2);
  };

  const pricingPlans = [
    {
      type: "monthly",
      plans: [
        {
          name: "Pro",
          price: "$50/month",
          features: [
            "200 Pilot Chats – Advanced insights, plain-english document updates.",
            "Unlimited Documents – Create & manage",
            "Simplify Legal Documents",
            "Secure Storage – End to end encryption",
            "Priority Support – 24/7 expert help, jump the queue.",
            "Early access to new features!",
          ],
          bestValue: true,
          buttonText: "Upgrade to Pro Now – Boost Productivity!",
          onClick: () => upgradePlan(),
        },
        {
          name: "Pay for this document",
          price: "$30 per document",
          features: [
            "5 Pilot Chats – Basic AI help for quick doc insights",
            "1 Tailored Document ",
            "Simplify Legal Documents",
            "Secure Storage – Secure encryption",
            "[strike] Advanced insights, plain-english document updates. [strike]",
            "[strike] Unlimited Documents – Create & manage [strike]",
            "[strike] Priority Support – 24/7 expert help, jump the queue. [strike]",
            "[strike] Early access to new features! [strike]",
          ],
          buttonText:
            document.documentInfo &&
            document.documentDetails &&
            window.location.href.split("/").includes("document")
              ? `Purchase ${document.documentDetails.title} Now`
              : "Choose Document",
          onClick: () => buyDocument(),
        },
      ],
    },
    {
      type: "annual",
      plans: [
        {
          name: "Pro",
          price: "$20/month",
          slashedPrice: "$50",
          savedAmount: "Save 55% ($300) with Annual Pro",
          features: [
            "200 Pilot Chats – Advanced insights, plain-english document updates.",
            "Unlimited Documents – Create & manage",
            "Simplify Legal Documents",
            "Secure Storage – End to end encryption",
            "Priority Support – 24/7 expert help, jump the queue.",
            "Early access to new features!",
          ],
          bestValue: true,
          buttonText: "Get Annual Pro Plan",
          onClick: () => upgradePlan(),
        },
      ],
    },
  ];

  return (
    <div className="pricingPlansContentContainer">
      {loading && (
        <div className="pricingLoading">
          <Oval
            height="50"
            width="50"
            radius={4}
            strokeWidth={5}
            color="#6550eb"
            secondaryColor="#8e7ef2"
            ariaLabel="puff-loading"
            wrapperStyle={{}}
            visible={true}
          />
        </div>
      )}

      <div className="pricingPlansNav">
        <div
          className="pricingPlansBackButton"
          onClick={() => {
            dispatch(
              updateUser({
                showUpgradeModal: false,
              })
            );
            // window.location.href = "/";
          }}
        >
          <IoMdArrowBack className="pricingPlansBackButtonIcon" />
          <p className="pricingPlansBackButtonText">Back</p>
        </div>

        <img
          src="/logoBlueTransparent.png"
          alt=""
          className="pricingPlanLogo"
        />
      </div>

      <div className="pricingPlansHeader">
        <h1 className="pricingPlansHeaderText">
          <span className="pricingPlansHeaderTextPurple">Upgrade</span> your
          plan
        </h1>
        <p className="pricingPlansHeaderDescription">
          Create unlimited personalised and compliant legal documents, make
          unlimited revisions, and store them securely all in-one-place.
        </p>
      </div>

      <div className="selectPlanType">
        <div
          className={`planType ${
            selectedPlanType === "annual" && "selectedPlanType annualPlanType"
          }`}
          onClick={() => setSelectedPlanType("annual")}
        >
          Annual (55% off)
        </div>
        <div
          className={`planType ${
            selectedPlanType === "monthly" && "selectedPlanType"
          }`}
          onClick={() => setSelectedPlanType("monthly")}
        >
          Monthly
        </div>
      </div>

      <div className="pricingPlansContainer">
        <div className="pricingPlansTop">
          {pricingPlans
            .find((plan) => plan.type === selectedPlanType)
            .plans.map((plan, index) => (
              <div
                className={`eachPricingPlan ${plan.bestValue ? "proPlan" : ""}`}
                key={index}
                style={
                  plan.slashedPrice
                    ? { width: "450px", marginLeft: "30px" }
                    : {}
                }
              >
                <div className="eachPricingPlanHeader">
                  <p className="pricingPlanName">
                    {plan.name}
                    {plan.bestValue && (
                      <BsRocketFill className="upgradePlanButtonIcon" />
                    )}
                  </p>
                  <p className="pricingPlanPrice">
                    {plan.slashedPrice && (
                      <div className="slashedPriceText">
                        {plan.slashedPrice}
                      </div>
                    )}
                    <p>{plan.price}</p>
                  </p>
                  {plan.bestValue && (
                    <div className="pricingPlanBestValue">
                      <GiTicket className="bestValueIcon" />
                      <p className="bestValueText">Best Value!</p>
                    </div>
                  )}
                  {plan.savedAmount && (
                    <span className="savedAmount">
                      <b>{plan.savedAmount}</b>
                    </span>
                  )}
                </div>

                <div className="pricingFeatures">
                  {plan.features.map((feature, featureIndex) => (
                    <li
                      className={`eachPricingFeature ${
                        feature.includes("[strike]")
                          ? "notAvailableFeature"
                          : ""
                      }`}
                      key={featureIndex}
                    >
                      {feature.replace(/\[.*?\]/g, "").trim()}
                    </li>
                  ))}
                </div>

                <div
                  className={`pricingPlanButton ${
                    plan.bestValue ? "proPlanButton" : ""
                  } ${loading && "disabledButton"}`}
                  onClick={plan.onClick}
                >
                  <p className="pricingPlanButtonText">{plan.buttonText}</p>
                  {loading && (
                    <Oval
                      height="18"
                      width="18"
                      radius={4}
                      strokeWidth={5}
                      color="#ffffff"
                      secondaryColor="#ffffff40"
                      ariaLabel="puff-loading"
                      wrapperStyle={{}}
                      wrapperClass="loadingIconButton"
                      visible={true}
                    />
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
