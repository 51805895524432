import React, { useEffect, useState } from "react";
import { MdOutlineArrowBack } from "react-icons/md";
import { Link, redirect, useParams } from "react-router-dom";
import {
  convertTextToSlug,
  downloadFile,
  getSimplifiedCardsData,
} from "../functions";
import { Navbar } from "../components/Navbar";
import { useSelector } from "react-redux";
import { BsStars } from "react-icons/bs";

import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import axios from "axios";
import { BiDownload } from "react-icons/bi";

import ClipLoader from "react-spinners/ClipLoader";

export const SimplifiedDocument = () => {
  const { id } = useParams();

  const [simplifiedDocument, setSimplifiedDocument] = useState(null);
  const [downloading, setDownloading] = useState(false);

  const { token } = useSelector((state) => state.user);

  const getSimplifiedDocument = async () => {
    await fetch(
      `${process.env.REACT_APP_API_URL}/document/get-simplified-document/${id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          api_key: "krishna",
          Authorization: token,
        },
      }
    ).then(async (response) => {
      const documentData = await response.json();
      setSimplifiedDocument(documentData);
    });
  };

  useEffect(() => {
    getSimplifiedDocument();
  }, []);

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const downloadDocument = async () => {
    setDownloading(true);
    const element = document.querySelector(".simplifyDocumentMarkdown");
    const clonedElement = element.cloneNode(true);

    const downloadButton = clonedElement.querySelector(
      ".downloadDocumentButton"
    );
    if (downloadButton) {
      downloadButton.remove();
    }

    // Extract the first h1, h2, or any heading tag content to use as the file name
    const firstHeading = clonedElement.querySelector("h1, h2, h3, h4, h5, h6");
    const fileName = firstHeading
      ? firstHeading.textContent.trim()
      : "document";

    // Split content into sections based on <hr> tags
    const sections = clonedElement.innerHTML.split("<hr>");
    let paginatedHtml = "";

    sections.forEach((section, index) => {
      const trimmedSection = section.trim();

      // Remove <button> tags and div with downloadDocumentButton class from the section
      const sectionWithoutButtonsOrDownloadDiv = trimmedSection
        .replace(/<button[^>]*>.*?<\/button>/g, "")
        .replace(
          /<div[^>]*class=["']downloadDocumentButton["'][^>]*>.*?<\/div>/g,
          ""
        );

      // Check if the previous section has enough content to justify a page break
      if (index > 0) {
        const previousSection = sections[index - 1].trim();

        const previousContentLength = previousSection.replace(
          /<[^>]*>/g,
          ""
        ).length; // Remove HTML tags to measure text length

        if (previousContentLength > 600) {
          paginatedHtml += `<div class="page" style="page-break-after: always;">${sectionWithoutButtonsOrDownloadDiv}</div>`;
        } else {
          paginatedHtml += sectionWithoutButtonsOrDownloadDiv;
        }
      } else {
        // First section, no page break
        paginatedHtml += `<div>${sectionWithoutButtonsOrDownloadDiv}</div>`;
      }
    });

    const options = {
      method: "POST",
      url: "https://api.pdfendpoint.com/v1/convert",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer pdfe_live_80719f1de7c28412e5d1968f8de9faab8461",
      },
      data: JSON.stringify({
        html: paginatedHtml,
        css: `
        * {
          font-family: 'Helvetica Neue', Arial, sans-serif;
          color: #333;
        }
        body {
          margin: 0;
          padding: 0;
        }
        * {
          font-family: "Inter", sans-serif;
          line-height: 1.5;
          color: #333;
          font-size: 12px; /* Adjusted font size for better fit */
          margin: 5px; /* Reduced spacing for better fit */
        }

        h1 {
          margin-top: 20px;
          margin-bottom: 15px;
          font-size: 1.5em; /* Adjusted font size for headings */
          font-weight: bold;
          line-height: 1.2;
        }

        h2 {
          margin-top: 15px;
          margin-bottom: 10px;
          font-size: 1.25em; /* Adjusted font size for subheadings */
          font-weight: bold;
          line-height: 1.3;
        }

        p {
          margin-bottom: 8px;
          font-size: 12px; /* Adjusted font size for paragraphs */
          line-height: 1.5em;
          color: #555;
        }

        ul, ol {
          margin-left: 15px;
          padding-left: 10px;
          font-size: 12px; /* Adjusted font size for lists */
        }

        li {
          margin-bottom: 5px;
        }

        a {
          color: #1a73e8;
          text-decoration: underline;
          font-size: 12px; /* Adjusted font size for links */
        }

        a:hover {
          color: #1146ce;
          text-decoration: none;
        }

        blockquote {
          margin: 15px 0;
          padding: 8px 15px;
          background-color: #e9ecef;
          border-left: 4px solid #1146ce;
          font-style: italic;
          color: #555;
          font-size: 12px; /* Adjusted font size for blockquotes */
        }

        code {
          font-family: "Fira Code", monospace;
          background-color: #f4f4f4;
          padding: 2px 4px;
          border-radius: 4px;
          font-size: 0.9em;
        }

        pre {
          font-family: "Fira Code", monospace;
          background-color: #f4f4f4;
          padding: 8px;
          border-radius: 6px;
          overflow-x: auto;
          font-size: 0.9em;
          margin: 8px 0;
        }

        hr {
          margin: 20px 0;
          opacity: 0.6;
          height: 0.5px;
          border: none;
          background-color: grey;
          display: none;
        }

        .page {
          page-break-after: always;
        }
      `,
        sandbox: false,
        orientation: "vertical",
        page_size: "A4",
        margin_top: "1cm",
        margin_bottom: "1cm",
        margin_left: "1cm",
        margin_right: "1cm",
        disable_html_validation: true,
      }),
    };

    axios
      .request(options)
      .then(function (response) {
        downloadFile(response.data.data.url, `${fileName}.pdf`);
        setTimeout(() => {
          setDownloading(false);
        }, 2000);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  return (
    <div className="simplifyContainer">
      <Navbar />

      <div className="rightContainer">
        <div className="rightContainerHeader">
          <h1 className="rightContainerHeaderText">Simplify your Document</h1>
          <p className="rightContainerHeaderDescription">
            View your document in a easy-to understand and a visual format and
            identify important points.
          </p>
        </div>

        <div className="simplifyDocumentsContainer">
          <div className="simplifyDocumentsResultsContainer">
            <Link to="/simplify" className="simplifyDocumentsGoBack">
              <MdOutlineArrowBack className="simplifyDocumentsGoBackIcon" />
              <p className="simplifyDocumentsGoBackText">Go Back</p>
            </Link>

            <div className="simplifyDocumentsResults">
              <div className="simplifyDocumentResultsMainContent">
                <div className="simplifiedDocumentRightSection">{/*  */}</div>
                {/* )} */}

                <div className="simplifyDocumentsHeader">{/*  */}</div>

                <div className="simplifyDocumentMarkdown">
                  <div
                    onClick={downloadDocument}
                    className={`downloadDocumentButton ${
                      downloading && "disabledButton"
                    }`}
                  >
                    {downloading ? (
                      <ClipLoader
                        color={"white"}
                        loading={true}
                        size={13}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        speedMultiplier={2}
                      />
                    ) : (
                      <BiDownload />
                    )}
                    <span>{downloading ? "Downloading..." : "Download"}</span>
                  </div>
                  <Markdown remarkPlugins={[remarkGfm]}>
                    {simplifiedDocument?.content
                      ?.map((eachElement) => {
                        if (eachElement.type === "TITLE") {
                          return `# ${eachElement.text}`;
                        } else if (eachElement.type === "SUBTITLE") {
                          return `## ${eachElement.text}`;
                        } else if (eachElement.type === "PARAGRAPH") {
                          return eachElement.text;
                        }
                        return "";
                      })
                      .join("\n\n")}
                  </Markdown>
                </div>

                {/* {simplifiedDocument?.content.map((eachElement) => {
                  if (eachElement.type === "TITLE") {
                    return (
                      <>
                        <h1 className="simplifyDocumentsHeaderText">
                          {eachElement.text}
                        </h1>
                      </>
                    );
                  } else if (eachElement.type === "SUBTITLE") {
                    if (eachElement.text.includes("<>")) {
                      return (
                        <div className="simplifyResultsCards">
                          {getSimplifiedCardsData(eachElement.text).map(
                            (eachCardData) => (
                              <div className="simplifyResultEachCard">
                                <BsStars className="resultCardIcon" />

                                <div className="simplifyResultCardContent">
                                  <p className="resultCardLabel">
                                    {eachCardData.label}
                                  </p>
                                  <p className="resultCardText">
                                    {eachCardData.data}
                                  </p>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      );
                    } else {
                      return (
                        <p
                          className="simplifyResultInfoHeader"
                          id={convertTextToSlug(eachElement.text)}
                        >
                          {eachElement.text}
                        </p>
                      );
                    }
                  } else if (eachElement.type === "PARAGRAPH") {
                    return (
                      <p className="simplifyResultInfoDescription">
                        {eachElement.text}
                      </p>
                    );
                  }
                })} */}

                {simplifiedDocument === null && (
                  <div className="skeletonLoader" id="simplifyDocumentLoader">
                    <div className="skeletonLoaderElement skeletonHeader"></div>
                    <div className="skeletonParagraphGroup">
                      <div className="skeletonLoaderElement skeletonParagraph"></div>
                      <div className="skeletonLoaderElement skeletonParagraph"></div>
                      <div className="skeletonLoaderElement skeletonParagraph"></div>
                    </div>

                    <div className="skeletonLoaderElement skeletonHeader"></div>
                    <div className="skeletonParagraphGroup">
                      <div className="skeletonLoaderElement skeletonParagraph"></div>
                      <div className="skeletonLoaderElement skeletonParagraph"></div>
                      <div className="skeletonLoaderElement skeletonParagraph"></div>
                    </div>

                    <div className="skeletonLoaderElement skeletonHeader"></div>
                    <div className="skeletonParagraphGroup">
                      <div className="skeletonLoaderElement skeletonParagraph"></div>
                      <div className="skeletonLoaderElement skeletonParagraph"></div>
                      <div className="skeletonLoaderElement skeletonParagraph"></div>
                    </div>
                  </div>
                )}
              </div>

              {simplifiedDocument && (
                <div className="simplifyDocumentsContents">
                  <p className="simplifyDocumentsContentsHeader">CONTENTS</p>

                  <div className="simplifyDocumentsContentsList">
                    {simplifiedDocument?.content
                      .filter(
                        (eachElement) =>
                          eachElement.type === "SUBTITLE" &&
                          !eachElement.text.includes("<>")
                      )
                      .map((eachContent) => (
                        <p
                          onClick={() => {
                            scrollToSection(
                              convertTextToSlug(eachContent.text)
                            );
                          }}
                          className="simplifyDocumentsEachContent"
                        >
                          {eachContent.text}
                        </p>
                      ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// <div className="simplifyResultEachCard">
// <BsStars className="resultCardIcon" />

// <div className="simplifyResultCardContent">
//   <p className="resultCardLabel">Monthly Salary</p>
//   <p className="resultCardText">$400/month</p>
// </div>
// </div>

// <div className="simplifyResultEachCard">
// <BsStars className="resultCardIcon" />
// <div className="simplifyResultCardContent">
//   <p className="resultCardLabel">
//     Probation Period
//   </p>
//   <p className="resultCardText">3 months</p>
// </div>
// </div>

// <div className="simplifyResultEachCard">
// <BsStars className="resultCardIcon" />
// <div className="simplifyResultCardContent">
//   <p className="resultCardLabel">Expiration Date</p>
//   <p className="resultCardText">12th Feb 2024</p>
// </div>
// </div>
