import React from "react";
import { BiAlarmSnooze, BiSolidAlarmAdd } from "react-icons/bi";
import { Modal } from "../../components/Modal";

const RemindersModal = ({ isOpen, onClose, reminders }) => {
  return (
    isOpen && (
      <Modal
        onClose={onClose}
        HeaderIcon={BiAlarmSnooze}
        headerText={"Reminders"}
        onClick={onClose}
        loading={false}
        buttonText="Done"
        showButtonContainer={true}
      >
        <div className="remindersContainer">
          {reminders.map((eachReminder, index) => (
            <div className="eachReminder" key={index}>
              <BiSolidAlarmAdd className="reminderIcon" />
              <div>
                <p
                  className="reminderTitle"
                  style={{ fontSize: "15px", lineHeight: "1.5em" }}
                >
                  {eachReminder.message.substring(0, 100)}...
                </p>
                <p className="reminderDesc" style={{ opacity: 0.9 }}>
                  {eachReminder.message}
                </p>
                <p
                  className="reminderDesc"
                  style={{ textDecoration: "underline" }}
                >
                  Reminding on {new Date(eachReminder.date).toISOString()}(
                  {(() => {
                    const reminderDate = new Date(eachReminder.date);
                    const now = new Date();
                    const diffInMs = reminderDate - now;
                    const diffInMinutes = Math.floor(
                      Math.abs(diffInMs) / (1000 * 60)
                    );
                    const diffInHours = Math.floor(
                      Math.abs(diffInMs) / (1000 * 60 * 60)
                    );
                    const diffInDays = Math.floor(
                      Math.abs(diffInMs) / (1000 * 60 * 60 * 24)
                    );

                    if (diffInMs > 0) {
                      if (diffInDays > 0) {
                        return `${diffInDays} day(s) from now`;
                      } else if (diffInHours > 0) {
                        return `${diffInHours} hour(s) from now`;
                      } else {
                        return `${diffInMinutes} minute(s) from now`;
                      }
                    } else {
                      if (diffInDays > 0) {
                        return `${diffInDays} day(s) past due`;
                      } else if (diffInHours > 0) {
                        return `${diffInHours} hour(s) past due`;
                      } else {
                        return `${diffInMinutes} minute(s) past due`;
                      }
                    }
                  })()}
                  )
                </p>

                <p
                  className="reminderDesc"
                  style={{
                    opacity: 0.9,
                    marginTop: "20px",
                    paddingTop: "15px",
                    borderTop: "1px solid rgba(0,0,0,0.1)",
                  }}
                >
                  You will receive an email before this reminder itself with the
                  next steps, ensuring punctuality.
                </p>
              </div>
            </div>
          ))}
        </div>
      </Modal>
    )
  );
};

export default RemindersModal;
