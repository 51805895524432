import React, { useState, useEffect } from "react";
import { useSpring, animated } from "@react-spring/web";
import { Oval } from "react-loader-spinner";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getUserAPI } from "../api/apiCalls";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../redux/actions/userAction";
import { updateDocument } from "../redux/actions/documentActions";

// Function to import a key from a Base64 string
async function importKey(base64Key) {
  const binaryKey = Uint8Array.from(atob(base64Key), (c) => c.charCodeAt(0));
  return await crypto.subtle.importKey(
    "raw",
    binaryKey,
    { name: "AES-CBC", length: 256 },
    true,
    ["encrypt", "decrypt"]
  );
}

// Decrypt function (for the app side)
export async function decrypt(encryptedData, key) {
  // Decode Base64
  const binaryData = Uint8Array.from(atob(encryptedData), (c) =>
    c.charCodeAt(0)
  );

  // Extract IV (first 16 bytes) and ciphertext (rest)
  const iv = binaryData.slice(0, 16);
  const ciphertext = binaryData.slice(16);

  // Decrypt
  const decrypted = await crypto.subtle.decrypt(
    {
      name: "AES-CBC",
      iv: iv,
    },
    key,
    ciphertext
  );

  // Convert back to string
  const decoder = new TextDecoder();
  return JSON.parse(decoder.decode(decrypted));
}

export const RedirectSuccess = () => {
  const [loadingText, setLoadingText] = useState("Loading");
  const [textOpacity, setTextOpacity] = useState(1);
  const [decryptedData, setDecryptedData] = useState(null);
  const [error, setError] = useState(null);
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isOnboardingCompleted = false } = useSelector((state) => state.user);

  const fadeProps = useSpring({
    opacity: textOpacity,
    config: { duration: 500 },
  });

  const getDecryptedData = async (encryptedData, keyStr) => {
    try {
      // Import the encryption key from the Base64 string
      const importedKey = await importKey(keyStr);

      // Decrypt the data
      const decrypted = await decrypt(encryptedData, importedKey);
      console.log("Decrypted data:", decrypted);
      setDecryptedData(decrypted); // Update state with decrypted data

      // Decrypt the data

      let loginData = decrypted.loginData;

      dispatch(
        updateUser({
          id: loginData._id,
          name: loginData.name,
          email: loginData.email,
          token: decrypted.token,
          onboardingShown: true,
          isOnboardingCompleted: loginData.isOnboardingCompleted,
          companyName: loginData.companyName,
          country: loginData.country,
          plan: loginData.plan,
          customDocumentMessage: decrypted.message,
        })
      );

      setTimeout(() => {
        navigate("/");
      }, 2000);

      console.log("is onboarding completed: ", isOnboardingCompleted);

      if (decrypted.message && loginData.isOnboardingCompleted === true) {
        console.log("changing document modal to show --->");
        // addding the user's landing page text to this document modal's text
        dispatch(
          updateDocument({
            initialText: decrypted.message,
            initiateTask: true,
          })
        );
        // opening the document modal
        dispatch(
          updateUser({
            showDocumentsModal: true,
          })
        );

        // create custom draft
      }
    } catch (err) {
      console.error("Decryption failed:", err);
      setError(
        "Failed to decrypt data. Please check the encryption key or data."
      );
    }
  };

  useEffect(() => {
    const loadingMessages = ["Loading", "Please wait", "Redirecting"];
    let index = 0;

    console.log(process.env);

    const encryptedData = searchParams.get("data");
    const decryptionKey = process.env.REACT_APP_ENCRYPTION_KEY; // Use Vite-style env variable or adjust based on your setup

    if (!encryptedData) {
      setError("No encrypted data found in URL.");
      return;
    }

    if (!decryptionKey) {
      setError("Encryption key is not defined in environment variables.");
      return;
    }

    getDecryptedData(encryptedData, decryptionKey);

    const interval = setInterval(() => {
      setTextOpacity(0); // Start fading out
      setTimeout(() => {
        index = (index + 1) % loadingMessages.length;
        setLoadingText(loadingMessages[index]);
        setTextOpacity(1); // Fade back in
      }, 500); // Match the fade-out duration
    }, 3000);

    return () => clearInterval(interval);
  }, [searchParams]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "white",
      }}
    >
      <Oval
        height={40}
        width={40}
        color="#007bff"
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#0056b3"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
      <animated.p
        style={{
          marginTop: "20px",
          fontSize: "18px",
          color: "#333",
          ...fadeProps,
        }}
      >
        {error || loadingText}
      </animated.p>
      {/* {decryptedData && (
        <p
          style={{
            marginTop: "10px",
            fontSize: "16px",
            color: "#555",
          }}
        >
          Decrypted Data: {JSON.stringify(decryptedData)}
        </p>
      )} */}
    </div>
  );
};
