import React from "react";
import { BsArrowRightShort } from "react-icons/bs";
import { Oval } from "react-loader-spinner";
import "../Onboarding.css";

const Step3 = ({
  loading,
  countries,
  selectedCountry,
  setSelectedCountry,
  selectedState,
  setSelectedState,
  getCountryInfo,
  getStateInfo,
  onboardingLoading,
  addOnboardingDetailsAPI,
  setOnboardingStepNumber,
}) => {
  return (
    <>
      <div className="appOnboardingHeader">
        <img
          src="/blueTransparent.png"
          className="appOnboardingLogo"
          alt="Airstrip Logo"
        />
        <h1 className="appOnboardingHeaderText">Where are you located?</h1>
        <p className="appOnboardingHeaderDescription">
          This helps us provide location-specific legal documents that comply
          with your local regulations.
        </p>
      </div>

      <div className="appOnboardingMain">
        <p className="stepLabel">Step 3 of 3</p>

        <div className="appOnboardingInputContainer">
          <label className="label" htmlFor="location">
            Company Location
          </label>

          {loading ? (
            <div className="loadingContent">
              <p className="loadingText">Getting countries</p>

              <Oval
                height={12}
                width={12}
                color="#1252f3"
                wrapperStyle={{ marginLeft: 5 }}
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#1252f320"
                strokeWidth={5}
                strokeWidthSecondary={5}
              />
            </div>
          ) : (
            countries.length !== 0 && (
              <>
                <select
                  id="country"
                  value={selectedCountry?.iso3 || ""}
                  onChange={(e) => {
                    const countryInfo = getCountryInfo(e.target.value);
                    if (countryInfo) {
                      setSelectedCountry(countryInfo);

                      if (countryInfo.states && countryInfo.states.length > 1) {
                        setSelectedState({
                          state_code: countryInfo.states[0]["state_code"],
                          name: countryInfo.states[0]["name"],
                        });
                      }
                    }
                  }}
                  className="input"
                  style={{ marginBottom: "10px" }}
                >
                  <option value="">Select a country</option>
                  {countries.map((country) => (
                    <option key={country.iso3} value={country.iso3}>
                      {country.name}
                    </option>
                  ))}
                </select>

                {selectedCountry &&
                  getCountryInfo(selectedCountry.iso3)?.states?.length > 1 && (
                    <select
                      id="state"
                      value={selectedState.state_code}
                      onChange={(e) =>
                        setSelectedState(getStateInfo(e.target.value))
                      }
                      className="input"
                    >
                      <option value="">Select a state</option>
                      {getCountryInfo(selectedCountry.iso3).states.map(
                        (state) => (
                          <option
                            key={state.state_code}
                            value={state.state_code}
                          >
                            {state.name}
                          </option>
                        )
                      )}
                    </select>
                  )}
              </>
            )
          )}
        </div>

        <div className="appOnboardingMainButtons">
          <div
            className={`onboardingNextButton ${
              onboardingLoading && "disabledButton"
            }`}
            onClick={() => {
              addOnboardingDetailsAPI();
            }}
          >
            <p className="onboardingButtonText">Continue</p>
            <BsArrowRightShort size={18} />

            {onboardingLoading && (
              <Oval
                height={12}
                width={12}
                color="#ffffff"
                wrapperStyle={{ marginLeft: 5 }}
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#ffffff30"
                strokeWidth={5}
                strokeWidthSecondary={5}
              />
            )}
          </div>

          <div
            onClick={() => setOnboardingStepNumber(2)}
            className="appOnboardingBackButton"
          >
            Back
          </div>
        </div>
      </div>
    </>
  );
};

export default Step3;
