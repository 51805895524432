import React, { useRef, useState, useEffect } from "react";
import { Navbar } from "../components/Navbar";
import { BiCustomize, BiSave } from "react-icons/bi";
import { BsPeople, BsStars } from "react-icons/bs";
import { IoBusiness } from "react-icons/io5";
import { TbTool } from "react-icons/tb";
import {
  customisePilotAPI,
  getCustomisedDetailsAPI,
  customisePilotFromWebsiteAPI,
} from "../api/apiCalls";
import { Loader } from "rsuite";
import { LuLoader } from "react-icons/lu";
import { AiOutlineStar } from "react-icons/ai";
import { FaStar } from "react-icons/fa";

import { Tooltip } from "../components/Tooltip";

import ClipLoader from "react-spinners/ClipLoader";

export const CustomisePilot = () => {
  const [selectedTab, setSelectedTab] = useState("companyInfo");
  const [formData, setFormData] = useState({
    website: "",
    briefDesc: "",
    incorporated: "",
    customersLocations: "",
    customInstructions: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isWebsiteLoading, setIsWebsiteLoading] = useState(false);
  const [isWebsiteValid, setIsWebsiteValid] = useState(true);

  const sectionRefs = {
    companyInfo: useRef(null),
    customers: useRef(null),
    instructions: useRef(null),
  };

  useEffect(() => {
    const fetchCustomisedDetails = async () => {
      setIsLoading(true);
      try {
        const response = await getCustomisedDetailsAPI();
        if (response && response.data) {
          setFormData({
            website: response.data.website.replace(/[#*`]/g, "") || "",
            briefDesc: response.data.briefDesc.replace(/[#*`]/g, "") || "",
            incorporated:
              response.data.incorporated.replace(/[#*`]/g, "") || "",
            customersLocations:
              response.data.customerLocations.replace(/[#*`]/g, "") || "",
            customInstructions:
              response.data.customInstructions.replace(/[#*`]/g, "") || "",
          });
        }
      } catch (error) {
        console.error("Error fetching customised details:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCustomisedDetails();
  }, []);

  const validateWebsite = (url) => {
    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?([\\w-]+\\.)+[\\w-]{2,}(\\/[\\w-]*)*$",
      "i"
    );
    return urlPattern.test(url);
  };

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));

    if (field === "website") {
      const isValid = validateWebsite(value);
      setIsWebsiteValid(isValid);
    }
  };

  const handleSaveChanges = async () => {
    setIsSaving(true);
    try {
      const response = await customisePilotAPI(formData);
      if (response?.status === 200) {
        // alert("Changes saved successfully!");
      } else {
        // alert("Failed to save changes. Please try again.");
      }
    } catch (error) {
      console.error("Error saving changes:", error);
      //   alert("An error occurred while saving changes.");
    } finally {
      setIsSaving(false);
    }
  };

  const handleWebsiteFetch = async () => {
    if (!formData.website || !isWebsiteValid) {
      //   alert("Please enter a valid website URL first.");
      return;
    }

    setIsWebsiteLoading(true);
    try {
      const response = await customisePilotFromWebsiteAPI({
        website: formData.website,
      });
      if (response?.data) {
        setFormData((prevData) => ({
          ...prevData,
          briefDesc: response.data.briefDesc || "",
          incorporated: response.data.incorporated || "",
          customersLocations: response.data.customerLocations || "",
          customInstructions: response.data.customInstructions || "",
        }));
        // alert("Website data fetched and autofilled successfully!");
      } else {
        // alert("Failed to fetch website data. Please try again.");
      }
    } catch (error) {
      console.error("Error fetching website data:", error);
      //   alert("An error occurred while fetching website data.");
    } finally {
      setIsWebsiteLoading(false);
    }
  };

  const scrollToSection = (section) => {
    setSelectedTab(section);
    const ref = sectionRefs[section];
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  return (
    <div className="customisePilotContainer">
      <Navbar />

      <div className="rightContainer">
        <div className="documentsContainer">
          <div className="customisePilotContent">
            <div className="rightContainerHeader">
              <h1 className="rightContainerHeaderText">Customise Pilot</h1>
              <p className="rightContainerHeaderDescription">
                Set up Pilot to match your company’s needs and make it work
                better for your team.
              </p>
            </div>

            <div className="tabsContainer customisePilotTabs">
              <div
                className={`eachTab ${
                  selectedTab === "companyInfo" ? "selectedTab" : ""
                }`}
                onClick={() => scrollToSection("companyInfo")}
              >
                <IoBusiness className="eachTabIcon" />
                <p>Company Information</p>
              </div>
              <div
                className={`eachTab ${
                  selectedTab === "customers" ? "selectedTab" : ""
                }`}
                onClick={() => scrollToSection("customers")}
              >
                <BsPeople className="eachTabIcon" />
                <p>Customers</p>
              </div>

              <div
                className={`eachTab ${
                  selectedTab === "instructions" ? "selectedTab" : ""
                }`}
                onClick={() => scrollToSection("instructions")}
              >
                <TbTool className="eachTabIcon" />
                <p>Custom Instructions</p>
              </div>
            </div>

            {isLoading ? (
              <div
                style={{
                  width: "60%",
                  margin: "0 auto",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "40px 0",
                  fontSize: "18px",
                }}
              >
                <Loader
                  content={<LuLoader />}
                  speed="fast"
                  style={{
                    animation: "spinPulse 1.5s infinite ease-in-out",
                  }}
                />
              </div>
            ) : (
              <div
                style={{
                  animation: isWebsiteLoading
                    ? "pulse 1.5s infinite ease-in-out"
                    : "fadeInPricingPlans 0.5s ease-in-out",
                }}
              >
                <div
                  className="customisePilotSections"
                  ref={sectionRefs.companyInfo}
                >
                  <h1 className="customisePilotSectionHeader">
                    <h1>Company Information</h1>
                    <p>Basic information about your company</p>
                  </h1>

                  <div className="customisePilotGrid">
                    <div className="customisePilotEachSection">
                      <div
                        className={`customisePilotInputSection ${
                          formData["website"].length !== 0 &&
                          "prefilledInputSection"
                        }`}
                      >
                        <p>Company Website</p>
                        <input
                          className="customisePilotInput"
                          placeholder="e.g., https://www.example.com"
                          value={formData.website}
                          onChange={(e) =>
                            handleInputChange("website", e.target.value)
                          }
                          disabled={isWebsiteLoading}
                        />
                        {!isWebsiteValid && (
                          <p
                            className="errorText"
                            style={{
                              marginTop: "5px",
                              fontWeight: "400",
                              fontStyle: "italic",
                            }}
                          >
                            Please enter a valid website URL.
                          </p>
                        )}
                        <button
                          className="fetchWebsiteButton"
                          onClick={handleWebsiteFetch}
                          disabled={isWebsiteLoading || !isWebsiteValid}
                          style={{
                            marginLeft: "10px",
                            cursor:
                              isWebsiteLoading || !isWebsiteValid
                                ? "not-allowed"
                                : "pointer",
                          }}
                        >
                          {isWebsiteLoading ? (
                            <ClipLoader
                              color={"blue"}
                              loading={true}
                              size={15}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                              speedMultiplier={2}
                            />
                          ) : (
                            <Tooltip text="Auto-fill with information from this website">
                              <BsStars className="websiteFillIcon" size={20} />
                            </Tooltip>
                          )}
                        </button>
                      </div>

                      <div
                        className={`customisePilotInputSection ${
                          formData["briefDesc"].length !== 0 &&
                          "prefilledInputSection"
                        }`}
                      >
                        <p>Brief Description of Your Company</p>
                        <textarea
                          className="customisePilotInput"
                          placeholder="e.g., We are a tech company specializing in AI solutions."
                          value={formData.briefDesc}
                          onChange={(e) =>
                            handleInputChange("briefDesc", e.target.value)
                          }
                        />
                      </div>

                      <div
                        className={`customisePilotInputSection ${
                          formData["incorporated"].length !== 0 &&
                          "prefilledInputSection"
                        }`}
                      >
                        <p>Company Incorporation Location</p>
                        <input
                          className="customisePilotInput"
                          placeholder="e.g., Delaware, USA"
                          value={formData.incorporated}
                          onChange={(e) =>
                            handleInputChange("incorporated", e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="customisePilotSections"
                  ref={sectionRefs.customers}
                >
                  <div className="customisePilotSectionHeader">
                    <h1>Customer Compliance</h1>
                    <p>
                      Specify where your customers are located to help Pilot
                      provide region-specific suggestions.
                    </p>
                  </div>

                  <div className="customisePilotGrid">
                    <div className="customisePilotEachSection">
                      <div
                        className={`customisePilotInputSection ${
                          formData["customersLocations"].length !== 0 &&
                          "prefilledInputSection"
                        }`}
                      >
                        <p>Customer Locations</p>
                        <textarea
                          className="customisePilotInput"
                          placeholder="e.g., North America, Europe, Asia"
                          value={formData.customersLocations}
                          onChange={(e) =>
                            handleInputChange(
                              "customersLocations",
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="customisePilotSections"
                  ref={sectionRefs.instructions}
                >
                  <h1 className="customisePilotSectionHeader">
                    <h1>Custom Instructions</h1>
                    <p>
                      Add specific instructions for Pilot to follow in every
                      interaction. This can include policies to follow, unique
                      business practices, or specific guidelines followed in the
                      company.
                    </p>
                  </h1>

                  <div className="customisePilotGrid">
                    <div className="customisePilotEachSection">
                      <div
                        className={`customisePilotInputSection ${
                          formData["customInstructions"].length !== 0 &&
                          "prefilledInputSection"
                        }`}
                      >
                        <p>Custom Instructions for Pilot</p>
                        <textarea
                          className="customisePilotInput"
                          placeholder={`e.g., 1. Ensure all contracts are reviewed for compliance with local laws. 
2. Provide summaries of key legal obligations for clients. 
3. Flag any potential legal risks in agreements.`}
                          value={formData.customInstructions}
                          onChange={(e) =>
                            handleInputChange(
                              "customInstructions",
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div
            className="customiseSaveButton"
            onClick={handleSaveChanges}
            style={{
              opacity: isSaving || !isWebsiteValid ? 0.7 : 1,
              pointerEvents: isSaving || !isWebsiteValid ? "none" : "auto",
            }}
          >
            {isSaving ? (
              <p>Saving...</p>
            ) : (
              <p style={{ display: "flex", alignItems: "center" }}>
                <BiSave
                  style={{ marginRight: "5px", fontSize: "16px", opacity: 0.8 }}
                />{" "}
                <p>Save changes</p>
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
