import { Crisp } from "crisp-sdk-web";
import React, { useEffect, useState } from "react";
import { BsStars } from "react-icons/bs";
import { CgClose, CgFileDocument } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateUser } from "../../redux/actions/userAction";
import { PiShootingStarDuotone } from "react-icons/pi";
import { HiSwitchHorizontal } from "react-icons/hi";
import { createCustomDraftAPI } from "../../api/apiCalls";
import ClipLoader from "react-spinners/ClipLoader";
import { updateDocument } from "../../redux/actions/documentActions";
import "./CreateDocumentModal.css";

export const CreateDocumentModal = () => {
  const [documentFilterTag, setDocumentFilterTag] = useState(" ");
  const [documentSearchInput, setDocumentSearchInput] = useState("");
  const [selectedDocument, setSelectedDocument] = useState(null);
  const {
    allDocuments,
    initialText = "",
    initiateTask,
  } = useSelector((state) => state.document);
  const [docsInfo, setDocsInfo] = useState([]);

  const setInitialText = (value) => {
    dispatch(updateDocument({ initialText: value }));
  };

  const [showInitialTextbox, setShowInitialTextbox] = useState(true);
  const [customDraftLoading, setCustomDraftLoading] = useState(false);

  const dispatch = useDispatch();

  const [filteredAllDocuments, setFilteredAllDocuments] =
    useState(allDocuments);

  const createCustomFlow = async () => {
    setCustomDraftLoading(true);

    const response = await createCustomDraftAPI({
      context: initialText,
    });

    if (response.data.id && response.data.flow_id) {
      setCustomDraftLoading(false);
      dispatch(
        updateUser({
          showDocumentsModal: false,
        })
      );
      navigate(`/document/${response.data.flow_id}/create`);
    }
  };

  useEffect(() => {
    let localDocsInfo = allDocuments;

    console.log("initiating task ????");
    if (initiateTask === true) {
      console.log("yes, initiating task now....");
      createCustomFlow();
    }

    // console.log("all docs: ", allDocuments);

    // Use reduce to group objects by tags
    const groupedData = allDocuments.reduce((result, item) => {
      const { tag } = item;

      // Find the index of the tag in the result array
      const tagIndex = result.findIndex((group) => group.type === tag);

      // If the tag is not found, create a new group object
      if (tagIndex === -1) {
        result.push({ type: tag, documents: [] });
      }

      // Push the current item into the documents array for the corresponding tag
      result[tagIndex !== -1 ? tagIndex : result.length - 1].documents.push(
        item
      );

      return result;
    }, []);

    const filteredDocsInfo = groupedData.sort((a, b) => {
      const textA = a.type.toUpperCase(); // Convert to uppercase for case-insensitive sorting
      const textB = b.type.toUpperCase();

      if (textA < textB) {
        return -1;
      }
      if (textA > textB) {
        return 1;
      }
      return 0;
    });
    setDocsInfo(filteredDocsInfo);
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    let newFilteredAllDocuments = allDocuments.filter((eachDocument) =>
      eachDocument.title
        .toLowerCase()
        .includes(documentSearchInput.toLowerCase())
    );
    setFilteredAllDocuments(newFilteredAllDocuments);
  }, [documentSearchInput]);

  useEffect(() => {
    setDocumentSearchInput("");
    let newFilteredAllDocuments = allDocuments.filter((eachDocument) =>
      eachDocument.tag.includes(documentFilterTag)
    );
    setFilteredAllDocuments(newFilteredAllDocuments);
  }, [documentFilterTag]);

  useEffect(() => {
    if (selectedDocument) {
      let newFilteredAllDocuments = allDocuments.filter(
        (eachDocument) => eachDocument === selectedDocument
      );

      setFilteredAllDocuments(newFilteredAllDocuments);
    } else {
      setSelectedDocument(null);
      setFilteredAllDocuments(allDocuments);
    }
  }, [selectedDocument]);

  const createDocument = () => {
    dispatch(updateUser({ showDocumentsModal: false }));

    navigate(`/document/${selectedDocument._id}`);
  };

  return (
    <div className="createDocumentModal">
      {showInitialTextbox ? (
        <div className="createDocumentModalContent">
          <div className="createDocumentModalHeader">
            <div className="createDocumentModalIcon">
              <PiShootingStarDuotone className="sparkleIcon" />
            </div>
            <span className="createDocumentModalBadge">New</span>
            <h1 className="createDocumentModalTitle">
              Create any Legal Document you want
            </h1>
            <p className="createDocumentModalDescription">
              Write your custom requirements, start creating a tailor made
              document for your specific needs.
            </p>
            <button
              className="createDocumentModalClose"
              onClick={() => {
                dispatch(
                  updateUser({
                    showDocumentsModal: false,
                  })
                );
              }}
            >
              <CgClose />
            </button>
          </div>

          <div className="createDocumentModalBody">
            <textarea
              autoFocus
              placeholder="ex: We need a document to formalize agreements with our clients and protect our business interests."
              className="createDocumentTextarea"
              onChange={(e) => setInitialText(e.target.value)}
              value={initialText}
            ></textarea>
          </div>

          <div className="createDocumentModalFooter">
            <button
              className="createDocumentSwitchButton"
              onClick={() => setShowInitialTextbox(!showInitialTextbox)}
            >
              <HiSwitchHorizontal />
              Choose document manually
            </button>

            <button
              onClick={() => createCustomFlow()}
              className={`createDocumentButton ${
                customDraftLoading ? "loading" : ""
              }`}
              disabled={customDraftLoading}
            >
              {customDraftLoading ? (
                <>
                  <ClipLoader
                    color={"white"}
                    loading={true}
                    size={15}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    speedMultiplier={2}
                  />
                  Creating document...
                </>
              ) : (
                <>
                  <BsStars className="sparkleIcon" />
                  Create document
                </>
              )}
            </button>
          </div>
        </div>
      ) : (
        <div className="createDocumentModalContent">
          <div className="createDocumentModalHeader">
            <h1 className="createDocumentModalTitle">
              Choose the Document you need
            </h1>
            <p className="createDocumentModalDescription">
              Browse through and select the document that you need to create.
            </p>
            <button
              className="createDocumentModalClose"
              onClick={() => {
                dispatch(
                  updateUser({
                    showDocumentsModal: false,
                  })
                );
              }}
            >
              <CgClose />
            </button>
          </div>

          <div className="createDocumentModalBody">
            {!selectedDocument && (
              <>
                <input
                  className="documentSearchInput"
                  autoFocus
                  placeholder="Search for documents (e.g., Founders agreement)"
                  value={documentSearchInput}
                  onChange={(e) => setDocumentSearchInput(e.target.value)}
                />

                <div className="documentTags">
                  <div
                    className={`documentTag ${
                      documentFilterTag === " " ? "documentTagSelected" : ""
                    }`}
                    onClick={() => setDocumentFilterTag(" ")}
                  >
                    All ({allDocuments.length})
                  </div>
                  {docsInfo.map((eachDocInfo, index) => (
                    <div
                      key={index}
                      className={`documentTag ${
                        eachDocInfo.type === documentFilterTag
                          ? "documentTagSelected"
                          : ""
                      }`}
                      onClick={() => setDocumentFilterTag(eachDocInfo.type)}
                    >
                      {eachDocInfo.type} ({eachDocInfo.documents.length})
                    </div>
                  ))}
                </div>
              </>
            )}

            <div
              className={`documentList ${
                selectedDocument ? "documentSelected" : ""
              }`}
            >
              {selectedDocument && (
                <div className="documentSelectionHeader">
                  Let's create this document for you:
                </div>
              )}

              {filteredAllDocuments.map((eachDocument, index) => (
                <div
                  key={index}
                  className={`documentItem ${
                    selectedDocument === eachDocument
                      ? "documentItemSelected"
                      : ""
                  }`}
                  onClick={() => {
                    if (!selectedDocument) {
                      setSelectedDocument(eachDocument);
                    }
                  }}
                >
                  <CgFileDocument className="documentItemIcon" />
                  <div className="documentItemText">{eachDocument.title}</div>

                  {selectedDocument === eachDocument && (
                    <div
                      className="documentItemRemove"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedDocument(null);
                      }}
                    >
                      <CgClose />
                    </div>
                  )}
                </div>
              ))}

              {filteredAllDocuments.length === 0 && (
                <div className="noDocumentsFound">
                  No document found.{" "}
                  <span onClick={() => Crisp.chat.open()}>
                    Please send us a quick message
                  </span>
                </div>
              )}
            </div>
          </div>

          <div className="createDocumentModalFooter">
            <button
              className="createDocumentSwitchButton"
              onClick={() => setShowInitialTextbox(!showInitialTextbox)}
            >
              <HiSwitchHorizontal />
              Get custom document
            </button>

            <button
              onClick={() => createDocument()}
              className="createDocumentButton"
              disabled={!selectedDocument}
              style={{ opacity: !selectedDocument ? 0.6 : 1 }}
            >
              <BsStars className="sparkleIcon" />
              Create Document
            </button>
          </div>
        </div>
      )}

      <div
        className="createDocumentModalOverlay"
        onClick={() => {
          dispatch(
            updateUser({
              showDocumentsModal: false,
            })
          );
        }}
      ></div>
    </div>
  );
};
