import React from "react";
import * as Dialog from "@radix-ui/react-dialog";
import "../../radix-styles/dialog.css";
import { MdClose } from "react-icons/md";
import { Oval } from "react-loader-spinner";

const RadixModal = ({
  triggerComponent,
  children,
  title,
  description,
  buttonText,
  secondaryText,
  secondaryOnClick,
  onClick,
  openModal,
  setOpenModal,
  className,
  loading,
  icon,
}) => (
  <div className={`DialogContainer`}>
    <Dialog.Root open={openModal} onOpenChange={setOpenModal}>
      {/* <Dialog.Trigger asChild>{triggerComponent()}</Dialog.Trigger> */}
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className={`DialogContent ${className}`}>
          <div className="DialogHeader">
            {icon && <div className="DialogIcon">{icon}</div>}
            <Dialog.Title className="DialogTitle">{title}</Dialog.Title>
            <Dialog.Description className="DialogDescription">
              {description}
            </Dialog.Description>
          </div>

          <div className="DialogContentContainer">{children}</div>

          <div asChild className="ButtonContainers">
            {buttonText && (
              <button
                onClick={() => onClick()}
                className="ButtonMain"
                style={{ opacity: loading ? 0.6 : 1 }}
              >
                {loading && (
                  <Oval
                    height={15}
                    width={15}
                    color="white"
                    wrapperStyle={{ marginLeft: 5 }}
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#ffffff7b"
                    strokeWidth={5}
                    strokeWidthSecondary={5}
                  />
                )}
                <span style={{ marginLeft: "5px" }}>{buttonText}</span>
              </button>
            )}

            {secondaryText && (
              <button
                onClick={() => secondaryOnClick()}
                className="ButtonSecondary"
                style={{ opacity: loading ? 0.6 : 1 }}
              >
                <span style={{ marginLeft: "5px" }}>{secondaryText}</span>
              </button>
            )}
          </div>

          <Dialog.Close asChild>
            <button className="IconButton" aria-label="Close">
              <MdClose />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  </div>
);

export default RadixModal;
