import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { IoAdd, IoAddCircle, IoClose, IoWarning } from "react-icons/io5";
import { TbSignature, TbVersions } from "react-icons/tb";

import { animated, useSpring } from "@react-spring/web";
import ReactDOMServer from "react-dom/server";
import {
  MdAdd,
  MdAddComment,
  MdOutlineRocketLaunch,
  MdUpdate,
} from "react-icons/md";
import { Oval } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import {
  createSignatureAPI,
  getDocumentDraftAPI,
  getDocumentPaymentLinkAPI,
  getUserAPI,
  saveDraftDocumentAPI,
  upgradePlanAPI,
} from "../../api/apiCalls";
import { updateDocument } from "../../redux/actions/documentActions";
import { PdfViewer } from "../PDFViewer";
import { DocumentViewerLoader } from "./loader";

import { BsInfoCircleFill } from "react-icons/bs";
import { IoMdCheckmark } from "react-icons/io";
import { v4 as uuidv4 } from "uuid";
import { paymentPlans } from "../../data";
import { useDocumentElementsContext } from "../FinalDocumentChat/FinalDocumentContext";
import RadixModal from "../Modal/RadixModal";
import { BiLock, BiSolidMessageSquareAdd } from "react-icons/bi";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
  HoverCardPortal,
  HoverCardArrow,
} from "@radix-ui/react-hover-card";

import "../../radix-styles/hover-card.css";
import { HighlightMenu, MenuButton } from "react-highlight-menu";
import { ReplyAll } from "lucide-react";
import { updateUser } from "../../redux/actions/userAction";

const documentAsArray = [
  {
    id: 1,
    text: "Service Agreement",
    type: "TITLE",
  },
  {
    id: 2,
    text: 'This Service Agreement (hereinafter referred to as "the Agreement") is made and entered into as of the 23rd of August 2023, by and between Minto, a financial platform for businesses that utilizes AI to understand financial data and create reports, analytics, forecast revenues, and other financial business planning (hereinafter referred to as "the Company"), and its users (hereinafter referred to as "the Users"). This Agreement governs the provision of services by Minto to its Users in accordance with the laws of California, United States.',
    type: "PARAGRAPH",
  },
  {
    id: 3,
    text: "Definitions",
    type: "SUBTITLE",
  },
  {
    id: 4,
    text: '1. "Minto" refers to the financial platform for businesses, which uses AI to understand financial data and create reports, analytics, forecast revenues, and other financial business planning.',
    type: "PARAGRAPH",
  },
  {
    id: 5,
    text: '2. "AI" refers to artificial intelligence, a branch of computer science that simulates human intelligence to perform tasks such as data analysis, problem-solving, and decision-making.',
    type: "PARAGRAPH",
  },
  {
    id: 6,
    text: '3. "Basic features" refer to the functionalities of Minto that are accessible to all users without the need for a premium plan, including limited projects, product integrations, API access, and customer support.',
    type: "PARAGRAPH",
  },
  {
    id: 7,
    text: '4. "Premium plan" refers to the upgraded subscription package offered by Minto, which grants users access to unlimited projects, unlimited product integrations, unlimited API access for developers, early access to new features, and 24/7 customer support.',
    type: "PARAGRAPH",
  },
  {
    id: 8,
    text: "5. 'Users' refer to individuals or entities who utilize Minto's services, including both free and premium plan users.",
    type: "PARAGRAPH",
  },
  {
    id: 9,
    text: '6. "Service provider" refers to Stripe, a company that makes international payments simpler through easy API and fraud detection.',
    type: "PARAGRAPH",
  },
  {
    id: 10,
    text: '7. "Services" refer to the financial planning, reporting, analytics, and other related services provided by Minto to its Users.',
    type: "PARAGRAPH",
  },
  {
    id: 11,
    text: '8. "Effective Date" refers to the date on which this Agreement comes into force, which is the 23rd of August 2023.',
    type: "PARAGRAPH",
  },
];

let drafted = false;

const DEFAULT_INITIAL_DATA = () => {
  return {
    time: new Date().getTime(),
    blocks: [
      {
        type: "header",
        data: {
          text: "This is my awesome editor!",
          level: 1,
        },
      },
    ],
  };
};

const processTextWithSources = (children) => {
  // console.log(children);
  // Handle both string and array of elements (Markdown can pass either)
  const processSingleText = (text) => {
    if (typeof text !== "string") return text;

    // console.log("checking text: ", text);

    const uuidRegex =
      /\[ID: ([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})\]/gi;
    const parts = text.split(uuidRegex);

    return parts.map((part, index) => {
      if (
        part.match(
          /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i
        )
      ) {
        return (
          <sup
            key={index}
            className={`source-ref-document document-${part}`}
            onClick={() => {
              const targetElement = document.getElementById(part);
              console.log(targetElement);
              if (targetElement) {
                targetElement.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            {/* <a
              href={`#source-${part}`}
              title={`Source ${part}`}
              // style={{ color: "#0066cc", textDecoration: "none" }}
            >
              Source
            </a> */}
          </sup>
        );
      }
      return part;
    });
  };

  // Handle arrays of children from Markdown
  if (Array.isArray(children)) {
    return children.map((child, index) =>
      typeof child === "string" ? (
        <React.Fragment key={index}>{processSingleText(child)}</React.Fragment>
      ) : (
        child
      )
    );
  }
  return processSingleText(children);
};

export const DocumentViewer = ({
  updatingElementId,
  updatingTextStream,
  setUpdatingElementId,
  setUpdatingTextStream,
  updatingElementData,
  documentStatus,
  refreshDocument: refreshDocumentState,
}) => {
  const { id, versionId } = useParams();

  const [signatures, setSignatures] = useState([]);
  // const [signatureId, setSignatureId] = useState(1);

  const [refreshDocument, setRefreshDocument] = refreshDocumentState;

  const {
    documentElementsArray,
    updateArray: setDocumentElementsArray,
    // saveDocumentElements: saveDocument,
  } = useDocumentElementsContext();

  const {
    signatures: documentSignatures,
    documentInfo,
    editor: savedEditor,
    documentUpdating,
  } = useSelector((state) => state.document);

  const [currentSelectedSignature, setCurrentSignature] = useState(null);

  const { token, ...userData } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const { unsavedChanges } = useSelector((state) => state.document);

  const [addSignatureLoading, setAddSignatureLoading] = useState(false);
  const [documentData, setDocumentData] = useState(null);
  const [streamStatus, setStreamStatus] = useState("");
  const [showVersions, setShowVersions] = useState(false);

  // document changing
  const [updatingElementInfo, setUpdatingElementInfo] = updatingElementData;
  const [isDocumentChanging, setIsDocumentChanging] = useState(false);

  let localArray = [];

  const [documentViewerProps, setDocumentViewerProps] = useSpring(() => ({
    backgroundColor: "white",
    padding: 0,
    borderRadius: 0,
  }));

  const removeSignature = (elementId) => {
    // // //"removing sign");
    setCurrentSignature(null);

    document.getElementById(elementId).remove();

    const newSignaturesArray = signatures.filter(
      (signature) => signature.id !== elementId
    );

    setSignatures(newSignaturesArray);
    dispatch(
      updateDocument({
        signatures: newSignaturesArray,
      })
    );
  };
  let signatureId = 1;

  const handleSignatureDrop = (dragEvent) => {
    // // //"Signature Drag Event: ", dragEvent);

    let signatureIdDiv = `finalDocumentSignatureElement${signatureId}`;

    const signatureElement = document.createElement("div");
    // signatureElement.contentEditable = "false";
    signatureElement.className = "finalDocumentSignatureElement";
    signatureElement.id = `finalDocumentSignatureElement${signatureId}`;
    signatureElement.innerHTML = ReactDOMServer.renderToString(
      <>
        <p>Add your signature</p>
        <IoClose
          className="signatureElementRemove"
          id={`finalDocumentSignatureElementRemove${signatureId}`}
        />
      </>
    );
    setTimeout(() => {
      const removeSignElement = document.getElementById(
        `finalDocumentSignatureElementRemove${signatureId}`
      );

      if (removeSignElement) {
        const signatureElement = document.getElementById(signatureIdDiv);
        signatureElement.addEventListener("click", (e) => {
          setCurrentSignature(signatureIdDiv);
        });

        removeSignElement.addEventListener("click", (e) => {
          removeSignature(e.target.parentElement.id);
        });

        return () => {
          signatureElement.removeEventListener("click", () =>
            setCurrentSignature(signatureIdDiv)
          );
          removeSignElement.removeEventListener("click", removeSignature);
        };
      }
    }, 500);

    signatureElement.style = `position: absolute; top: ${
      dragEvent.layerY - 0
    }px; left: ${dragEvent.layerX - 80}px`;

    // // //dragEvent.target);

    const documentViewerElement =
      document.getElementById(dragEvent.target.id) ||
      document.getElementById(dragEvent.target.offsetParent.id);
    documentViewerElement.appendChild(signatureElement);

    const signaturesList = [...signatures];
    signaturesList.push({
      id: signatureIdDiv,
      pageNumber: dragEvent.target.id.split("finalDocumentViewer")[1],
      xPosition: dragEvent.layerX,
      yPosition: dragEvent.layerY,
      details: null,
    });

    signatureId = signatureId + 1;

    setCurrentSignature(signatureIdDiv);

    setSignatures(signaturesList);
    dispatch(
      updateDocument({
        signatures: [
          ...documentSignatures,
          {
            id: signatureIdDiv,
            pageNumber: dragEvent.target.id.split("finalDocumentViewer")[1],
            xPosition: dragEvent.layerX,
            yPosition: dragEvent.layerY,
            details: null,
          },
        ],
      })
    );
  };

  const convertStringToHTML = (htmlString) => {
    const parser = new DOMParser();
    const html = parser.parseFromString(htmlString, "text/html");

    return html.body;
  };

  // let localDocumentElementsArray = [...documentElementsArray];

  const saveDocument = async () => {
    // const formatted = returnFormattedDocumentsArray();

    const response = await saveDraftDocumentAPI({
      draft_id: id,
      content: localArray,
    });

    if (response.data) {
      // dispatch(
      //   updateDocument({
      //     documentInfo: {
      //       ...documentInfo,
      //       document_drafts: [localArray],
      //     },
      //   })
      // );

      getDocumentDraft();

      setStreamStatus("COMPLETED");

      const mainContainer = document.querySelector("#finalDocumentViewer");
      if (mainContainer) {
        mainContainer.scroll({
          top: 0,
          behavior: "smooth",
        });
      }
    }
  };

  const addToElements = (object) => {
    // if (documentInfo && documentInfo?.status === "LIMITED_DOCUMENT_DRAFTED") {
    //   //("PUSHING SAVED ARRAY TO LOCAL ARRAY");
    //   localArray = [
    //     documentInfo?.document_drafts[documentInfo?.document_drafts?.length - 1],
    //   ];
    // }

    if (object && !object.text.includes("null")) {
      localArray.push({
        id: uuidv4(),
        ...object,
      });
      setDocumentElementsArray((prev) => {
        return [...localArray];
      });
      dispatch(
        updateDocument({
          documentElements: [...localArray],
        })
      );
    }
  };

  const [showIncompleteDocModal, setShowIncompleteDocModal] = useState(false);

  // TODO: HANDLE THE STREAMING OF THE DOCUMENT DRAFT PROPERLY: ITS NOT WORKING NOW
  let newElement = null;

  async function readStream(stream, onChunk, onComplete) {
    const reader = stream.getReader();
    const textDecoder = new TextDecoder();

    while (true) {
      setStreamStatus("PENDING");

      const { done, value } = await reader.read();
      if (done) {
        onComplete();
        return;
      }

      const chunk = textDecoder.decode(new Uint8Array(value));
      onChunk(chunk);
    }
  }

  const streamDocumentDraft = async (editor) => {
    setDocumentLoading(true);

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/flow/draft-document`,
      {
        method: "post",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          api_key: "krishna",
          Authorization: token,
        },
        body: JSON.stringify({
          draftID: id,
        }),
      }
    );

    if (response.ok) {
      setDocumentLoading(false);

      let newElement = null; // Accumulator for building elements

      await readStream(
        response.body,
        (chunk) => {
          const parsedChunks = chunk.trim().split("\n").map(JSON.parse);
          parsedChunks.forEach((parsedChunk) => {
            if (parsedChunk.type === "stream") {
              const data = parsedChunk.data;
              let content;

              // Extract content from either string or object format
              if (typeof data === "string") {
                content = data;
              } else if (data?.delta?.content) {
                content = data.delta.content;
              } else {
                return; // Skip invalid chunks
              }

              if (typeof content !== "string") return;

              // Handle TITLE (^)
              if (!newElement && content.trim().startsWith("^")) {
                newElement = content.trim().replace(/^\^/, "");
                if (newElement.includes("^")) {
                  const [title] = newElement.split("^");
                  addToElements({ type: "TITLE", text: title.trim() });
                  newElement = null;
                }
                return;
              }

              if (newElement !== null && content.includes("^")) {
                const [title, ...rest] = (newElement + content).split("^");
                addToElements({ type: "TITLE", text: title.trim() });
                newElement = rest.join("^").trim() || null;
                return;
              }

              // Handle SUBTITLE (#)
              if (!newElement && content.trim().startsWith("#")) {
                newElement = content.trim().replace(/^#+/, "").trim();
                if (content.includes("#\n\n")) {
                  addToElements({ type: "SUBTITLE", text: newElement });
                  newElement = null;
                }
                return;
              }

              if (newElement !== null && content.includes("#\n\n")) {
                const [subtitle] = (newElement + content).split("#\n\n");
                addToElements({ type: "SUBTITLE", text: subtitle.trim() });
                newElement = null;
                return;
              }

              // Handle PARAGRAPH (\n\n)
              if (newElement !== null && content.includes("\n\n")) {
                const [paragraph, ...rest] = (newElement + content).split(
                  "\n\n"
                );
                addToElements({ type: "PARAGRAPH", text: paragraph.trim() });
                newElement = rest.join("\n\n").trim() || null;
                return;
              }

              // Accumulate content
              if (newElement !== null) {
                newElement += content;
                return;
              }

              // Start new element if no marker is found
              if (!newElement && content.trim()) {
                newElement = content.trim();
              }
            }
          });
        },
        () => {
          // Finalize any remaining content
          if (newElement) {
            let type = "PARAGRAPH";
            if (newElement.startsWith("^")) {
              type = "TITLE";
              newElement = newElement.replace(/^\^/, "").split("^")[0];
            } else if (newElement.startsWith("#")) {
              type = "SUBTITLE";
              newElement = newElement.replace(/^#+/, "").trim();
            }
            addToElements({ type, text: newElement.trim() });
          }

          saveDocument(editor);

          if (documentInfo?.resourceType === "FREE_RESOURCE") {
            if (
              documentStatus &&
              documentStatus !== "ACCESS_GRANTED_FOR_DOCUMENT"
            ) {
              setShowIncompleteDocModal(true);
              setShowExpandedUpgradeButton(true);
              setShowUpgradePlan(true);
              dispatch(
                updateDocument({
                  documentInfo: {
                    ...documentInfo,
                    payment: false,
                    resourceType: "FREE_RESOURCE",
                    status: "LIMITED_DOCUMENT_DRAFTED",
                  },
                })
              );
            }
          } else {
            setTimeout(() => {
              const link = document.createElement("a");
              const linkUrl = `https://tally.so#tally-open=mVQJll&tally-width=570&tally-emoji-text=👋&tally-emoji-animation=wave&email=${userData.email}&name=${userData.name}`;
              link.href = linkUrl;
              link.target = "_blank";
              link.style.display = "none";

              document.body.appendChild(link);
              link.click();
            }, 20000);
          }
        }
      );
    }
  };

  const closeSignature = () => {
    const isSignatureAdded = signatures.filter(
      (signature) =>
        signature.id === currentSelectedSignature &&
        signature.details !== null &&
        signature.details.name &&
        signature.details.email
    )[0];

    if (isSignatureAdded) {
      setCurrentSignature(null);
    } else {
      removeSignature(currentSelectedSignature);
      setCurrentSignature(null);
    }
  };

  const addDetailsToSignature = (signatureId, label, value) => {
    let newSignaturesArray = signatures.map((signature) => {
      if (signature.id === signatureId) {
        return {
          ...signature,
          details: {
            ...signature.details,
            [label]: value,
          },
        };
      } else {
        return {
          ...signature,
        };
      }
    });

    setSignatures(newSignaturesArray);
    dispatch(
      updateDocument({
        signatures: newSignaturesArray,
      })
    );
  };

  const getSignatureDetailsValue = (signatureId, label) => {
    const signature = signatures.filter(
      (signature) => signature.id === signatureId
    )[0];
    if (signature && signature.details) {
      return signature.details[label];
    } else return "";
  };

  const getSignatureInfo = (signatureId) => {
    const signature = signatures.filter(
      (signature) => signature.id === signatureId
    )[0];
    if (signature) {
      return signature;
    } else return;
  };

  const addSignature = async (signatureId) => {
    setAddSignatureLoading(true);

    const signature = getSignatureInfo(currentSelectedSignature);

    const response = await createSignatureAPI({
      draft: id,
      name: signature.details.name,
      email: signature.details.email,
      xPosition: signature.xPosition - 40,
      yPosition: signature.yPosition + 150,
      pageNumber: signature.pageNumber,
    });

    if (response.data) {
      addDetailsToSignature(signatureId, "finalised", true);
      const addSignatureElement = document.getElementById(signatureId);

      const handleClick = () => {
        setCurrentSignature(signatureId);
      };

      addSignatureElement.addEventListener("click", handleClick);

      const removeSignatureIconElement = document.getElementById(
        addSignatureElement.children[1].id
      );

      removeSignatureIconElement.remove();

      addSignatureElement.children[0].innerHTML =
        getSignatureInfo(signatureId).details.name;
      addSignatureElement.classList.add("detailAddedSignature");

      setAddSignatureLoading(false);

      closeSignature();

      return () => {
        addSignatureElement.removeEventListener("click", handleClick);
      };
    }
  };

  const addSignaturesToTheDocument = (signatureData) => {
    const newSignaturesArray = [];

    if (
      signatureData &&
      signatureData.signers &&
      signatureData.signers.length !== 0
    ) {
      signatureData.signers.map((signature) => {
        const signaturePageElement = document.getElementById(
          `finalDocumentViewer${signature.position.pageNumber}`
        );

        newSignaturesArray.push({
          id: signature._id,
          xPosition: signature.position.xPosition + 40,
          yPosition: signature.position.yPosition - 150,
          details: {
            name: signature.name,
            email: signature.email,
            finalised: true,
          },
        });

        const newSignatureElement = document.createElement("div");
        // newSignatureElement.contentEditable = false;
        newSignatureElement.className = "finalDocumentSignatureElement";
        newSignatureElement.classList.add("detailAddedSignature");

        newSignatureElement.innerHTML = `<p>${signature.name}</p>`;

        newSignatureElement.style = `position: absolute; top:${
          signature.position.yPosition - 170
        }px; left: ${signature.position.xPosition + 40}px;`;

        const handleClick = () => {
          setCurrentSignature(signature._id);
        };

        newSignatureElement.addEventListener("click", handleClick);

        signaturePageElement.appendChild(newSignatureElement);

        return () => {
          newSignatureElement.removeEventListener("click", handleClick);
        };
      });

      setSignatures(newSignaturesArray);
    }
  };

  const [pdfData, setPdfData] = useState(null);

  const [documentLoading, setDocumentLoading] = useState(true);
  const [documentSoftLoading, setDocumentSoftLoading] = useState(true);

  useEffect(() => {
    dispatch(
      updateDocument({
        signatures: [],
      })
    );
  }, []);

  const [purchaseLoading, setPurchaseLoading] = useState(false);

  const generatePaymentLink = async (onSuccess) => {
    setPurchaseLoading(true);

    // //document.documentInfo
    const response = await upgradePlanAPI({
      redirectUrl: window.location.href,
    });

    if (response.data) {
      window.location.href = response.data.url;
      setPurchaseLoading(false);

      if (onSuccess) {
        onSuccess();
      }
    }
  };

  const [perDocumentLoading, setPerDocumentLoading] = useState(false);

  const buyDocument = async () => {
    setPerDocumentLoading(true);

    const response = await getDocumentPaymentLinkAPI({
      documentId: documentInfo?.document,
      draftId: id,
    });

    if (response.data) {
      window.location.href = response.data.url;
      setPerDocumentLoading(false);

      //
    }
  };

  const getDocumentDraft = async (editor) => {
    setDocumentLoading(true);

    // dispatch(updateDocument({
    //   documentInfo:
    // }))

    try {
      dispatch(
        updateDocument({
          signatures: [],
          documentInfo: {
            ...documentInfo,
            lawyerReview: "",
          },
        })
      );

      const response = await getDocumentDraftAPI(id);

      if (response.data) {
        setDocumentLoading(false);

        streamDocumentAfterPayment(response.data);

        // if (response.data.resourceType === "FREE_RESOURCE") {
        //   setShowExpandedUpgradeButton(true);
        //   setShowUpgradePlan(true);
        // }

        dispatch(
          updateDocument({
            documentInfo: response.data,
          })
        );

        //"VERSION ID: ", versionId);

        // if (
        //   response.data.accessStatus === "FREE" &&
        //   response.data?.status === "LIMITED_DOCUMENT_DRAFTED"
        // ) {
        //   // alert("setting one");
        //   setShowExpandedUpgradeButton(true);
        // }
        if (versionId) {
          const documentElements =
            documentInfo?.document_drafts[parseInt(versionId) - 1].content;

          //"document elements: ", documentElements);

          setDocumentElementsArray(documentElements);

          dispatch(updateDocument({ documentElements: documentElements }));
        } else if (response.data?.document_drafts?.length !== 0) {
          setDocumentElementsArray(
            response.data?.document_drafts[
              response.data?.document_drafts?.length - 1
            ].content
          );

          dispatch(
            updateDocument({
              documentElements:
                response.data?.document_drafts[
                  response.data?.document_drafts?.length - 1
                ].content,
            })
          );
        } else {
          if (response.data?.document_drafts?.length === 0) {
            streamDocumentDraft();
          }
        }
      }
    } catch (error) {
      //"ERROR: ", error);
      return;
    }
  };

  useEffect(() => {
    if (streamStatus === "PENDING") {
      setInterval(() => {
        const loaderElement = document.getElementById("simplifyDocumentLoader");
        if (loaderElement) loaderElement.scroll({ behavior: "smooth" });
      }, 5000);
    }
  }, [streamStatus]);

  useEffect(() => {
    // setCurrentSignature(null);
    // // //signatures);
    if (signatures.length === 0) {
      setCurrentSignature(null);
    }
  }, [signatures, currentSelectedSignature]);

  useEffect(() => {
    getDocumentDraft();
  }, []);

  useEffect(() => {
    console.log("documentInfo -> ", documentInfo);
  }, [documentInfo]);

  const showDocumentVersion = (index) => {
    const documentElements =
      documentInfo?.document_drafts[parseInt(versionId)].content;

    setDocumentElementsArray(documentElements);

    dispatch(updateDocument({ documentElements: documentElements }));
    //"adding");
  };

  useEffect(() => {
    // document.querySelector(".documentContent").innerHTML = "";

    if (versionId) {
      // showDocumentVersion();
      // getDocumentDraft();
      setShowVersions(false);
    }
  }, [versionId]);

  const isParagraphBetweenHeaders = (paragraphID, headerID) => {
    let foundHeader = false;
    let foundParagraph = false;

    for (const item of documentElementsArray) {
      if (item.type === "SUBTITLE" && item.id === headerID) {
        foundHeader = true;
      } else if (foundHeader && item.type === "SUBTITLE") {
        break; // Reached the next header, exit the loop
      } else if (foundHeader && item.type === "PARAGRAPH") {
        if (item.id === paragraphID) {
          foundParagraph = true;
          break; // Found the paragraph, exit the loop
        }
      }
    }

    return foundParagraph;
  };

  const [showExpandedUpgradeButton, setShowExpandedUpgradeButton] =
    useState(true);

  const [userInfo, setUserInfo] = useState(null);
  const [showUpgradePlan, setShowUpgradePlan] = useState(false);

  const getUserInfo = async () => {
    const response = await getUserAPI();
    if (response.data) {
      setUserInfo(response.data);
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const streamDocumentAfterPayment = async (localDocumentData) => {
    // console.log("CALLING STREAM FUNCTION");
    // console.log("DOCUMENT INFO BEFORE STREAMING: ", localDocumentData);
    // alert(documentStatus);
    // // setDocumentLoading(true);
    // setStreamStatus("PENDING");
    // if (
    //   localDocumentData &&
    //   localDocumentData.status === "LIMITED_DOCUMENT_DRAFTED" &&
    //   streamStatus !== "PENDING" &&
    //   !documentLoading &&
    //   localDocumentData._id === id &&
    //   userData.plan !== "FREE_PLAN" &&
    //   documentStatus === "ACCESS_GRANTED_FOR_DOCUMENT" &&
    //   documentInfo.resourceType !== "PAID_RESOURCE" &&
    //   documentInfo.status !== "DOCUMENT_DRAFTED"
    // ) {
    //   console.log("🔴 STREAMING DOCUMENT");
    //   localArray = [];
    //   let newDocumentInfo = {
    //       ...localDocumentData,
    //       payment: true,
    //       status: "DOCUMENT_DRAFTED",
    //       resourceType: "PAID_RESOURCE",
    //     },
    //     drafted = true;
    //   dispatch(
    //     updateDocument({
    //       documentInfo: {
    //         ...documentInfo,
    //         payment: true,
    //         status: "DOCUMENT_DRAFTED",
    //         resourceType: "PAID_RESOURCE",
    //       },
    //     })
    //   );
    //   setShowExpandedUpgradeButton(false);
    //   setShowUpgradePlan(false);
    //   console.log("DOCUMENT INFO: ", newDocumentInfo);
    //   streamDocumentDraft();
    // }
  };

  useEffect(() => {
    // console.log("documentInfo:", documentInfo);
    // console.log("documentStatus:", documentStatus);
    // console.log("documentInfo.status:", documentInfo?.status);
    // console.log("streamStatus:", streamStatus);
    // console.log("documentLoading:", documentLoading);
    // console.log("documentInfo._id:", documentInfo?._id);
    // console.log("id:", id);
    // console.log("userData.plan:", userData?.plan);
    // console.log("documentInfo.resourceType:", documentInfo?.resourceType);
    // console.log("documentInfo.status:", documentInfo?.status);

    const conditions = {
      documentInfo: documentInfo,
      documentStatus: documentStatus === "ACCESS_GRANTED_FOR_DOCUMENT",
      documentInfoStatus:
        documentInfo && documentInfo.status === "LIMITED_DOCUMENT_DRAFTED",
      streamStatus: streamStatus !== "PENDING",
      documentLoading: !documentLoading,
      documentIdMatch: documentInfo && documentInfo._id === id,
      userDataPlan: userData && userData.plan === "PRO_PLAN",
      documentResourceType:
        documentInfo && documentInfo.resourceType === "FREE_RESOURCE",
      documentInfoStatusAgain:
        documentInfo && documentInfo.status === "LIMITED_DOCUMENT_DRAFTED",
    };

    // console.log("Conditions:", conditions);

    if (
      conditions.documentInfo &&
      conditions.documentStatus &&
      conditions.documentInfoStatus &&
      conditions.streamStatus &&
      conditions.documentLoading &&
      conditions.documentIdMatch &&
      conditions.userDataPlan &&
      conditions.documentResourceType &&
      conditions.documentInfoStatusAgain
    ) {
      // streamDocumentAfterPayment(documentInfo);
    }
  }, [
    documentStatus,
    documentInfo,
    streamStatus,
    documentLoading,
    id,
    userData.plan,
  ]);

  useEffect(() => {
    if (
      userInfo &&
      documentInfo &&
      documentInfo.document_drafts &&
      documentInfo?.payment === false &&
      documentInfo?.document_drafts?.length !== 0
    ) {
      // setShowExpandedUpgradeButton(true);
      setShowUpgradePlan(true);
    } else {
      setShowExpandedUpgradeButton(false);
      setShowUpgradePlan(false);
    }
  }, [documentInfo, userInfo]);

  // document change function
  let currentSubTitleId = null;

  let latestDocumentContent =
    documentInfo &&
    documentInfo.document_drafts &&
    documentInfo.document_drafts.length !== 0 &&
    documentInfo.document_drafts[
      versionId ? versionId - 1 : documentInfo.document_drafts.length - 1
    ]?.content;

  const getElementDataFromID = (id) => {
    return documentElementsArray.filter(
      (eachElement) => eachElement.id === id
    )[0];
  };

  const updateDocumentFromChat = async () => {
    if (
      updatingElementInfo &&
      updatingElementInfo?.ID &&
      updatingElementInfo?.Data &&
      updatingElementInfo?.Action &&
      updatingElementInfo?.Reason
    ) {
      // // console.log("its here: ", getElementDataFromID(updatingElementInfo.ID));

      if (
        updatingElementInfo.Action &&
        updatingElementInfo.Action === "UPDATE_CLAUSE"
      ) {
        // // // console.log(
        //   "UPDATING PARAGRAPH",
        //   getElementDataFromID(updatingElementInfo.ID)
        // );

        latestDocumentContent = latestDocumentContent.map(
          (eachContentObject) => {
            if (eachContentObject.id === updatingElementInfo.ID) {
              return {
                ...eachContentObject,
                text: updatingElementInfo.Data,
              };
            } else {
              return {
                ...eachContentObject,
              };
            }
          }
        );
      }

      if (
        updatingElementInfo.Action &&
        updatingElementInfo.Action === "UPDATE_SECTION"
      ) {
        // // console.log(
        //   "UPDATING SECTION",
        //   getElementDataFromID(updatingElementInfo.ID)
        // );

        latestDocumentContent.map((eachContentObject, index) => {
          if (eachContentObject.type === "SUBTITLE") {
            currentSubTitleId = eachContentObject.id;

            if (currentSubTitleId === updatingElementInfo.ID) {
              latestDocumentContent[index + 1] = {
                type: "PARAGRAPH",
                id: uuidv4(),
                text: updatingElementInfo.Data,
              };

              // // console.log(latestDocumentContent[index + 1]);
            }
          } else if (
            eachContentObject.type === "PARAGRAPH" &&
            currentSubTitleId === updatingElementInfo.ID &&
            latestDocumentContent[index - 1].type !== "SUBTITLE"
          ) {
            let newDocumentContentArray = latestDocumentContent.filter(
              (eachObject) => eachObject.id !== eachContentObject.id
            );
            latestDocumentContent = newDocumentContentArray;
          }
        });
      }

      if (
        updatingElementInfo.Action &&
        updatingElementInfo.Action === "NEW_CLAUSE"
      ) {
        // // console.log(
        //   "ADDING NEW CLAUSE",
        //   getElementDataFromID(updatingElementInfo.ID)
        // );

        latestDocumentContent.map((eachContentObject, index) => {
          if (eachContentObject.id === updatingElementInfo.ID) {
            let newObject = {
              type: "PARAGRAPH",
              id: uuidv4(),
              text: updatingElementInfo.Data,
            };

            latestDocumentContent.splice(index + 1, 0, newObject);

            // // console.log(latestDocumentContent[index + 1]);
          }
        });
      }

      setUpdatingElementInfo(null);

      // // console.log("NEW DOCUMENT ARRAY: ", latestDocumentContent);

      setDocumentElementsArray(latestDocumentContent);

      const response = await saveDraftDocumentAPI({
        draft_id: id,
        content: latestDocumentContent,
      });
      dispatch(
        updateDocument({
          documentInfo: {
            ...documentInfo,
            document_drafts: [
              ...documentInfo?.document_drafts,
              {
                version: documentInfo?.document_drafts?.length,
                content: latestDocumentContent,
              },
            ],
          },
          unsavedChanges: false,
        })
      );
    }
  };

  const markdownRef = useRef(null);
  const hasRunRef = useRef(false);
  const documentUpdatingRef = useRef(false);

  // Function to wait for children to appear
  const waitForChildren = (markdownRef, callback) => {
    const checkChildren = () => {
      const markdownElement = markdownRef.current;
      if (markdownElement && markdownElement.children.length > 0) {
        console.log("Children detected, proceeding to process batches");
        callback(markdownRef);
      } else {
        console.log("No children yet, checking again in 100ms");
        setTimeout(checkChildren, 100);
      }
    };
    checkChildren();
  };

  // Function to process batches with looping
  const processMarkdownBatches = (markdownRef, documentUpdatingRef) => {
    console.log("Starting batch processing");

    let currentBatchIndex = 0;
    const batchSize = 4;
    let timeoutId = null;

    const runBatch = () => {
      if (!documentUpdatingRef.current) {
        console.log("documentUpdating is false, stopping batch processing");
        if (timeoutId) clearTimeout(timeoutId);
        return;
      }

      const markdownElement = markdownRef.current;
      if (!markdownElement || markdownElement.children.length === 0) {
        console.log("Markdown element or children lost, stopping");
        if (timeoutId) clearTimeout(timeoutId);
        return;
      }

      const childNodes = Array.from(markdownElement.children);
      const batches = Array.from(
        { length: Math.ceil(childNodes.length / batchSize) },
        (_, i) => childNodes.slice(i * batchSize, (i + 1) * batchSize)
      );

      if (currentBatchIndex >= batches.length) {
        console.log("All batches processed, looping back to start");
        currentBatchIndex = 0;
        if (markdownElement) {
          markdownElement.scrollTo({ top: 0, behavior: "smooth" });
        }
      }

      console.log("Processing batch:", currentBatchIndex);
      const batch = batches[currentBatchIndex];
      batch.forEach((node) => node.classList.add("flash"));

      const lastNode = batch[batch.length - 1];
      if (lastNode) {
        console.log("Attempting to scroll to last node:", lastNode);
        if (document.body.contains(lastNode)) {
          lastNode.scrollIntoView({ behavior: "smooth" });
        } else {
          console.log("Last node not in DOM anymore:", lastNode);
        }
      } else {
        console.log("No last node found for batch:", currentBatchIndex);
      }

      setTimeout(() => {
        console.log("Removing flash class from batch:", currentBatchIndex);
        batch.forEach((node) => {
          if (document.body.contains(node)) node.classList.remove("flash");
        });
      }, 1500);

      currentBatchIndex++;
      timeoutId = setTimeout(runBatch, 2500);
    };

    runBatch();
  };

  // useEffect(() => {
  //   documentUpdatingRef.current = documentUpdating; // Sync ref with state

  //   console.log("document updating here: ", documentUpdating);
  //   console.log("current  here: ", documentUpdating);
  //   if (documentUpdating === true && !hasRunRef.current) {
  //     console.log(
  //       "documentUpdating is true for the first time, starting process"
  //     );
  //     waitForChildren(markdownRef, () =>
  //       processMarkdownBatches(markdownRef, documentUpdatingRef)
  //     );
  //     hasRunRef.current = true;
  //   }
  // }, [documentUpdating]);

  const sampleFunction = () => {
    window.location.pathname = `/document/${id}/draft/${versionId + 1}`;
  };

  useEffect(() => {
    updateDocumentFromChat();
  }, [updatingElementInfo]);

  const [selection, setSelection] = useState(null); // Store the selected text
  const containerRef = useRef(null); // Reference to the Markdown container
  const hoverCardRef = useRef(null); // Reference to the hover card content for click detection

  const customRenderer = {
    p: ({ children }) => <p>{processTextWithSources(children)}</p>,
    h1: ({ children }) => <h1>{processTextWithSources(children)}</h1>,
    h2: ({ children }) => <h2>{processTextWithSources(children)}</h2>,
    h3: ({ children }) => <h3>{processTextWithSources(children)}</h3>,
    h4: ({ children }) => <h4>{processTextWithSources(children)}</h4>,
    h5: ({ children }) => <h5>{processTextWithSources(children)}</h5>,
    h6: ({ children }) => <h6>{processTextWithSources(children)}</h6>,
    ul: ({ children }) => <ul>{processTextWithSources(children)}</ul>, // Handle <ul>
    li: ({ children }) => <li>{processTextWithSources(children)}</li>, // Handle <li>
    a: ({ children, ...props }) => (
      <a {...props}>{processTextWithSources(children)}</a>
    ),
    blockquote: ({ children }) => (
      <blockquote>{processTextWithSources(children)}</blockquote>
    ),
    strong: ({ children }) => (
      <strong>{processTextWithSources(children)}</strong>
    ),
    em: ({ children }) => <em>{processTextWithSources(children)}</em>,
    code: ({ inline, children }) =>
      inline ? (
        <code>{processTextWithSources(children)}</code>
      ) : (
        <pre>
          <code>{processTextWithSources(children)}</code>
        </pre>
      ),
  };

  function DocumentViewerMarkdown({ documentElementsArray }) {
    // Custom renderer for Markdown with eachElement.id as the id of the element
    const renderDocument = () => (
      <Markdown
        remarkPlugins={[remarkGfm]}
        className="actualDocumentMarkdown"
        components={customRenderer}
      >
        {documentElementsArray
          ?.map((eachElement) => {
            const text = eachElement?.text ?? "";
            const textWithoutBrackets = text.replace(/\[.*?\]\s*/g, "");
            const id = eachElement?.id ?? "";

            if (eachElement.type === "TITLE") {
              return `# ${textWithoutBrackets} [ID: ${id}]`;
            } else if (eachElement.type === "SUBTITLE") {
              return `## ${textWithoutBrackets} [ID: ${id}]`;
            } else if (eachElement.type === "PARAGRAPH") {
              return `${
                textWithoutBrackets.startsWith("\n")
                  ? `${textWithoutBrackets}`
                  : `\n${textWithoutBrackets}`
              } [ID: ${id}]`;
            }
            return "";
          })
          .join("\n\n")}
      </Markdown>
    );

    useEffect(() => {
      // After renderDocument is done, process elements to extract id and set as class
      const elements = document.querySelectorAll(".actualDocumentMarkdown *");
      elements.forEach((element) => {
        const match = element.textContent.match(/\[id:(\d+)\]/);
        if (match) {
          const id = match[1];
          element.classList.add(`id-${id}`);
          element.textContent = element.textContent
            .replace(/\[id:\d+\]/, "")
            .trim();
        }
      });
    }, [documentElementsArray]);

    const handleKeydown = (e) => {
      if (
        (e.key === "ArrowRight" && (e.metaKey || e.ctrlKey)) ||
        (e.key === ">" && (e.metaKey || e.ctrlKey))
      ) {
        // Do something
        console.log("Do something");
      }
    };

    useEffect(() => {
      document.addEventListener("keydown", handleKeydown);
      return () => {
        document.removeEventListener("keydown", handleKeydown);
      };
    }, []);

    return (
      <div ref={containerRef} style={{ position: "relative" }}>
        <HighlightMenu
          target=".actualDocumentMarkdown"
          allowedPlacements={["top", "bottom"]}
          style={{ boxShadow: "none !important" }}
          menu={({ selectedText = "", setClipboard, setMenuOpen }) => (
            <div className="highlightMenuContent">
              <div
                className="eachMenuHighlight"
                onClick={() => {
                  dispatch(
                    updateUser({
                      chatInput: `> ${selectedText} \n\n`,
                    })
                  );
                  const finalDocumentChatInput = document.querySelector(
                    ".finalDocumentChatInput"
                  );
                  if (finalDocumentChatInput) {
                    finalDocumentChatInput.style.height = "200px";
                    finalDocumentChatInput.focus();
                    finalDocumentChatInput.scrollTop =
                      finalDocumentChatInput.scrollHeight;
                  }
                }}
              >
                <BiSolidMessageSquareAdd fontSize={18} />
                <p>Ask Pilot</p>
              </div>

              {/* <div className="highlightSelectionSection">
                <span>Selected</span>
                <p>
                  {selectedText.length > 200
                    ? `${selectedText.slice(0, 200)}...`
                    : selectedText}
                </p>
              </div> */}
              {/* <p>{selectedText}</p> */}
              {/* <MenuButton
                title="Copy to clipboard"
                icon="clipboard"
                onClick={() =>
                  setClipboard(selectedText, () => {
                    alert("Copied to clipboard");
                  })
                }
              />

              <MenuButton
                title="Search Google"
                onClick={() => {
                  window.open(
                    `https://www.google.com/search?q=${encodeURIComponent(
                      selectedText
                    )}`
                  );
                }}
                icon="magnifying-glass"
              />
              <MenuButton
                title="Close menu"
                onClick={() => setMenuOpen(false)}
                icon="x-mark"
              /> */}
            </div>
          )}
        />
        {renderDocument()}
      </div>
    );
  }

  return (
    <>
      {documentLoading && (
        <div className="documentViewerLoadingContainer">
          <DocumentViewerLoader text="Loading Document" size="large" />
        </div>
      )}

      {currentSelectedSignature !== null && (
        <div className="modal">
          <div
            className="modalOverlay"
            onClick={() => {
              closeSignature();
            }}
          ></div>

          {/* {JSON.stringify(signatures)} */}

          <div className="modalContent">
            <div className="modalContentHeader flexModalContentHeader">
              <div className="modalContentHeaderLeft">
                <TbSignature className="modalContentHeaderIcon" />
                <p className="modalContentHeaderText">
                  Details of the signature
                </p>
              </div>

              <IoClose
                className="modalContentHeaderCloseIcon"
                onClick={() => {
                  closeSignature();
                }}
              />
            </div>

            <div className="modalMainContent">
              <div className="modalInputs modalCenterContent">
                <div className="modalInputContainer">
                  <p className="modalInputLabel inputLabel">
                    Name of the Signer
                  </p>
                  <input
                    type="text"
                    className={`modalInput createDocumentInput ${
                      getSignatureDetailsValue(
                        currentSelectedSignature,
                        "finalised"
                      ) && "disabledInput"
                    }`}
                    placeholder="John"
                    autoFocus
                    disabled={getSignatureDetailsValue(
                      currentSelectedSignature,
                      "finalised"
                    )}
                    value={getSignatureDetailsValue(
                      currentSelectedSignature,
                      "name"
                    )}
                    onChange={(e) =>
                      addDetailsToSignature(
                        currentSelectedSignature,
                        "name",
                        e.target.value
                      )
                    }
                  />
                </div>

                <div className="modalInputContainer">
                  <p className="modalInputLabel inputLabel">
                    Email of the Signer
                  </p>
                  <input
                    type="text"
                    className={`modalInput createDocumentInput ${
                      getSignatureDetailsValue(
                        currentSelectedSignature,
                        "finalised"
                      ) && "disabledInput"
                    }`}
                    placeholder="james@gmail.com"
                    disabled={getSignatureDetailsValue(
                      currentSelectedSignature,
                      "finalised"
                    )}
                    value={getSignatureDetailsValue(
                      currentSelectedSignature,
                      "email"
                    )}
                    onChange={(e) =>
                      addDetailsToSignature(
                        currentSelectedSignature,
                        "email",
                        e.target.value
                      )
                    }
                  />
                </div>
              </div>
            </div>

            {!getSignatureDetailsValue(
              currentSelectedSignature,
              "finalised"
            ) && (
              <div
                className={`modalButtonContainer ${
                  addSignatureLoading && "disabledButton"
                }`}
                onClick={() => addSignature(currentSelectedSignature)}
                disabled={addSignatureLoading}
              >
                <div className="modalMainContentButton">
                  <p className="modalMainContentButtonText">Add Signature</p>

                  {addSignatureLoading && (
                    <Oval
                      height={15}
                      width={15}
                      color="white"
                      wrapperStyle={{}}
                      wrapperClass="modalMainContentButtonLoader"
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#ffffff7b"
                      strokeWidth={5}
                      strokeWidthSecondary={5}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {/* {JSON.stringify(documentInfo)} */}

      {/* {!showIncompleteDocModal &&
        documentInfo &&
        ((documentInfo?.resourceType === "FREE_RESOURCE" &&
          documentInfo?.payment === false) ||
          userData.plan === paymentPlans.FREE_PLAN) &&
        documentStatus !== "ACCESS_GRANTED_FOR_DOCUMENT" &&
        documentInfo?.status === "LIMITED_DOCUMENT_DRAFTED" && (
          <div className="finalDocumentUpgradeInfo">
            <button
              className={`finalDocumentUpgradeInfoButton ${
                purchaseLoading && "disabledButton"
              }`}
              onClick={() => setShowIncompleteDocModal(!showIncompleteDocModal)}
            >
              <MdOutlineRocketLaunch className="finalDocumentUpgradeInfoButtonIcon" />
              <p className="finalDocumentUpgradeInfoButtonText">
                Upgrade and Get the full Document
              </p>
            </button>
          </div>
        )} */}

      <div
        className="finalDocumentViewerContent"
        style={{ display: documentLoading ? "none" : "initial" }}
      >
        <RadixModal
          title="This document is Incomplete!"
          description="You are using the free version of this document. This only has drafted a part of the document."
          secondaryText="No, I will use the incomplete document"
          className="incompleteDocumentModal"
          openModal={showIncompleteDocModal}
          buttonText="Yes, I want the complete Document"
          setOpenModal={setShowIncompleteDocModal}
          icon={<IoWarning color="orange" />}
          loading={perDocumentLoading}
          onClick={() => buyDocument()}
          secondaryOnClick={() => setShowIncompleteDocModal(false)}
          // loading={deleteLoading}
        >
          <div className="incompleteDocModalContent">
            <div className="incompleteDocPricingBenefits">
              <h1 className="incompleteTitle">Upgrade to access</h1>

              <div className="incompleteDocPricingBenefitsList">
                <div>
                  <IoMdCheckmark className="benefitsIcon" />
                  <p>Customised to your requirements</p>
                </div>

                <div>
                  <IoMdCheckmark className="benefitsIcon" />
                  <p>Compliance with relevant laws</p>
                </div>

                <div>
                  <IoMdCheckmark className="benefitsIcon" />
                  <p>Unlimited Documents (Pro)</p>
                </div>

                <div>
                  <IoMdCheckmark className="benefitsIcon" />
                  <p>Unlimited Revisions</p>
                </div>

                <div>
                  <IoMdCheckmark className="benefitsIcon" />
                  <p>Professional Lawyer Review</p>
                </div>

                <div>
                  <IoMdCheckmark className="benefitsIcon" />
                  <p>Save lots of $$$</p>
                </div>
              </div>
            </div>

            <div className="incompleteDocTable">
              <div
                className="eachPricingTableSection"
                style={{
                  background: "#191f2f04",
                }}
              >
                <div
                  className="eachPricingTableHeader"
                  style={{ boxShadow: "none" }}
                >
                  <p
                    className="eachPricingTableType"
                    style={{ marginTop: "35px" }}
                  >
                    Free (current plan)
                  </p>
                  <h1 className="eachPricingTablePrice">$0</h1>
                </div>

                {/* <div className="eachPricingBenefits">
                  <p className="featureNotAvailable">
                    Customised to requirements
                  </p>
                  <p className="featureNotAvailable">Compliant with the laws</p>
                  <p className="featureNotAvailable">Unlimited Documents</p>
                  <p className="featureNotAvailable">Unlimited Revisions</p>
                  <p className="featureNotAvailable">
                    Minimal Lawyer Intervention
                  </p>
                  <p className="featureNotAvailable">
                    Professional Lawyer Review
                  </p>
                  <p>✅ Sample document</p>
                </div> */}
              </div>
              <div
                className="eachPricingTableSection"
                style={{
                  backgroundColor: "#4f37e815",
                  outline: "4px solid #4f37e810",
                  border: "1px solid #4f37e8",
                }}
              >
                <div
                  className="eachPricingTableHeader"
                  // style={{ background: "#191f2f12" }}
                >
                  <div className="eachPricingTableLabel">Most Popular 🎉</div>

                  <p className="eachPricingTableType">One-time</p>
                  <h1
                    className="eachPricingTablePrice"
                    // style={{ fontWeight: "700" }}
                  >
                    $30
                    <span style={{ fontSize: "24px", opacity: 0.5 }}>
                      /document
                    </span>
                  </h1>

                  {/* <p>✅ Customised to requirements </p>
                  <p>✅ Compliant with the laws </p>
                  <p className="featureNotAvailable">Unlimited Documents</p>
                  <p>✅ Unlimited Revisions</p>
                  <p>✅ Minimal Lawyer Intervention</p>
                  <p>✅ Professional Lawyer Review</p>
                  <p className="featureNotAvailable">Sample document</p> */}

                  <button
                    className="ButtonMain"
                    style={{
                      fontSize: "13px",
                      background: "#4f37e8",
                      width: "87%",
                    }}
                    disabled={perDocumentLoading}
                    onClick={() => buyDocument()}
                  >
                    {perDocumentLoading ? (
                      <Oval
                        height={15}
                        width={15}
                        color="white"
                        wrapperStyle={{ marginLeft: 5 }}
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#ffffff7b"
                        strokeWidth={5}
                        strokeWidthSecondary={5}
                      />
                    ) : (
                      <p> Get complete document</p>
                    )}
                  </button>
                </div>
              </div>
              <div className="eachPricingTableSection">
                <div className="eachPricingTableHeader">
                  <p
                    className="eachPricingTableType"
                    style={{ marginTop: "35px" }}
                  >
                    Pro
                  </p>
                  <h1 className="eachPricingTablePrice">
                    $50
                    <span style={{ fontSize: "24px", opacity: 0.5 }}>
                      /month
                    </span>
                  </h1>

                  {/* <p>✅ Customised to requirements</p>
                  <p>✅ Compliant with the laws</p>
                  <p>✅ Unlimited Documents</p>
                  <p>✅ Unlimited Revisions</p>
                  <p>✅ Minimal Lawyer Intervention</p>
                  <p>✅ Professional Lawyer Review</p>
                  <p className="featureNotAvailable">Sample document</p> */}

                  <button
                    disabled={purchaseLoading}
                    className="ButtonSecondary"
                    style={{ background: "#191f2f05", width: "87%" }}
                    onClick={() => generatePaymentLink()}
                  >
                    {purchaseLoading ? (
                      <Oval
                        height={15}
                        width={15}
                        color="white"
                        wrapperStyle={{ marginLeft: 5 }}
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#ffffff7b"
                        strokeWidth={5}
                        strokeWidthSecondary={5}
                      />
                    ) : (
                      "Upgrade to Pro"
                    )}
                  </button>
                </div>
              </div>
            </div>

            <p className="incompleteDocModalText">
              The document you have cannot be safely or professionaly used as
              you are on the free plan. Get the full document to get the
              complete, compliant version to confidently use it.{" "}
              <a target="_blank" href="https://useairstrip.com/pricing">
                Compare plans
              </a>
            </p>
          </div>
        </RadixModal>

        <div
          className="finalDocumentViewerContainer"
          id="finalDocumentViewerContainer"
        >
          {/* {JSON.stringify(documentInfo)} */}
          {/* {JSON.stringify(currentSelectedSignature)} */}

          <div id="document-builder">
            <animated.div
              style={documentViewerProps}
              className="finalDocumentViewer"
              id="finalDocumentViewer"
              // contentEditable="true"
              // onInput={() => {
              //   // alert("here");
              //   if (documentInfo?.document_drafts?.length !== 0) {
              //     dispatch(
              //       updateDocument({
              //         unsavedChanges: true,
              //       })
              //     );
              //   }
              // }}
            >
              {documentInfo && (
                <div className="versionsContainer">
                  <TbVersions
                    className="versionsIcon"
                    onClick={() => {
                      setShowVersions(true);
                    }}
                  />

                  {showVersions && (
                    <div className="versionsList">
                      <div className="versionsListHeader">
                        <p className="versionsListHeaderText">Versions</p>
                        <IoClose
                          className="closeVersionsListIcon"
                          onClick={() => setShowVersions(false)}
                        />
                      </div>

                      {documentInfo?.document_drafts.map(
                        (eachVersion, index) => (
                          <div
                            className={`eachVersionContainer ${
                              (index === parseInt(versionId) - 1 ||
                                (!versionId &&
                                  index ===
                                    documentInfo?.document_drafts?.length -
                                      1)) &&
                              "currentVersionContainer"
                            }`}
                            onClick={() => {
                              // showDocumentVersion(index);
                              window.location.pathname = `/document/${id}/draft/${
                                index + 1
                              }`;
                            }}
                            // to={`/document/${id}/draft/${index + 1}`}
                          >
                            <MdUpdate className="versionsIcon eachVersionIcon" />
                            <p className="eachVersionDate">
                              {/* {formatDateAndTime(eachVersion)} */}
                              Version {index + 1}
                            </p>
                          </div>
                        )
                      )}
                    </div>
                  )}
                </div>
              )}

              {/* {unsavedChanges && (
                <p
                  className="unsavedChangesTextContainer"
                  // contentEditable="false"
                >
                  <BsInfoCircleFill className="unsavedChangesIcon" />
                  <div className="unsavedChangesText">Unsaved Changes</div>
                </p>
              )} */}

              {/* {JSON.stringify(documentInfo)} */}

              <div
                className={`documentContent simplifyDocumentMarkdown ${
                  documentSoftLoading ? "documentSoftLoading" : ""
                }`}
                // contentEditable="true"
                // defaultValue=""
              >
                {/* only showing 4 elements if the user is not paid user */}
                {documentInfo &&
                userData &&
                userData.plan === "FREE_PLAN" &&
                documentInfo?.resourceType === "FREE_RESOURCE" &&
                documentInfo?.payment === false &&
                documentStatus !== "ACCESS_GRANTED_FOR_DOCUMENT" ? (
                  <DocumentViewerMarkdown
                    documentElementsArray={documentElementsArray.slice(0, 12)}
                  />
                ) : (
                  <DocumentViewerMarkdown
                    documentElementsArray={documentElementsArray}
                  />
                )}

                {userData.plan === "FREE_PLAN" &&
                  documentInfo?.resourceType === "FREE_RESOURCE" &&
                  documentInfo?.payment === false &&
                  documentStatus !== "ACCESS_GRANTED_FOR_DOCUMENT" && (
                    <div className="lockedSection">
                      <div className="lockIconContainer">
                        <BiLock className="lockIcon" />
                      </div>
                      <h2 className="lockedSectionTitle">
                        Upgrade to Access More Content
                      </h2>
                      <p className="lockedSectionDescription">
                        You are currently on the free plan which limits the
                        document to 3 elements. Upgrade to access the full
                        document and more features.
                      </p>
                      <button
                        className="upgradeButton"
                        onClick={() => setShowIncompleteDocModal(true)}
                      >
                        Upgrade Now
                      </button>
                    </div>
                  )}
              </div>
            </animated.div>

            {streamStatus === "PENDING" && (
              <div
                className="skeletonLoader"
                style={{ width: "700px", margin: "auto", padding: "0px 0px" }}
                id="simplifyDocumentLoader"
              >
                <div className="skeletonLoaderElement skeletonHeader"></div>
                <div className="skeletonParagraphGroup">
                  <div className="skeletonLoaderElement skeletonParagraph"></div>
                  <div className="skeletonLoaderElement skeletonParagraph"></div>
                  <div className="skeletonLoaderElement skeletonParagraph"></div>
                </div>
              </div>
            )}

            {pdfData && <PdfViewer pdfUrl={pdfData} />}

            {/* <div
              className="finalDocumentViewer"
              id="finalDocumentViewer"
              contentEditable="true"
            >
              

              <div
                className="documentContent"
                id="documentContent"
                style={{ opacity: showVersions ? 0.6 : 1 }}
              >
                {documentAsArray.map((eachElement) => {
                  if (eachElement.type === "TITLE") {
                    return (
                      <h1
                        className="finalDocumentViewerHeading"
                        id={eachElement.id}
                      >
                        {eachElement.text}
                      </h1>
                    );
                  } else if (eachElement.type === "SUBTITLE") {
                    return (
                      <p
                        className="finalDocumentViewerSubHeading"
                        id={eachElement.id}
                      >
                        {eachElement.text}
                      </p>
                    );
                  } else if (eachElement.type === "PARAGRAPH") {
                    return (
                      <p
                        className="finalDocumentViewerParagraph"
                        id={eachElement.id}
                      >
                        {eachElement.text}
                      </p>
                    );
                  }
                })}
              </div>
            </div> */}

            {/* <div
            className="finalDocumentViewer"
            id="finalDocumentViewer1"
            onDragOver={(event) => event.preventDefault()}
            onDrop={(event) => handleSignatureDrop(event)}
          ></div>

          <div
            className="finalDocumentViewer"
            id="finalDocumentViewer1"
            onDragOver={(event) => event.preventDefault()}
            onDrop={(event) => handleSignatureDrop(event)}
          ></div> */}
          </div>
        </div>
      </div>
    </>
  );
};
