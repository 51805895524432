import React from "react";

import * as TooltipComponent from "@radix-ui/react-tooltip";
import "../../radix-styles/tooltip.css";

export const Tooltip = ({ label, text, children }) => {
  return (
    <TooltipComponent.Provider>
      <TooltipComponent.Root>
        <TooltipComponent.Trigger style={{ zIndex: 999999999999999 }} asChild>
          {children}
        </TooltipComponent.Trigger>
        <TooltipComponent.Portal>
          <TooltipComponent.Content className="TooltipContent" sideOffset={5}>
            {text}
            <TooltipComponent.Arrow className="TooltipArrow" />
          </TooltipComponent.Content>
        </TooltipComponent.Portal>
      </TooltipComponent.Root>
    </TooltipComponent.Provider>
  );
};
