import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CrispChat from "../../crisp";
import { PricingPlans } from "../PricingPlans";
import { getUserAPI } from "../../api/apiCalls";
import { updateUser } from "../../redux/actions/userAction";
import { CreateDocumentModal } from "../CreateDocumentModal";
import { ErrorModal } from "../ErrorModal";

export const ProtectedRoute = ({ children }) => {
  const dispatch = useDispatch();
  const {
    token,
    isOnboardingCompleted,
    showUpgradeModal,
    showDocumentsModal,
    ...otherUserData
  } = useSelector((state) => state.user);

  // console.log('error modal ----> ', otherUserData)

  const getUser = async () => {
    const response = await getUserAPI();
    dispatch(updateUser({ plan: response.data.plan }));
  };

  useEffect(() => {
    getUser();

    // console.log(window.location.href);

    if (window.location.href.includes("pricing")) {
      dispatch(
        updateUser({
          showUpgradeModal: true,
        })
      );
    }

    return () => {
      getUser();
    };
  }, []);

  if (token) {
    return (
      <>
        {showUpgradeModal && <PricingPlans />}
        <CrispChat />
        {showDocumentsModal && <CreateDocumentModal />}
        {children}
        <ErrorModal />
      </>
    );
  } else {
    localStorage.setItem("redirectLink", JSON.stringify(window.location.href));
    window.location.href = "/login";
  }
};
