import * as React from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import {
  DotsVerticalIcon,
  DotFilledIcon,
  CheckIcon,
  ChevronRightIcon,
} from "@radix-ui/react-icons";
import "../radix-styles/dropdown.css";
import { BiHistory } from "react-icons/bi";

const DropdownMenuDemo = ({ items, size = "normal", length = null }) => {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <button
          className="IconButton"
          aria-label="Customise options"
          style={
            size === "small"
              ? {
                  fontSize: "14px",
                  width: "20px",
                  height: "20px",
                  background: "rgba(0,0,0,0.1)",
                  marginLeft: "5px",
                }
              : {}
          }
        >
          {size === "small" ? <BiHistory /> : <DotsVerticalIcon />}
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content className="DropdownMenuContent" sideOffset={5}>
          {length && (
            <p
              style={{
                fontSize: "12px",
                color: "gray",
                marginLeft: "15px",
                marginBottom: "5px",
                marginTop: "5px",
              }}
            >
              {length} chats
            </p>
          )}
          {items.map((item, index) => (
            <DropdownMenu.Item
              key={index}
              className={`DropdownMenuItem ${item.red ? "red" : ""} ${
                item.yello ? "yellow" : ""
              }`}
              onClick={() => {
                if (item.id) {
                  item.onClick(item.id);
                } else {
                  item.onClick();
                }
              }}
              // disabled={item.disabled}
            >
              {item.label}
              {item.icon ? (
                <div className="RightSlot">{item.icon}</div>
              ) : item.shortcut ? (
                <div className="RightSlot">{item.shortcut}</div>
              ) : null}
            </DropdownMenu.Item>
          ))}

          {/* <DropdownMenu.Separator className="DropdownMenuSeparator" /> */}

          <DropdownMenu.Arrow className="DropdownMenuArrow" />
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default DropdownMenuDemo;
