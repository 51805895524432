import React, { useEffect, useState } from "react";
import { Navbar } from "../components/Navbar";
import { HiDocumentText } from "react-icons/hi2";
import { MdOutlineArrowBack } from "react-icons/md";
import { UploadDocument } from "../components/Simplify/UploadDocument";
import { BsStars } from "react-icons/bs";
import { useSelector } from "react-redux";
import { Oval, Puff } from "react-loader-spinner";
import {
  convertTextToSlug,
  downloadFile,
  getSimplifiedCardsData,
  isValidFileType,
} from "../functions";
import { BiCheck, BiChevronDown, BiDownload, BiSave } from "react-icons/bi";
import { FaBoxArchive } from "react-icons/fa6";
import { getDocumentDraftAPI, getMyDraftsAPI } from "../api/apiCalls";
import { IoClose } from "react-icons/io5";
import { Link, redirect, useNavigate } from "react-router-dom";
import remarkGfm from "remark-gfm";
import Markdown from "react-markdown";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";

export const Simplify = () => {
  const [fileDropped, setFileDropped] = useState(null);

  const openFileUpload = () => {
    const input = document.getElementById("fileInput");
    input.click();
  };

  const { token } = useSelector((state) => state.user);

  const [documentUploading, setDocumentUploading] = useState(false);
  const [streamStatus, setStreamStatus] = useState("");
  const [elements, setElements] = useState([]);

  const navigate = useNavigate();

  let newElement = null;

  let localInfo = [];

  const addToElements = (object) => {
    //"local info: ", localInfo);
    localInfo.push(object);
    setElements((prev) => {
      return [...localInfo];
    });
  };

  const readStream = async (stream) => {
    const reader = stream.getReader();
    let alldata = "";
    if (newElement === null) newElement = ""; // Ensure newElement is initialized to avoid null errors

    while (true) {
      setStreamStatus("PENDING");
      const { done, value } = await reader.read();
      if (done) {
        if (newElement) {
          addToElements({
            type: "PARAGRAPH",
            text: newElement.replace("\n", ""),
          });
        }
        saveSimplifiedDocument();
        return;
      }

      const populatStreams = (object) => {
        console.log(object.data);
        alldata = alldata + object.data;

        if (newElement === null && object.data.includes("!")) {
          newElement = "";
          return;
        }

        if (newElement !== null && object.data.includes("!")) {
          addToElements({ type: "TITLE", text: newElement });
          newElement = null;
          return;
        }

        if (newElement === null && object.data.includes("#")) {
          newElement = "";
          return;
        }

        if (newElement !== null && object.data.includes("#")) {
          if (newElement.includes(".")) {
            let formatedText = "";

            let first5 = newElement.slice(0, 5);

            if (first5.includes("\n")) {
              first5 = first5.replace(/\n/g, "");
            }

            let remainingPart = newElement.slice(5);

            formatedText = first5 + remainingPart;

            addToElements({
              type: "PARAGRAPH",
              text: formatedText + "\n\n",
            });
            newElement = "";
            return;
          }

          addToElements({
            type: "SUBTITLE",
            text: newElement,
          });

          newElement = null;
          return;
        }

        if (newElement !== null) {
          newElement = newElement + object.data;
          return;
        }

        if (newElement === null) {
          newElement = object.data;
          return;
        }

        if (document) {
          // const element = document.getElementsByClassName("chatSectionChats");
          // element[0].scrollTo(0, element[0].scrollHeight);
        }
      };

      const textDecoder = new TextDecoder();
      try {
        const object = JSON.parse(textDecoder.decode(new Uint8Array(value)));

        if (object.data) {
          console.log("inside try -> ", object.data);
          populatStreams(object);
        }
      } catch {
        const input = `${textDecoder.decode(new Uint8Array(value))}`;

        input
          .trim()
          .split("\n")
          .map((str) => {
            try {
              const object = JSON.parse(str);

              console.log("inside catch -> ", object.data);

              if (object.data) {
                populatStreams(object);
              }
            } catch (error) {
              console.error("Error parsing JSON in catch block:", error);
            }
          });
      }
    }
  };

  const uploadFile = async (type = "document", content, documentInfo) => {
    const formData = new FormData();

    if (type === "document") {
      formData.append("document", content);
    } else {
      formData.append("id", content);
    }

    setDocumentUploading(true);

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/document/simplify-document`,
      {
        method: "POST",
        body: formData,
        "Content-Type": "multipart/form-data",
        headers: {
          api_key: "krishna",
          authorization: token,
          "Access-Control-Allow-Origin": "*",
        },
      }
    );

    if (response.ok) {
      setDocumentUploading(false);
      setFileDropped(
        type === "document"
          ? content
          : { name: documentInfo?.name, id: documentInfo?._id }
      );
      const stream = response.body;
      await readStream(stream);
    } else {
      setStreamStatus("NOT_STARTED");
      console.error(`Failed to fetch stream: ${response.statusText}`);
    }
  };

  // const [savingDocument, setSavingDocument] = useState(null);

  // useEffect(() => {
  //   saveSimplifiedDocument();
  // }, []);

  const saveSimplifiedDocument = async () => {
    // setSavingDocument(true);

    //elements);

    //"saving document");

    const titleElement = localInfo.filter(
      (eachElement) =>
        eachElement.type === "TITLE" && eachElement.text.length !== 0
    )[0];
    const title = titleElement ? titleElement.text : "Untitled";

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/document/save-simplified-document`,
      {
        method: "POST",
        body: JSON.stringify({
          title: title,
          content: localInfo,
        }),
        headers: {
          "Content-Type": "application/json",
          api_key: "krishna",
          Authorization: token,
        },
      }
    );

    //"saving", response.data);

    if (response.data) {
      navigate(`/simplify/${response.data._id}`);

      // setSavingDocument(false);
      setStreamStatus("COMPLETED");

      const rightContainer = document.querySelector(".rightContainer");
      rightContainer.scroll({
        top: 0,
        behavior: "smooth",
      });
    } else {
      setStreamStatus("COMPLETED");

      const rightContainer = document.querySelector(".rightContainer");
      rightContainer.scroll({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const handleFile = async (file) => {
    if (isValidFileType(file)) {
      if (file) {
        uploadFile("document", file);

        // const reader = new FileReader();

        // reader.onload = (event) => {
        //   const buffer = event.target.result;
        //   //buffer);

        //   // Now you have the file content as a buffer
        //   // You can use the 'buffer' variable for further processing
        // };

        // reader.readAsArrayBuffer(file);
      }
    } else {
      //"Invalid file type.");
    }
  };

  useEffect(() => {
    if (!fileDropped) {
      getAllSimplifiedDocuments();

      const dropArea = document.getElementById(
        "simplifyDocumentsUploadContainer"
      );
      const fileInput = document.getElementById("fileInput");

      const handleDragOver = (e) => {
        e.preventDefault();
        dropArea.classList.add("activeUploadContainer");
      };

      const handleDragLeave = () => {
        dropArea.classList.remove("activeUploadContainer");
      };

      const handleDrop = (e) => {
        e.preventDefault();
        dropArea.classList.remove("activeUploadContainer");

        const files = e.dataTransfer.files;

        if (isValidFileType(files[0]) && files.length === 1) {
          handleFile(files[0]);
        }
      };

      const handleChange = (e) => {
        const files = e.target.files;

        if (isValidFileType(files[0]) && files.length === 1) {
          handleFile(files[0]);
        }
      };

      // Add event listeners
      dropArea.addEventListener("dragover", handleDragOver);
      dropArea.addEventListener("dragleave", handleDragLeave);
      dropArea.addEventListener("drop", handleDrop);
      fileInput.addEventListener("change", handleChange);

      // Clean up event listeners on unmount
      return () => {
        dropArea.removeEventListener("dragover", handleDragOver);
        dropArea.removeEventListener("dragleave", handleDragLeave);
        dropArea.removeEventListener("drop", handleDrop);
        fileInput.removeEventListener("change", handleChange);
      };
    }
  }, [fileDropped]);

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const loaderElement = document.getElementById("simplifyDocumentLoader");
    if (loaderElement) {
      loaderElement.scrollIntoView({ behavior: "smooth" });
    }
  }, [elements]);

  const [showSimplifiedDocuments, setShowSimplifiedDocuments] = useState(true);

  //

  const [allDocuments, setAllDocuments] = useState([]);
  const [documentsLoading, setDocumentsLoading] = useState(false);

  const getAllDocumentsAPI = async () => {
    setDocumentsLoading(true);
    const response = await getMyDraftsAPI();

    if (response.data) {
      setDocumentsLoading(false);

      setAllDocuments(response.data);
    }
  };

  const getDraft = async (id) => {
    setDocumentUploading(true);

    const response = await getDocumentDraftAPI(id);

    if (response.data) {
      // const htmlBody = atob(
      //   decodeURIComponent(
      //     response.data.document_drafts[
      //       response.data.document_drafts.length - 1
      //     ]
      //   )
      // );

      // // Create a temporary container element
      // var tempContainer = document.createElement("div");
      // tempContainer.innerHTML = htmlBody;
      // tempContainer.style.display = "none";
      // var documentText = tempContainer.textContent;
      // tempContainer.remove();

      uploadFile("id", id, response.data);
    }
  };

  const [simplifiedDocuments, setSimplifiedDocuments] = useState(null);
  const [downloading, setDownloading] = useState(false);

  const getAllSimplifiedDocuments = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/document/get-all-simplified-document`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          api_key: "krishna",
          authorization: token,
        },
      }
    ).then(async (response) => {
      const savedDocuments = await response.json();

      setSimplifiedDocuments(savedDocuments);
    });
  };

  useEffect(() => {
    getAllDocumentsAPI();
    getAllSimplifiedDocuments();
  }, []);

  const downloadDocument = async () => {
    setDownloading(true);
    const element = document.querySelector(".simplifyDocumentMarkdown");
    const clonedElement = element.cloneNode(true);

    const downloadButton = clonedElement.querySelector(
      ".downloadDocumentButton"
    );
    if (downloadButton) {
      downloadButton.remove();
    }

    // Extract the first h1, h2, or any heading tag content to use as the file name
    const firstHeading = clonedElement.querySelector("h1, h2, h3, h4, h5, h6");
    const fileName = firstHeading
      ? firstHeading.textContent.trim()
      : "document";

    // Split content into sections based on <hr> tags
    const sections = clonedElement.innerHTML.split("<hr>");
    let paginatedHtml = "";

    sections.forEach((section, index) => {
      const trimmedSection = section.trim();

      // Remove <button> tags and div with downloadDocumentButton class from the section
      const sectionWithoutButtonsOrDownloadDiv = trimmedSection
        .replace(/<button[^>]*>.*?<\/button>/g, "")
        .replace(
          /<div[^>]*class=["']downloadDocumentButton["'][^>]*>.*?<\/div>/g,
          ""
        );

      // Check if the previous section has enough content to justify a page break
      if (index > 0) {
        const previousSection = sections[index - 1].trim();

        const previousContentLength = previousSection.replace(
          /<[^>]*>/g,
          ""
        ).length; // Remove HTML tags to measure text length

        if (previousContentLength > 600) {
          paginatedHtml += `<div class="page" style="page-break-after: always;">${sectionWithoutButtonsOrDownloadDiv}</div>`;
        } else {
          paginatedHtml += sectionWithoutButtonsOrDownloadDiv;
        }
      } else {
        // First section, no page break
        paginatedHtml += `<div>${sectionWithoutButtonsOrDownloadDiv}</div>`;
      }
    });

    const options = {
      method: "POST",
      url: "https://api.pdfendpoint.com/v1/convert",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer pdfe_live_80719f1de7c28412e5d1968f8de9faab8461",
      },
      data: JSON.stringify({
        html: paginatedHtml,
        css: `
        * {
          font-family: 'Helvetica Neue', Arial, sans-serif;
          color: #333;
        }
        body {
          margin: 0;
          padding: 0;
        }
        * {
          font-family: "Inter", sans-serif;
          line-height: 1.5;
          color: #333;
          font-size: 12px; /* Adjusted font size for better fit */
          margin: 5px; /* Reduced spacing for better fit */
        }

        h1 {
          margin-top: 20px;
          margin-bottom: 15px;
          font-size: 1.5em; /* Adjusted font size for headings */
          font-weight: bold;
          line-height: 1.2;
        }

        h2 {
          margin-top: 15px;
          margin-bottom: 10px;
          font-size: 1.25em; /* Adjusted font size for subheadings */
          font-weight: bold;
          line-height: 1.3;
        }

        p {
          margin-bottom: 8px;
          font-size: 12px; /* Adjusted font size for paragraphs */
          line-height: 1.5em;
          color: #555;
        }

        ul, ol {
          margin-left: 15px;
          padding-left: 10px;
          font-size: 12px; /* Adjusted font size for lists */
        }

        li {
          margin-bottom: 5px;
        }

        a {
          color: #1a73e8;
          text-decoration: underline;
          font-size: 12px; /* Adjusted font size for links */
        }

        a:hover {
          color: #1146ce;
          text-decoration: none;
        }

        blockquote {
          margin: 15px 0;
          padding: 8px 15px;
          background-color: #e9ecef;
          border-left: 4px solid #1146ce;
          font-style: italic;
          color: #555;
          font-size: 12px; /* Adjusted font size for blockquotes */
        }

        code {
          font-family: "Fira Code", monospace;
          background-color: #f4f4f4;
          padding: 2px 4px;
          border-radius: 4px;
          font-size: 0.9em;
        }

        pre {
          font-family: "Fira Code", monospace;
          background-color: #f4f4f4;
          padding: 8px;
          border-radius: 6px;
          overflow-x: auto;
          font-size: 0.9em;
          margin: 8px 0;
        }

        hr {
          margin: 20px 0;
          opacity: 0.6;
          height: 0.5px;
          border: none;
          background-color: grey;
          display: none;
        }

        .page {
          page-break-after: always;
        }
      `,
        sandbox: false,
        orientation: "vertical",
        page_size: "A4",
        margin_top: "1cm",
        margin_bottom: "1cm",
        margin_left: "1cm",
        margin_right: "1cm",
        disable_html_validation: true,
      }),
    };

    axios
      .request(options)
      .then(function (response) {
        downloadFile(response.data.data.url, `Simplified-${fileName}.pdf`);
        setTimeout(() => {
          setDownloading(false);
        }, 4000);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  return (
    <div className="simplifyContainer">
      <Navbar />

      <div className="rightContainer">
        <div className="rightContainerHeader">
          <h1 className="rightContainerHeaderText">Simplify your Document</h1>
          <p className="rightContainerHeaderDescription">
            View your document in a easy-to understand and a visual format and
            identify important points.
          </p>
        </div>

        <div className="simplifyDocumentsContainer">
          {!fileDropped && (
            <div className="simplifiedDocumentsListContainer">
              <div
                className="simplifiedDocumentsListHeader"
                onClick={() =>
                  setShowSimplifiedDocuments(!showSimplifiedDocuments)
                }
              >
                {/* <FaBoxArchive className="simplifiedDocumentsListHeaderMainIcon" /> */}
                <p className="simplifiedDocumentsListHeaderText">
                  Previously Simplified Documents{" "}
                  {simplifiedDocuments?.length > 0 &&
                    `(${simplifiedDocuments?.length})`}
                </p>
                <BiChevronDown
                  className={`simplifiedDocumentsListHeaderIcon ${
                    showSimplifiedDocuments &&
                    "simplifiedDocumentsListHeaderIconOpen"
                  }`}
                />
              </div>

              {showSimplifiedDocuments && simplifiedDocuments && (
                <div className="simplifiedDocumentsList">
                  {simplifiedDocuments && simplifiedDocuments?.length === 0 ? (
                    <p className="emptyListText">
                      No documents simplified yet.
                    </p>
                  ) : (
                    <>
                      {simplifiedDocuments.map((eachSimplifiedDocument) => (
                        <Link
                          to={`/simplify/${eachSimplifiedDocument._id}`}
                          className="simplifyResultEachCard simplifiedEachDocumentCard"
                        >
                          <HiDocumentText className="resultCardIcon" />

                          <div className="simplifyResultCardContent">
                            <p className="resultCardText">
                              {eachSimplifiedDocument.title.substring(0, 40)}...
                            </p>
                          </div>
                        </Link>
                      ))}
                    </>
                  )}
                </div>
              )}
            </div>
          )}

          {fileDropped ? (
            <div className="simplifyDocumentsResultsContainer">
              <div
                className="simplifyDocumentsGoBack"
                onClick={() => {
                  setFileDropped(null);
                  setElements([]);
                }}
              >
                <MdOutlineArrowBack className="simplifyDocumentsGoBackIcon" />
                <p className="simplifyDocumentsGoBackText">Go Back</p>
              </div>

              <div className="simplifyDocumentsResults">
                <div className="simplifyDocumentResultsMainContent">
                  {/* {streamStatus === "PENDING" && ( */}
                  <div className="simplifiedDocumentRightSection">
                    {streamStatus === "PENDING" ? (
                      <div className="streamStatusContainer">
                        <Puff
                          height="15"
                          width="15"
                          radius={1}
                          color="#1253f3"
                          secondaryColor="#1253f340"
                          ariaLabel="puff-loading"
                          wrapperStyle={{}}
                          wrapperClass="streamStatusLoading"
                          visible={true}
                        />
                        <p className="streamStatusContainerText">
                          Simplifying Document... Do not close your browser, the
                          result will be lost
                        </p>
                      </div>
                    ) : (
                      <div className="streamStatusContainer streamStatusSuccessContainer">
                        <BiCheck color="green" />
                        <p className="streamStatusContainerText">
                          Document is simplified and saved for later.
                        </p>
                      </div>
                    )}

                    {/* <Toast.Provider swipeDirection="right">
                      <div
                        className="simplifiedDocumentSaveButton"
                        onClick={() => saveSimplifiedDocument()}
                      >
                        <p className="simplifiedDocumentSaveButtonText">
                          Save for Later
                        </p>

                        {savingDocument && (
                          <Oval
                            height={15}
                            width={15}
                            color="white"
                            wrapperStyle={{ marginLeft: 5 }}
                            visible={true}
                            ariaLabel="oval-loading"
                            secondaryColor="#ffffff7b"
                            strokeWidth={5}
                            strokeWidthSecondary={5}
                          />
                        )}
                      </div>

                      <Toast.Root
                        className="ToastRoot"
                        open={open}
                        onOpenChange={setOpen}
                      >
                        <div className="toastContainer">
                          <div className="toastIcon">
                            <BiCheck className="documentSavedIcon" />
                          </div>

                          <div className="toastContent">
                            <Toast.Title className="ToastTitle">
                              Document Saved
                            </Toast.Title>
                            <Toast.Description asChild>
                              <p className="ToastDescription">
                                <b>{fileDropped?.name}</b> was saved
                                successfully. You can find it again in the
                                Simplify Screen.
                              </p>
                            </Toast.Description>
                            <Toast.Action
                              className="ToastAction"
                              asChild
                              altText="Goto schedule to undo"
                            >
                              <button
                                onClick={() => setOpen(false)}
                                className="Button small green"
                              >
                                <IoClose />
                              </button>
                            </Toast.Action>
                          </div>
                        </div>
                      </Toast.Root>
                      <Toast.Viewport className="ToastViewport" />
                    </Toast.Provider> */}
                  </div>
                  {/* )} */}

                  <div className="simplifyDocumentsHeader">
                    {fileDropped.name && (
                      <div
                        className="simplifyDocumentsName"
                        onClick={() => {
                          if (fileDropped.id) {
                            window.open(
                              `${window.location.origin}/document/${fileDropped.id}/draft`
                            );
                          }
                        }}
                      >
                        <HiDocumentText className="simplifyDocumentsHeaderNameIcon" />
                        <p className="simplifyDocumentsHeaderNameText">
                          {fileDropped.name}
                        </p>
                      </div>
                    )}
                  </div>

                  {/* <div className="simplifyResultsCards">
                    <div className="simplifyResultEachCard">
                      <BsStars className="resultCardIcon" />

                      <div className="simplifyResultCardContent">
                        <p className="resultCardLabel">Monthly Salary</p>
                        <p className="resultCardText">$400/month</p>
                      </div>
                    </div>

                    <div className="simplifyResultEachCard">
                      <BsStars className="resultCardIcon" />

                      <div className="simplifyResultCardContent">
                        <p className="resultCardLabel">Monthly Salary</p>
                        <p className="resultCardText">$400/month</p>
                      </div>
                    </div>

                    <div className="simplifyResultEachCard">
                      <BsStars className="resultCardIcon" />
                      <div className="simplifyResultCardContent">
                        <p className="resultCardLabel">Probation Period</p>
                        <p className="resultCardText">3 months</p>
                      </div>
                    </div>

                    <div className="simplifyResultEachCard">
                      <BsStars className="resultCardIcon" />
                      <div className="simplifyResultCardContent">
                        <p className="resultCardLabel">Expiration Date</p>
                        <p className="resultCardText">12th Feb 2024</p>
                      </div>
                    </div>
                  </div> */}

                  {/* {JSON.stringify(elements)} */}
                  <div className="simplifyDocumentMarkdown">
                    <div
                      onClick={downloadDocument}
                      className={`downloadDocumentButton ${
                        downloading && "disabledButton"
                      }`}
                    >
                      {downloading ? (
                        <ClipLoader
                          color={"white"}
                          loading={true}
                          size={13}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                          speedMultiplier={2}
                        />
                      ) : (
                        <BiDownload />
                      )}
                      <span>{downloading ? "Downloading..." : "Download"}</span>
                    </div>
                    <Markdown remarkPlugins={[remarkGfm]}>
                      {elements
                        ?.map((eachElement) => {
                          if (eachElement.type === "TITLE") {
                            return `# ${eachElement.text}`;
                          } else if (eachElement.type === "SUBTITLE") {
                            return `## ${eachElement.text}`;
                          } else if (eachElement.type === "PARAGRAPH") {
                            return eachElement.text;
                          }
                          return "";
                        })
                        .join("\n\n")}
                    </Markdown>
                  </div>

                  {streamStatus === "PENDING" && (
                    <div className="skeletonLoader" id="simplifyDocumentLoader">
                      <div className="skeletonLoaderElement skeletonHeader"></div>
                      <div className="skeletonParagraphGroup">
                        <div className="skeletonLoaderElement skeletonParagraph"></div>
                        <div className="skeletonLoaderElement skeletonParagraph"></div>
                        <div className="skeletonLoaderElement skeletonParagraph"></div>
                      </div>
                    </div>
                  )}

                  {/* <div className="simplifyDocumentsResultHeader">
                    <h1 className="simplifyDocumentsHeaderText">
                      Simplified Rajsuthan's Employee Agreement
                    </h1>
                    <p className="simplifyDocumentsDescription">
                      Agreement oulining the responsibilites, payment, and other
                      information to hire Rajsuthan as a contractor.
                    </p>
                  </div>


                  <div className="simplifyResultsContent">
                    <div className="simplifyResultInfoContainer">
                      

                     
                    </div>

                    <div className="simplifyResultInfoContainer">
                      <p className="simplifyResultInfoHeader">
                        Consultant's Services: What Rajsuthan Will Do
                      </p>

                      <p className="simplifyResultInfoDescription">
                        Rajsuthan will provide professional consulting services
                        to Sootchy, Inc. These services include web design and
                        development using Javascript, mobile app design and
                        development using React Native, creating web templates
                        using Photoshop designs and sketches, collaborating with
                        designers and product managers, and other related coding
                        tasks as necessary.{" "}
                      </p>
                    </div>

                    <div className="simplifyResultInfoContainer">
                      <p className="simplifyResultInfoHeader">
                        Payment: How Much Rajsuthan Will Earn
                      </p>

                      <p className="simplifyResultInfoDescription">
                        For his services, Rajsuthan will earn $700 per month,
                        paid bi-weekly ($323.08 per pay period). After three
                        months of successful performance, his monthly pay will
                        increase to $800. However, unless Sootchy, Inc. gives
                        written approval, Rajsuthan won't be reimbursed for any
                        expenses he incurs while performing his services.
                      </p>
                    </div>

                    <div className="simplifyResultInfoContainer">
                      <p className="simplifyResultInfoHeader">
                        Working Relationship: How Rajsuthan and Sootchy, Inc.
                        Will Work Together{" "}
                      </p>

                      <p className="simplifyResultInfoDescription">
                        Rajsuthan is not an employee of Sootchy, Inc. He's an
                        independent contractor, which means he's his own boss.
                        He's also free to provide services to other companies or
                        individuals. However, he can't claim to be an employee,
                        partner, or shareholder of Sootchy, Inc.
                      </p>
                    </div>

                    <div className="simplifyResultInfoContainer">
                      <p className="simplifyResultInfoHeader">
                        Confidentiality: Keeping Company Secrets
                      </p>

                      <p className="simplifyResultInfoDescription">
                        Rajsuthan agrees not to share any confidential and
                        proprietary information or trade secrets of Sootchy,
                        Inc. without the company's prior written consent. He
                        also agrees not to use this information for anything
                        other than consulting with the company. This agreement
                        lasts for three years after the end of the agreement.
                      </p>
                    </div>

                    <div className="simplifyResultInfoContainer">
                      <p className="simplifyResultInfoHeader">
                        In a Nutshell: Quick Takeaways
                      </p>

                      <p className="simplifyResultInfoPoints">
                        <li>
                          Rajsuthan is providing professional consulting
                          services to Sootchy, Inc.
                        </li>
                        <li>
                          He'll earn $700 per month, increasing to $800 per
                          month after three months of successful performance.
                        </li>
                        <li>
                          He's an independent contractor, not an employee of
                          Sootchy, Inc.
                        </li>
                        <li>
                          He agrees to keep Sootchy, Inc.'s confidential
                          information secret for three years after the agreement
                          ends.
                        </li>
                        <li>
                          The agreement lasts until Rajsuthan completes his
                          services, unless either party decides to end it
                          earlier.
                        </li>
                      </p>
                    </div>
                  </div> */}
                </div>

                <div className="simplifyDocumentsContents">
                  <p className="simplifyDocumentsContentsHeader">CONTENTS</p>

                  <div className="simplifyDocumentsContentsList">
                    {elements
                      .filter(
                        (eachElement) =>
                          eachElement.type === "SUBTITLE" &&
                          !eachElement.text.includes("<>")
                      )
                      .map((eachContent) => (
                        <p
                          onClick={() => {
                            scrollToSection(
                              convertTextToSlug(eachContent.text)
                            );
                          }}
                          className="simplifyDocumentsEachContent"
                        >
                          {eachContent.text}
                        </p>
                      ))}

                    {/* <p className="simplifyDocumentsEachContent">
                      Consultant's Services: What Rajsuthan Will Do
                    </p>
                    <p className="simplifyDocumentsEachContent">
                      Payment: How Much Rajsuthan Will Earn
                    </p>
                    <p className="simplifyDocumentsEachContent">
                      Working Relationship: How Rajsuthan and Sootchy, Inc. Will
                      Work Together
                    </p>
                    <p className="simplifyDocumentsEachContent">
                      Confidentiality: Keeping Company Secrets
                    </p>
                    <p className="simplifyDocumentsEachContent">
                      In a Nutshell: Quick Takeaways
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <UploadDocument
              openFileUpload={openFileUpload}
              documentUploading={documentUploading}
              allDocuments={allDocuments}
              documentsLoading={documentsLoading}
              getDraft={getDraft}
            />
          )}
        </div>
      </div>
    </div>
  );
};
