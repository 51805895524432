import React, { useState } from "react";
import RadixModal from "./Modal/RadixModal";
import { useSelector } from "react-redux";

export const UploadDocuments = ({
  showUploadModal,
  setShowUploadModal,
  onSuccess,
}) => {
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState([]);

  const { token } = useSelector((state) => state.user);

  const removeFile = (index) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setUploadProgress((prevProgress) =>
      prevProgress.filter((_, i) => i !== index)
    );
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);

    // Ensure files are properly stored
    setUploadedFiles((prevFiles) => [...prevFiles, ...files]);
    setUploadProgress((prevProgress) => [
      ...prevProgress,
      ...files.map(() => "ready"), // Initialize progress as "ready"
    ]);
  };

  const uploadDocument = async () => {
    if (uploadedFiles.length === 0) return;
    setUploadLoading(true);

    const progressArray = uploadedFiles.map(() => "ready");
    setUploadProgress(progressArray);

    console.log("this is the uploaded files:", uploadedFiles);

    try {
      for (let i = 0; i < uploadedFiles.length; i++) {
        const file = uploadedFiles[i]; // Correctly access each file in the array

        // Update progress to "uploading..."
        setUploadProgress((prevProgress) => {
          const updatedProgress = [...prevProgress];
          updatedProgress[i] = "uploading...";
          return updatedProgress;
        });

        const formData = new FormData();
        formData.append("document", file);

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/draft/upload-own-document`,
          {
            method: "POST",
            body: formData,
            "Content-Type": "multipart/form-data",
            headers: {
              api_key: "krishna",
              authorization: token,
              "Access-Control-Allow-Origin": "*",
            },
          }
        );

        console.log("uploading -> ", file);
        console.log(response);

        // Mark the file as uploaded in the progress array
        setUploadProgress((prevProgress) => {
          const updatedProgress = [...prevProgress];
          updatedProgress[i] = "uploaded";
          return updatedProgress;
        });
      }

      setUploadLoading(false);
      setShowUploadModal(false);
      setUploadedFiles([]);
      onSuccess();
    } catch (error) {
      console.error("Error uploading files:", error);
      setUploadLoading(false);
    }
  };

  return (
    <RadixModal
      title="Upload Documents"
      description={"Select one or more documents to upload."}
      buttonText={"Upload Documents"}
      openModal={showUploadModal}
      setOpenModal={setShowUploadModal}
      className="uploadDocumentModal"
      onClick={() => {
        uploadDocument();
      }}
      loading={uploadLoading}
    >
      <div className="uploadDocumentModalContainer">
        <input
          type="file"
          multiple
          onChange={handleFileChange}
          className="uploadDocumentInput"
        />
        <div className="uploadedFilesList">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              maxHeight: "200px",
              overflowY: "scroll",
              marginTop: "20px",
            }}
          >
            {uploadedFiles.map((file, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "10px",
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  backgroundColor: "#f9f9f9",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#333",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "70%",
                  }}
                >
                  {file.name}
                </span>
                <span
                  style={{
                    fontSize: "12px",
                    color:
                      uploadProgress[index] === "uploaded"
                        ? "green"
                        : uploadProgress[index] === "uploading..."
                        ? "blue"
                        : "orange",
                    fontWeight: "bold",
                  }}
                >
                  {uploadProgress[index]}
                </span>
                <button
                  onClick={() => removeFile(index)}
                  style={{
                    backgroundColor: "#ff4d4f",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    padding: "5px 10px",
                    cursor: "pointer",
                    fontSize: "12px",
                    transition: "background-color 0.3s ease",
                    fontWeight: "400",
                  }}
                  onMouseOver={(e) =>
                    (e.target.style.backgroundColor = "#d9363e")
                  }
                  onMouseOut={(e) =>
                    (e.target.style.backgroundColor = "#ff4d4f")
                  }
                >
                  ✖
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </RadixModal>
  );
};
