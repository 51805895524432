import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { animated, easings, useSpring } from "@react-spring/web";
import { BsArrowRightShort, BsStars } from "react-icons/bs";
import { CgClose, CgFileDocument } from "react-icons/cg";
import {
  addStartupOnboardingDetailsAPI,
  getLifetimeAccessPlan,
} from "../../api/apiCalls";
import { updateUser } from "../../redux/actions/userAction";
import { updateDocument } from "../../redux/actions/documentActions";
import { Oval } from "react-loader-spinner";
import OnboardingModal from "./OnboardingModal";
import SuccessModal from "./SuccessModal";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import SuccessStep from "./Steps/SuccessStep";
import "./Onboarding.css";

const Onboarding = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    token,
    isOnboardingCompleted,
    showAppSumoModal,
    customDocumentMessage,
  } = useSelector((state) => state.user);
  const { allDocuments } = useSelector((state) => state.document);

  const [onboardingModalProps, setOnboardingModalProps] = useSpring(() => ({
    opacity: 1,
  }));

  const [onboardingStepNumber, setOnboardingStepNumber] = useState(1);
  const [onboardingSuccess, setOnboardingSuccess] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [onboardingLoading, setOnboardingLoading] = useState(false);
  const [showAPIKeyModal, setShowAPIKeyModal] = useState(false);

  // User inputs
  const [selectedUserType, setSelectedUserType] = useState("individual");
  const [onboardingInputs, setOnboardingInputs] = useState({
    company: "",
    description: "",
  });

  // Location data
  const [loading, setLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState({
    name: "United States",
    iso3: "USA",
    iso2: "US",
  });
  const [selectedState, setSelectedState] = useState({
    name: "California",
    state_code: "CA",
  });
  const [countries, setCountries] = useState([]);

  // Document selection
  const [docsInfo, setDocsInfo] = useState([]);
  const [filteredAllDocuments, setFilteredAllDocuments] =
    useState(allDocuments);
  const [documentFilterTag, setDocumentFilterTag] = useState(" ");
  const [documentSearchInput, setDocumentSearchInput] = useState("");
  const [selectedDocument, setSelectedDocument] = useState(null);

  // AppSumo modal
  const [superUserModalProps, setSuperUserModalProps] = useSpring(() => ({
    opacity: 0,
  }));

  // Get countries data
  const getCountries = () => {
    setLoading(true);
    const supportedCountries = [
      "USA",
      "GBR",
      "CAN",
      "AUS",
      "DEU",
      "CHE",
      "ESP",
    ];

    fetch("https://countriesnow.space/api/v0.1/countries/states").then(
      (response) => {
        response.json().then((responseData) => {
          const filteredCountries = responseData.data.filter(
            (eachCountryInfo) => {
              if (supportedCountries.includes(eachCountryInfo.iso3)) {
                return {
                  ...eachCountryInfo,
                };
              }
              return false;
            }
          );

          setCountries(filteredCountries);
          setLoading(false);
        });
      }
    );
  };

  const getCountryInfo = (iso3) => {
    if (!iso3) return null;
    return countries.filter((country) => country.iso3 === iso3)[0] || null;
  };

  const getStateInfo = (stateCode) => {
    if (!stateCode || !selectedCountry) return null;

    let stateInfo;
    const countryInfo = getCountryInfo(selectedCountry.iso3);

    if (countryInfo && countryInfo.states) {
      countryInfo.states.filter((state) => {
        if (state.state_code === stateCode) {
          stateInfo = state;
        }
        return false;
      });
    }

    return stateInfo || null;
  };

  // Add onboarding details to API
  const addOnboardingDetailsAPI = async () => {
    console.log("Starting onboarding API call...");
    setOnboardingLoading(true);

    try {
      // Ensure we have valid country data before proceeding
      if (!selectedCountry || !selectedCountry.name) {
        throw new Error("Country information is missing");
      }

      const countryString =
        selectedState && selectedState.name
          ? `${selectedState.name}, ${selectedCountry.name}`
          : selectedCountry.name;

      const requestData = {
        name:
          selectedUserType === "individual"
            ? "Individual"
            : onboardingInputs.company,
        aboutCompany:
          selectedUserType === "individual"
            ? "Individual"
            : onboardingInputs.description,
        country: countryString,
      };

      console.log("Sending data to API:", requestData);

      const response = await addStartupOnboardingDetailsAPI(requestData);

      console.log("API response received:", response);

      if (response.data) {
        console.log("API call successful, updating user data");

        // Update Redux store with user data
        dispatch(
          updateUser({
            companyName: onboardingInputs.company,
            country: countryString,
          })
        );

        const redirectLink = JSON.parse(localStorage.getItem("redirectLink"));
        if (redirectLink) {
          if (redirectLink.includes("pricing")) {
            console.log("Redirecting to pricing page");
            window.location.replace(redirectLink);
            localStorage.removeItem("redirectLink");

            dispatch(
              updateUser({
                isOnboardingCompleted: true,
                showUpgradeModal: false,
              })
            );
          }
        }

        // Stop loading state
        setOnboardingLoading(false);
        console.log("Loading state turned off");

        // Hide the onboarding modal first with animation
        console.log("Hiding onboarding modal");
        setOnboardingModalProps({
          from: { opacity: 1 },
          to: { opacity: 0 },
          config: { duration: 300 },
        });

        // First show the success modal, then set onboarding completed
        console.log("Scheduling success modal to appear in 2 seconds");
        setTimeout(() => {
          console.log("Showing success modal now");
          setOnboardingSuccess(true);
          setShowSuccessModal(true);

          // Don't set isOnboardingCompleted yet - we'll do that when the success modal is closed
          // This ensures the success modal is visible
        }, 2000);

        // if a user wanted to create a custom document from the landing page
        if (customDocumentMessage) {
          console.log("Setting custom document message");
          // adding the user's landing page text to this document modal's text
          dispatch(
            updateDocument({
              initialText: customDocumentMessage,
              initiateTask: true,
            })
          );
        }

        // this is showing the appsumo modal
        setShowAPIKeyModal(false);
        setSuperUserModalProps({
          from: { opacity: 0 },
          to: { opacity: 1 },
          config: { easing: easings.easeInElastic(20) },
        });
      }
    } catch (error) {
      console.error("Error in onboarding API call:", error);
      setOnboardingLoading(false);
      // Show error message to user
      alert("There was an error completing your onboarding. Please try again.");
    }
  };

  // Create document and navigate
  const createDocument = () => {
    setOnboardingModalProps({
      from: {
        opacity: 1,
      },
      to: {
        opacity: 0,
      },
      config: {
        easing: easings.easeInElastic(20),
      },
    });

    setTimeout(() => {
      dispatch(
        updateUser({
          isOnboardingCompleted: true,
        })
      );

      // Make sure selectedDocument exists before navigating
      if (selectedDocument && selectedDocument._id) {
        navigate(`/document/${selectedDocument._id}`);
      } else {
        console.error("No document selected");
        // Navigate to a safe fallback route
        navigate("/");
      }
    }, 500);
  };

  // Filter documents based on search input
  useEffect(() => {
    let newFilteredAllDocuments = allDocuments.filter((eachDocument) =>
      eachDocument.title
        .toLowerCase()
        .includes(documentSearchInput.toLowerCase())
    );
    setFilteredAllDocuments(newFilteredAllDocuments);
  }, [documentSearchInput, allDocuments]);

  // Filter documents based on tag
  useEffect(() => {
    setDocumentSearchInput("");
    let newFilteredAllDocuments = allDocuments.filter((eachDocument) =>
      eachDocument.tag.includes(documentFilterTag)
    );
    setFilteredAllDocuments(newFilteredAllDocuments);
  }, [documentFilterTag, allDocuments]);

  // Filter documents based on selection
  useEffect(() => {
    if (selectedDocument) {
      let newFilteredAllDocuments = allDocuments.filter(
        (eachDocument) => eachDocument === selectedDocument
      );

      setFilteredAllDocuments(newFilteredAllDocuments);
    } else {
      setSelectedDocument(null);
      setFilteredAllDocuments(allDocuments);
    }
  }, [selectedDocument, allDocuments]);

  // AppSumo modal effects
  useEffect(() => {
    if (showAppSumoModal === true) {
      setSuperUserModalProps({
        from: { opacity: 0 },
        to: { opacity: 1 },
        config: { easing: easings.easeInElastic(20) },
      });
      setShowAPIKeyModal(true);
    }
  }, [showAppSumoModal]);

  useEffect(() => {
    // Only update Redux if modal is being shown (not when hiding after success)
    if (showAPIKeyModal && showAppSumoModal) {
      dispatch(updateUser({ showAppSumoModal: showAPIKeyModal }));
    }
  }, [showAPIKeyModal, showAppSumoModal, dispatch]);

  // Get countries on component mount
  useEffect(() => {
    getCountries();
  }, []);

  // Render the appropriate step based on current step number
  const renderStep = () => {
    if (onboardingSuccess) {
      return (
        <SuccessStep
          filteredAllDocuments={filteredAllDocuments}
          documentSearchInput={documentSearchInput}
          setDocumentSearchInput={setDocumentSearchInput}
          documentFilterTag={documentFilterTag}
          setDocumentFilterTag={setDocumentFilterTag}
          docsInfo={docsInfo}
          selectedDocument={selectedDocument}
          setSelectedDocument={setSelectedDocument}
          createDocument={createDocument}
        />
      );
    }

    switch (onboardingStepNumber) {
      case 1:
        return (
          <Step1
            selectedUserType={selectedUserType}
            setSelectedUserType={setSelectedUserType}
            onboardingInputs={onboardingInputs}
            setOnboardingInputs={setOnboardingInputs}
            onboardingLoading={onboardingLoading}
            addOnboardingDetailsAPI={addOnboardingDetailsAPI}
            setOnboardingStepNumber={setOnboardingStepNumber}
          />
        );
      case 2:
        return (
          <Step2
            onboardingInputs={onboardingInputs}
            setOnboardingInputs={setOnboardingInputs}
            setOnboardingStepNumber={setOnboardingStepNumber}
          />
        );
      case 3:
        return (
          <Step3
            loading={loading}
            countries={countries}
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
            selectedState={selectedState}
            setSelectedState={setSelectedState}
            getCountryInfo={getCountryInfo}
            getStateInfo={getStateInfo}
            onboardingLoading={onboardingLoading}
            addOnboardingDetailsAPI={addOnboardingDetailsAPI}
            setOnboardingStepNumber={setOnboardingStepNumber}
          />
        );
      default:
        return null;
    }
  };

  // Render the SuperUserModal component
  const SuperUserModal = () => {
    const [superUserCode, setSuperUserCode] = useState("");
    const [showInvalidCodeMessage, setShowInvalidCodeMessage] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showCongratsModal, setShowCongratsModal] = useState(false);

    const handleSubmit = async () => {
      setIsSubmitting(true);
      try {
        const response = await getLifetimeAccessPlan({
          code: superUserCode,
          apiKey: "",
        });
        if (response.data.status === true) {
          setShowCongratsModal(true);
          // Only update Redux store after showing congrats modal
          setTimeout(() => {
            window.location.reload();
            dispatch(
              updateUser({
                isSuperUser: true,
                apiKey: "",
                showAppSumoModal: false, // This will prevent the modal from showing again
              })
            );
          }, 3000); // Give user time to see congrats message
        } else {
          // Display an error message or handle the case where the code is invalid
          console.error("Invalid super user code");
          // Show a message in the modal that the code is not valid
          if (response.data.message === "Code has already been used") {
            setShowInvalidCodeMessage(
              "The super user code you entered has already been used."
            );
          } else if (response.data.message === "Invalid API key") {
            setShowInvalidCodeMessage(
              "The API key you entered is invalid. Please check and try again."
            );
          } else {
            setShowInvalidCodeMessage(
              "The super user code you entered is not valid."
            );
          }
        }
      } catch (error) {
        console.error("Error checking super user status:", error);
        // Show a message in the modal that the code is not valid
        setShowInvalidCodeMessage(
          "An error occurred while checking the super user code. Please try again later."
        );
      } finally {
        setIsSubmitting(false);
      }
    };

    return (
      <div className="homeContainer">
        {showCongratsModal && (
          <div
            className="appOnboardingModal"
            style={{ zIndex: 99999999999, textAlign: "center" }}
          >
            <animated.div
              style={{
                opacity: 1,
                animation: `fadeIn 0.5s ease-in-out`,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
              className={`appOnboardingModalContent ${
                onboardingSuccess && "appOnboardingModalContentSuccess"
              }`}
            >
              <div className="appOnboardingContent">
                <div className="appOnboardingHeader">
                  <img
                    src="/blueTransparent.png"
                    className="appOnboardingLogo"
                    style={{ marginRight: "10px" }}
                    alt="Airstrip Logo"
                  />
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1lydyXIW9bWcls3tJchwFptuCkB7pnBQgpw&s"
                    className="appOnboardingLogo"
                    alt="AppSumo Logo"
                  />
                  <h1 className="appOnboardingHeaderText">Congratulations!</h1>
                  <p className="appOnboardingHeaderDescription">
                    You now have a lifetime deal to AirstripAI. Thank you for
                    being an AirstripAI Pro Customer!
                  </p>
                </div>
              </div>
            </animated.div>

            <div
              className="appOnboardingModalOverlay"
              onClick={() => {
                setSuperUserModalProps({ to: { opacity: 0 } });
                setShowAPIKeyModal(false);
                window.location.reload();
              }}
              // style={{ zIndex: 99999 }}
            ></div>
          </div>
        )}

        {!showCongratsModal && (
          <div className="appOnboardingModal" style={{ zIndex: 99999999999 }}>
            <animated.div
              style={{ ...superUserModalProps }}
              className={`appOnboardingModalContent ${
                onboardingSuccess && "appOnboardingModalContentSuccess"
              }`}
            >
              <div className="appOnboardingContent">
                <div className="appOnboardingHeader">
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1lydyXIW9bWcls3tJchwFptuCkB7pnBQgpw&s"
                    className="appOnboardingLogo"
                    alt="AppSumo Logo"
                  />
                  <h1 className="appOnboardingHeaderText">
                    Are you from AppSumo?
                  </h1>
                  <p className="appOnboardingHeaderDescription">
                    If you have a super user code from our{" "}
                    <a href="#" target="_blank" rel="noopener noreferrer">
                      AppSumo lifetime deal (get it for $59)
                    </a>
                    , please enter it here to unlock your lifetime access.
                  </p>
                </div>

                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                  className="appOnboardingMain"
                >
                  {showInvalidCodeMessage && (
                    <div
                      className="appOnboardingInputContainer"
                      style={{
                        marginBottom: "20px",
                        border: "1px solid #ff0000",
                        padding: "10px",
                        backgroundColor: "#ffeeee",
                      }}
                    >
                      <div style={{ color: "#ff0000" }}>
                        {showInvalidCodeMessage}
                      </div>
                    </div>
                  )}
                  <div className="appOnboardingInputContainer">
                    <label className="label" htmlFor="superUserCode">
                      Unique Code
                    </label>
                    <input
                      className="input"
                      autoFocus
                      placeholder="Enter your super user code"
                      id="superUserCode"
                      value={superUserCode}
                      onChange={(e) => {
                        setSuperUserCode(e.target.value);
                        setShowInvalidCodeMessage(false);
                      }}
                      required
                    />
                  </div>
                  <div className="appOnboardingMainButtons">
                    <button
                      type="submit"
                      className="onboardingNextButton"
                      disabled={isSubmitting}
                    >
                      <p className="onboardingButtonText">
                        {isSubmitting ? "Submitting..." : "Get Lifetime Access"}
                      </p>
                      <BsArrowRightShort size={18} />
                    </button>
                    <div
                      className="appOnboardingBackButton"
                      onClick={() => {
                        setShowAPIKeyModal(false);
                        dispatch(updateUser({ showAppSumoModal: false }));
                      }}
                      style={{
                        backgroundColor: "#191f2f !important",
                        color: "#191f2f80",
                        marginLeft: "10px",
                      }}
                    >
                      No, I am not from AppSumo
                    </div>
                  </div>
                </form>
              </div>
            </animated.div>

            <div
              className="appOnboardingModalOverlay"
              onClick={() => {
                setSuperUserModalProps({ to: { opacity: 0 } });
                setShowAPIKeyModal(false);
                dispatch(updateUser({ showAppSumoModal: false }));
              }}
              // style={{ zIndex: 99999 }}
            ></div>
          </div>
        )}
      </div>
    );
  };

  // Effect to log state changes for debugging
  useEffect(() => {
    console.log("State changed - showSuccessModal:", showSuccessModal);
    console.log(
      "State changed - isOnboardingCompleted:",
      isOnboardingCompleted
    );
    console.log("State changed - onboardingSuccess:", onboardingSuccess);
  }, [showSuccessModal, isOnboardingCompleted, onboardingSuccess]);

  return (
    <div className="homeContainer">
      {showAPIKeyModal && <SuperUserModal />}

      {/* Success Modal - shown with highest priority and highest z-index */}
      {showSuccessModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 999999,
          }}
        >
          <SuccessModal
            onClose={() => {
              console.log("Success modal closed by user");
              setShowSuccessModal(false);
              // Make sure isOnboardingCompleted is set to true when closing the success modal
              dispatch(
                updateUser({
                  isOnboardingCompleted: true,
                })
              );
            }}
            onCreateDocument={() => {
              console.log("Create document button clicked in success modal");
              dispatch(
                updateUser({
                  showDocumentsModal: true,
                  isOnboardingCompleted: true,
                })
              );
            }}
          />
        </div>
      )}

      {/* Onboarding Modal - only shown if success modal not showing */}
      {!showSuccessModal && !isOnboardingCompleted && (
        <OnboardingModal
          onboardingModalProps={onboardingModalProps}
          onboardingSuccess={onboardingSuccess}
        >
          {renderStep()}
        </OnboardingModal>
      )}
    </div>
  );
};

export default Onboarding;
