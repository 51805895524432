import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./styles.css";
import { App } from "./pages/App.1";
import { CreateDocument } from "./pages/CreateDocument";
import { DocumentDetail } from "./pages/DocumentDetail";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ProtectedRoute } from "./components/ProtectedRoute/ProtectedRoute";

import { BrowseDocuments } from "./pages/BrowseDocuments";
import { ChatPage } from "./pages/ChatPage";
import { CreateSignLink } from "./pages/CreateSignLink";
import { CustomisePilot } from "./pages/CustomisePilot";
import { FinalDocument } from "./pages/FinalDocument";
import { ForgotPassword } from "./pages/ForgotPassword";
import { Login } from "./pages/Login";
import { MyDocuments } from "./pages/MyDocuments";
import { Settings } from "./pages/Settings";
import { Signup } from "./pages/Signup";
import { SimplifiedDocument } from "./pages/SimplifiedDocument";
import { Simplify } from "./pages/Simplify";
import SharedDraftView from "./pages/SharedDraftView";
import store from "./redux/store";
import { RedirectSuccess } from "./pages/RedirectSuccess";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <App />
      </ProtectedRoute>
    ),
  },
  {
    path: "/pricing",
    element: (
      <ProtectedRoute>
        <App />
      </ProtectedRoute>
    ),
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "/redirect_success",
    element: <RedirectSuccess />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  // {
  //   path: "/welcome",
  //   element: (
  //     <ProtectedRoute>
  //       <SignupFlowOnboarding />
  //     </ProtectedRoute>
  //   ),
  // },
  // TODO:
  {
    path: "/chat",
    element: (
      <ProtectedRoute>
        <ChatPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/chat/customise",
    element: (
      <ProtectedRoute>
        <CustomisePilot />
      </ProtectedRoute>
    ),
  },
  {
    path: "/chat/:chatId",
    element: (
      <ProtectedRoute>
        <ChatPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/settings",
    element: (
      <ProtectedRoute>
        <Settings />
      </ProtectedRoute>
    ),
  },
  {
    path: "/browse-documents",
    element: (
      <ProtectedRoute>
        <BrowseDocuments />
      </ProtectedRoute>
    ),
  },
  {
    path: "/simplify",
    element: (
      <ProtectedRoute>
        <Simplify />
      </ProtectedRoute>
    ),
  },
  {
    path: "/simplify/:id",
    element: <SimplifiedDocument />,
  },
  {
    path: "/my-documents",
    element: (
      <ProtectedRoute>
        <MyDocuments />
      </ProtectedRoute>
    ),
  },
  {
    path: "/document/:id",
    element: (
      <ProtectedRoute>
        <DocumentDetail />
      </ProtectedRoute>
    ),
  },
  {
    path: "/document/:id/create",
    element: (
      <ProtectedRoute>
        <CreateDocument />
      </ProtectedRoute>
    ),
  },
  {
    path: "/document/:id/create/details",
    element: (
      <ProtectedRoute>
        <CreateDocument />
      </ProtectedRoute>
    ),
  },
  {
    path: "/document/:id/create/review",
    element: (
      <ProtectedRoute>
        <CreateDocument />
      </ProtectedRoute>
    ),
  },
  {
    path: "/document/:id/create/confirm",
    element: (
      <ProtectedRoute>
        <CreateDocument />
      </ProtectedRoute>
    ),
  },
  {
    path: "/document/:id/create/chat",
    element: (
      <ProtectedRoute>
        <CreateDocument />
      </ProtectedRoute>
    ),
  },
  {
    path: "/document/:id/draft",
    element: (
      <ProtectedRoute>
        <FinalDocument />
      </ProtectedRoute>
    ),
  },
  {
    path: "/document/:id/draft/:versionId",
    element: (
      <ProtectedRoute>
        <FinalDocument />
      </ProtectedRoute>
    ),
  },
  {
    path: "/sign/:signId",
    element: <CreateSignLink />,
  },
  {
    path: "/shared-draft/:token",
    element: <SharedDraftView />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store().store}>
    <PersistGate loading={null} persistor={store().persistor}>
      <RouterProvider router={router} />
    </PersistGate>
  </Provider>
);
