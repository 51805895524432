import React from "react";
import { BsStars } from "react-icons/bs";
import { CgClose, CgFileDocument } from "react-icons/cg";
import { Crisp } from "crisp-sdk-web";
import "../Onboarding.css";

const SuccessStep = ({
  filteredAllDocuments,
  documentSearchInput,
  setDocumentSearchInput,
  documentFilterTag,
  setDocumentFilterTag,
  docsInfo,
  selectedDocument,
  setSelectedDocument,
  createDocument,
}) => {
  return (
    <div className="appOnboardingSuccess">
      <div className="appOnboardingTopContent">
        <p className="appOnbaordingTopEmoji">🎉</p>

        <div className="appOnboardingTopInfo">
          <p className="appOnboardingTopText">You're now all setup!</p>
          <p className="appOnboardingTopDescription">
            Select and start creating the document with the best in-class AI
          </p>
        </div>
      </div>

      <div className="appOnboardingSuccessHeaderContent">
        <h1 className="appOnboardingSuccessHeader">
          Choose the Document you need
        </h1>

        <p className="appOnboardingSuccessDescription">
          Browse through and select the document that you need to create.
        </p>
      </div>

      <div className="appOnboardingSuccessContent">
        {!selectedDocument && (
          <>
            <fieldset className="Fieldset appOnboardingInputContainer">
              <input
                className="input"
                autoFocus
                placeholder="ex: Founders agreement"
                id="documentSearch"
                value={documentSearchInput}
                onChange={(e) => setDocumentSearchInput(e.target.value)}
              />
            </fieldset>

            <div className="appOnboardingTags">
              <p
                className={`appOnboardingTag ${
                  documentFilterTag === " " && "selectedAppOnboardingTag"
                }`}
                onClick={() => setDocumentFilterTag(" ")}
              >
                All ({filteredAllDocuments.length})
              </p>
              {docsInfo.map((eachDocInfo) => (
                <p
                  key={eachDocInfo.type}
                  className={`appOnboardingTag ${
                    eachDocInfo.type === documentFilterTag &&
                    "selectedAppOnboardingTag"
                  }`}
                  onClick={() => setDocumentFilterTag(eachDocInfo.type)}
                >
                  {eachDocInfo.type} ({eachDocInfo.documents.length})
                </p>
              ))}
            </div>
          </>
        )}

        <div
          className={`appOnboardingDocuments ${
            selectedDocument && "documentSelected"
          }`}
        >
          {selectedDocument && (
            <p className="appOnboardingInfoLabel">
              Let's create you this document:
            </p>
          )}

          {filteredAllDocuments.map((eachDocument) => (
            <div
              key={eachDocument._id}
              className={`appOnboardingEachDocument ${
                selectedDocument === eachDocument &&
                "selectedAppOnboardingEachDocument"
              }`}
              onClick={() => {
                // when document is selected this is replacing the state
                if (!selectedDocument) {
                  setSelectedDocument(eachDocument);
                }
              }}
            >
              <CgFileDocument className="appOnboardingEachDocumentIcon" />
              <p className="appOnboardingEachDocumentText">
                {eachDocument.title}
              </p>

              {selectedDocument === eachDocument && (
                <div
                  className="documentCheckIconContainer"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedDocument(null);
                  }}
                >
                  <CgClose className="appOnboardingEachDocumentIcon documentCheckIcon" />
                </div>
              )}
            </div>
          ))}

          {filteredAllDocuments.length === 0 && (
            <p className="noDocumentsFound">
              No document found.{" "}
              <span onClick={() => Crisp.chat.open()}>
                Please Send us a quick message
              </span>{" "}
            </p>
          )}
        </div>

        <div
          className={`documentButton ${
            !selectedDocument && "disabledDocumentButton"
          }`}
          onClick={() => selectedDocument && createDocument()}
        >
          <BsStars className="documentButtonIcon" />
          <p className="documentButtonText">Create Document</p>
        </div>
      </div>
    </div>
  );
};

export default SuccessStep;
