import React, { useEffect } from "react";
import { BsStars } from "react-icons/bs";
import { animated, useSpring } from "@react-spring/web";
import { useDispatch } from "react-redux";
import { updateUser } from "../../redux/actions/userAction";
import "./Onboarding.css";

const SuccessModal = ({ onClose, onCreateDocument }) => {
  const dispatch = useDispatch();

  // Log when the success modal is mounted
  useEffect(() => {
    console.log("SuccessModal mounted and visible");

    // Play a success sound if available
    try {
      const audio = new Audio("/successSound.mp3");
      audio.play().catch((e) => console.log("Could not play success sound", e));
    } catch (error) {
      console.log("Error playing success sound", error);
    }

    return () => {
      console.log("SuccessModal unmounted");
    };
  }, []);

  const modalProps = useSpring({
    from: { opacity: 0, transform: "scale(0.8)" },
    to: { opacity: 1, transform: "scale(1)" },
    config: { tension: 200, friction: 20 },
  });

  const overlayProps = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  const handleCreateDocument = () => {
    console.log("Create document button clicked in SuccessModal");
    dispatch(
      updateUser({
        showDocumentsModal: true,
        isOnboardingCompleted: true,
      })
    );
    console.log("Dispatched showDocumentsModal: true");
    onCreateDocument();
    onClose();
  };

  const handleClose = () => {
    console.log("Success modal overlay clicked - closing modal");
    onClose();
  };

  return (
    <div className="successModal">
      <animated.div
        style={overlayProps}
        className="successModalOverlay"
        onClick={handleClose}
      />
      <animated.div style={modalProps} className="successModalContent">
        <div className="successModalIcon">
          <BsStars />
        </div>
        <h1 className="successModalTitle">You're all set!</h1>
        <p className="successModalDescription">
          Your account has been successfully set up. You're now ready to create
          your first legal document with Airstrip AI.
        </p>
        <button className="successModalButton" onClick={handleCreateDocument}>
          Create Your First Document
        </button>
      </animated.div>
    </div>
  );
};

export default SuccessModal;
