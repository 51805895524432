import React, { useEffect, useState } from "react";
import { BiSolidDownArrow } from "react-icons/bi";
import { HiDocument } from "react-icons/hi2";
import { MdOutlineDriveFolderUpload } from "react-icons/md";
import { formatDate, isValidFileType } from "../../functions";
import { useSelector } from "react-redux";
import { BsArrowRight } from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import { Oval } from "react-loader-spinner";

export const ContextSettings = () => {
  const [expandDescription, setExpandDescription] = useState(false);
  const [filesDropped, setFilesDropped] = useState(null);
  const [documentUploading, setDocumentUploading] = useState(false);

  const { token } = useSelector((state) => state.user);

  const openFileUpload = () => {
    const input = document.getElementById("fileInput");
    input.click();
  };

  const uploadFile = async (type = "document", content, documentInfo) => {
    const formData = new FormData();

    filesDropped.map((eachFile) => {
      formData.append("document", eachFile);
    });

    //("content", content);

    setDocumentUploading(true);

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/user/save-knowledge-base`,
      {
        method: "POST",
        body: formData,
        "Content-Type": "multipart/form-data",
        headers: {
          api_key: "krishna",
          authorization: token,
        },
      }
    );

    if (response.ok) {
      setDocumentUploading(false);
      setFilesDropped(null);
      //   setFileDropped(
      //     type === "document"
      //       ? content
      //       : { name: documentInfo?.name, id: documentInfo?._id }
      //   );

      getFilesInContext();
    } else {
      //   setStreamStatus("NOT_STARTED");
      console.error(`Failed to fetch stream: ${response.statusText}`);
    }
  };

  const handleFile = async (file) => {
    if (isValidFileType(file)) {
      if (file) {
        // uploadFile("document", file);
        // const reader = new FileReader();
        // reader.onload = (event) => {
        //   const buffer = event.target.result;
        //   //buffer);
        //   // Now you have the file content as a buffer
        //   // You can use the 'buffer' variable for further processing
        // };
        // reader.readAsArrayBuffer(file);
      }
    } else {
      //"Invalid file type.");
    }
  };

  useEffect(() => {
    if (!filesDropped) {
      const dropArea = document.getElementById("contextSettingUploadContent");
      const fileInput = document.getElementById("fileInput");

      const handleDragOver = (e) => {
        e.preventDefault();
        dropArea.classList.add("activeUploadContainer");
      };

      const handleDragLeave = () => {
        dropArea.classList.remove("activeUploadContainer");
      };

      const handleDrop = (e) => {
        e.preventDefault();
        dropArea.classList.remove("activeUploadContainer");
      };

      const handleChange = (e) => {
        const files = Object.values(e.target.files);
        setFilesDropped(files);
        files.forEach((eachFile) => {
          if (isValidFileType(eachFile)) {
            handleFile(eachFile);
          }
        });
      };

      dropArea.addEventListener("dragover", handleDragOver);
      dropArea.addEventListener("dragleave", handleDragLeave);
      dropArea.addEventListener("drop", handleDrop);
      fileInput.addEventListener("change", handleChange);

      return () => {
        dropArea.removeEventListener("dragover", handleDragOver);
        dropArea.removeEventListener("dragleave", handleDragLeave);
        dropArea.removeEventListener("drop", handleDrop);
        fileInput.removeEventListener("change", handleChange);
      };
    }
  }, [filesDropped]);

  const filterFiles = (file) => {
    const newFilesArray = filesDropped.filter(
      (droppedFile) => droppedFile !== file
    );
    setFilesDropped(newFilesArray);
  };

  const [filesInContext, setFilesInContext] = useState([]);
  const [filesInContextLoading, setFilesInContextLoading] = useState([]);

  const getFilesInContext = async () => {
    setFilesInContextLoading(true);

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/user/get-knowledge-base`,
      {
        method: "get",
        headers: {
          Authorization: token,
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          api_key: "krishna",
        },
      }
    );

    if (response.ok) {
      const filesFromContextData = await response.json();
      setFilesInContext(filesFromContextData);

      //("files from context: ", filesFromContextData);

      setFilesInContextLoading(false);
    }
  };

  useEffect(() => {
    getFilesInContext();
  }, []);

  return (
    <div className="contextSetting">
      <div className="contextSettingHeaderContent">
        <p
          className="contextSettingDescriptionHeader"
          onClick={() => setExpandDescription(!expandDescription)}
        >
          <BiSolidDownArrow
            className={`contextSettingDescriptionHeaderIcon ${
              expandDescription && "rotateIcon"
            }`}
          />
          <p className="contextSettingDescriptionHeaderText">
            What should you do here?
          </p>
        </p>

        {expandDescription && (
          <p className="contextSettingDescription">
            To get any assistance for your business, it needs to have initial
            knowledge (context) about your business. For Pilot,{" "}
            <b>this can be provided through documents.</b> <br /> These
            documents can include business plans, descriptions of the services
            or features the business provides, already created legal documents,
            your pricing, decisions you made. This can be knowledge that you
            want to share with a professional to make sure that they understand
            the business fully to start giving you advice.
          </p>
        )}
      </div>

      <div className="contextSettingUploadContainer">
        <p className="contextSettingUploadHeader">Upload Documents</p>

        <p className="contextSettingUploadDescription">
          These can be PDFs or Documents to explain to Pilot more about your
          business
        </p>

        <div
          id="contextSettingUploadContent"
          className={`contextSettingUploadContent contextSettingUploadContent`}
          onClick={() => openFileUpload()}
        >
          <MdOutlineDriveFolderUpload className="contextSettingUploadContentIcon" />
          <p className="contextSettingUploadContentText">
            Click here to upload documents
          </p>
        </div>

        {filesDropped && filesDropped.length !== 0 && (
          <div className="droppedFilesContainer">
            <div className="droppedFiles">
              {filesDropped?.map((fileDropped) => (
                <div className="eachDroppedFile">
                  <p>{fileDropped.name.substring(0, 20)}...</p>
                  <IoClose
                    className="removeEachDroppedFile"
                    onClick={() => filterFiles(fileDropped)}
                  />
                </div>
              ))}
            </div>

            <BsArrowRight className="uploadDroppedFileIcon" />

            <div
              className={`uploadFilesButton
              ${documentUploading && "disabledButton"}
              `}
              onClick={() => uploadFile(null, filesDropped)}
            >
              <p className="uploadFilesButtonText">Upload all Files</p>

              {documentUploading && (
                <Oval
                  height={10}
                  width={10}
                  color="#ffffff"
                  wrapperStyle={{}}
                  wrapperClass="modalMainContentButtonLoader"
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#ffffff50"
                  strokeWidth={5}
                  strokeWidthSecondary={5}
                />
              )}
            </div>
          </div>
        )}
      </div>

      <input
        type="file"
        multiple
        id="fileInput"
        style={{ display: "none" }}
        accept=".doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf, application/pdf"
      />

      <div className="contextSettingUploadedDocumentsContainer">
        <p className="contextSettingUploadHeader">Documents added to Context</p>
        <p className="contextSettingUploadDescription">
          These documents are already provided as context to Pilot
        </p>

        <div className="contextSettingDocuments">
          {filesInContextLoading ? (
            <div className="contextLoading">
              <Oval
                height={20}
                width={20}
                color="#1253f3"
                wrapperStyle={{}}
                wrapperClass="modalMainContentButtonLoader"
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#1253f340"
                strokeWidth={5}
                strokeWidthSecondary={5}
              />
            </div>
          ) : filesInContext.length === 0 ? (
            <div className="emptyFilesContext">
              <h1 className="emptyFilesContextHeader">
                No files added to context
              </h1>
              <p className="emptyFilesContextDescription">
                Add files to context for them to appear here.
              </p>
            </div>
          ) : (
            filesInContext.map((eachFileInContext) => (
              <div className="contextSettingUploadedDocument">
                <HiDocument className="contextSettingDocumentIcon" />
                <div className="contextSettingUploadDocumentInfo">
                  <p className="contextSettingUploadDocumentTitle">
                    {eachFileInContext.name}
                  </p>
                  <p className="contextSettingUploadDocumentDate">
                    {formatDate(eachFileInContext.createdAt)}
                  </p>
                </div>
              </div>
            ))
          )}

          {/* <div className="contextSettingUploadedDocument">
            <HiDocument className="contextSettingDocumentIcon" />
            <div className="contextSettingUploadDocumentInfo">
              <p className="contextSettingUploadDocumentTitle">Features.pdf</p>
              <p className="contextSettingUploadDocumentDate">25/09/2023</p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};
