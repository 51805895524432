import React, { useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import { BsInfoLg } from "react-icons/bs";
import { Tooltip } from "../Tooltip";
import "./StartupLocationInput.css";

const StartupLocationInput = ({
  location,
  onChange,
  error = null,
  setError = () => {},
}) => {
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [supportedCountries] = useState([
    "USA",
    "GBR",
    "CAN",
    "AUS",
    "DEU",
    "FRA",
    "SGP",
    "IND",
  ]);

  // Fetch countries on component mount
  useEffect(() => {
    fetchCountries();
  }, []);

  const fetchCountries = async () => {
    setLoading(true);

    try {
      const response = await fetch(
        "https://countriesnow.space/api/v0.1/countries/states"
      );
      const data = await response.json();

      if (data.data) {
        // Filter to only supported countries
        const filteredCountries = data.data.filter((country) =>
          supportedCountries.includes(country.iso3)
        );

        setCountries(filteredCountries);

        // If no location is set, default to first country
        if (!location.country.iso3 && filteredCountries.length > 0) {
          const firstCountry = filteredCountries[0];
          const firstState =
            firstCountry.states.length > 0
              ? {
                  name: firstCountry.states[0].name,
                  state_code: firstCountry.states[0].state_code,
                }
              : { name: "", state_code: "" };

          onChange({
            country: {
              name: firstCountry.name,
              iso3: firstCountry.iso3,
              iso2: firstCountry.iso2,
            },
            state: firstState,
          });
        }
      }
    } catch (err) {
      console.error("Error fetching countries:", err);
      setError("Failed to load countries. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleCountryChange = (e) => {
    const countryIso3 = e.target.value;
    const country = countries.find((c) => c.iso3 === countryIso3);

    if (country) {
      const firstState =
        country.states.length > 0
          ? {
              name: country.states[0].name,
              state_code: country.states[0].state_code,
            }
          : { name: "", state_code: "" };

      onChange({
        country: {
          name: country.name,
          iso3: country.iso3,
          iso2: country.iso2,
        },
        state: firstState,
      });
    }
  };

  const handleStateChange = (e) => {
    const stateCode = e.target.value;
    const country = countries.find((c) => c.iso3 === location.country.iso3);

    if (country) {
      const state = country.states.find((s) => s.state_code === stateCode);

      if (state) {
        onChange({
          ...location,
          state: {
            name: state.name,
            state_code: state.state_code,
          },
        });
      }
    }
  };

  // Get current country object
  const currentCountry = countries.find(
    (c) => c.iso3 === location.country.iso3
  );

  // Check if current country has states
  const hasStates = currentCountry?.states?.length > 0;

  return (
    <div className="locationInputContainer">
      <div className="signupFlowQuestionLabelContainer">
        <label className="locationInputLabel">Where are you located?</label>
        <Tooltip text="This helps us provide legal documents that comply with your local laws and regulations.">
          <div>
            <BsInfoLg className="signupFlowQuestionToolTipIcon" />
          </div>
        </Tooltip>
      </div>

      {loading ? (
        <div className="locationLoading">
          <Oval
            height={20}
            width={20}
            color="#1252f3"
            visible={true}
            ariaLabel="loading-countries"
            secondaryColor="#1252f320"
            strokeWidth={3}
          />
        </div>
      ) : (
        <div className="locationInputs">
          {/* Country dropdown */}
          <select
            value={location.country.iso3}
            onChange={handleCountryChange}
            className="locationSelect"
            aria-label="Select your country"
          >
            {countries.map((country) => (
              <option key={country.iso3} value={country.iso3}>
                {country.name}
              </option>
            ))}
          </select>

          {/* State dropdown - only show if country has states */}
          {hasStates && (
            <select
              value={location.state.state_code}
              onChange={handleStateChange}
              className="locationSelect stateSelect"
              aria-label="Select your state or province"
            >
              {currentCountry.states.map((state) => (
                <option key={state.state_code} value={state.state_code}>
                  {state.name}
                </option>
              ))}
            </select>
          )}
        </div>
      )}

      {error && <p className="locationError">{error}</p>}

      <p className="locationHint">
        We currently support legal documents for {supportedCountries.length}{" "}
        countries
      </p>
    </div>
  );
};

export default StartupLocationInput;
