import React, { useEffect, useState } from "react";
import {
  MdAdd,
  MdEmojiObjects,
  MdOutlineDocumentScanner,
  MdOutlineChat,
} from "react-icons/md";
import { HiOutlineDocumentText } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { FiLogOut, FiSettings } from "react-icons/fi";
import { LuChevronDown, LuChevronUp, LuScanLine } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../redux/actions/userAction";
import { Crisp } from "crisp-sdk-web";
import { navbarLinks, paymentPlans } from "../../data";
import { getPendingFlowsAPI } from "../../api/apiCalls";
import { addNewValue, updateStepper } from "../../redux/actions/stepperActions";
import { BsRocketFill } from "react-icons/bs";
import { IoDocuments, IoSettingsSharp } from "react-icons/io5";
import { BiCustomize, BiSearch } from "react-icons/bi";
import { CgBrowse } from "react-icons/cg";
import { BlocksIcon } from "lucide-react";

export const Navbar = () => {
  const navigate = useNavigate();

  const [showAccountModal, setShowAccountModal] = useState(false);
  const [expandedLinks, setExpandedLinks] = useState(
    navbarLinks.mainLinks.reduce((acc, link, index, array) => {
      acc[link.name] = index !== array.length - 1; // Initialize all links as expanded except the last one
      return acc;
    }, {})
  );

  const user = useSelector((state) => state.user);
  const { pendingFlows } = useSelector((state) => state.stepper);

  const dispatch = useDispatch();

  const logout = () => {
    dispatch(
      updateUser({
        token: null,
        email: null,
        name: null,
      })
    );
    if (Crisp) {
      Crisp?.session.reset();
      Crisp?.chat.hide();
    }

    localStorage.clear();
  };

  const isCurrentLink = (link) => {
    return link === window.location.pathname;
  };

  const getPendingFlows = async () => {
    const response = await getPendingFlowsAPI();

    if (response.data) {
      dispatch(
        updateStepper({
          pendingFlows: response.data,
        })
      );
    }
  };

  useEffect(() => {
    if (!pendingFlows) {
      dispatch(addNewValue());
      getPendingFlows();
    } else {
      getPendingFlows();
    }
  }, []);

  const toggleExpand = (linkName) => {
    setExpandedLinks((prev) => {
      const updatedLinks = {
        ...prev,
        [linkName]: !prev[linkName],
      };
      localStorage.setItem("expandedLinks", JSON.stringify(updatedLinks)); // Persist state to localStorage
      return updatedLinks;
    });
  };

  useEffect(() => {
    const handleRouteChange = () => {
      const savedExpandedLinks = JSON.parse(
        localStorage.getItem("expandedLinks")
      );
      if (savedExpandedLinks) {
        setExpandedLinks(savedExpandedLinks);
      }
    };

    window.addEventListener("popstate", handleRouteChange);
    return () => {
      window.removeEventListener("popstate", handleRouteChange);
    };
  }, []);

  return (
    <div className="navbarContainer">
      <div className="container" style={{ height: "100%" }}>
        <div className="navbar">
          <div className="navbarLogoContent" onClick={() => navigate("/")}>
            <img src="/logoWhiteTransparent.png" className="logoImage" />
            <p className="logoText">Airstrip</p>

            {pendingFlows && pendingFlows?.length !== 0 && (
              <div className="notificationsLength">{pendingFlows.length}</div>
            )}
          </div>

          <div className="navLinks">
            <div className="mainLinksContainer">
              {/* Primary Actions Section */}
              <div className="primaryActionsSection">
                {/* New Chat Button */}
                <div
                  className="mainLinkContainer mainNavButton"
                  onClick={() => navigate("/chat/")}
                  style={{
                    marginBottom: "15px",
                    backgroundColor: "#2a63f2",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    border: "none",
                    display: "flex",
                    alignItems: "center",
                    padding: "10px 15px",
                    borderRadius: "8px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "rgba(255, 255, 255, 0.2)",
                      borderRadius: "6px",
                      padding: "6px",
                      marginRight: "10px",
                    }}
                  >
                    <MdOutlineChat
                      style={{ color: "white", fontSize: "18px" }}
                    />
                  </div>
                  <div>
                    <p
                      style={{
                        color: "white",
                        fontSize: "14px",
                        fontWeight: "600",
                        margin: 0,
                      }}
                    >
                      New Chat
                    </p>
                    <p
                      style={{
                        color: "rgba(255, 255, 255, 0.7)",
                        fontSize: "11px",
                        margin: 0,
                        marginTop: "2px",
                      }}
                    >
                      Ask questions or get help
                    </p>
                  </div>
                </div>

                {/* Create Document Button */}
                <div
                  className="mainLinkContainer mainNavButton"
                  onClick={() => {
                    dispatch(updateUser({ showDocumentsModal: true }));
                  }}
                  style={{
                    backgroundColor: "white",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    border: "1px solid rgba(255, 255, 255, 0.3)",
                    display: "flex",
                    alignItems: "center",
                    padding: "10px 15px",
                    borderRadius: "8px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#2a63f2",
                      borderRadius: "6px",
                      padding: "6px",
                      marginRight: "10px",
                    }}
                  >
                    <MdAdd style={{ color: "white", fontSize: "18px" }} />
                  </div>
                  <div>
                    <p
                      style={{
                        color: "#2a3044",
                        fontSize: "14px",
                        fontWeight: "600",
                        margin: 0,
                      }}
                    >
                      Create Document
                    </p>
                    <p
                      style={{
                        color: "#5a6072",
                        fontSize: "11px",
                        margin: 0,
                        marginTop: "2px",
                      }}
                    >
                      Draft a new legal document
                    </p>
                  </div>
                </div>
              </div>

              {/* Section Divider */}
              <div
                style={{
                  width: "90%",
                  height: "1px",
                  backgroundColor: "rgba(255,255,255,0.1)",
                  margin: "20px auto",
                }}
              ></div>

              <div className="mainNavigationSection">
                {/* Tools Section */}
                <div
                  className="navSectionLabel"
                  style={{
                    color: "rgba(255,255,255,0.5)",
                    fontSize: "12px",
                    fontWeight: "500",
                    padding: "0 10px",
                    marginTop: "20px",
                    marginBottom: "10px",
                  }}
                >
                  Documents
                </div>

                {/* Customise Link */}
                <div
                  className={`mainLinkContainer ${
                    isCurrentLink("/browse-documents") && "currentLinkContainer"
                  }`}
                  onClick={() => navigate("/browse-documents")}
                >
                  <div className="mainLinkContainerContent">
                    <BiSearch className="navLinkIcon" />
                    <p className="navLink">Browse Documents</p>
                  </div>
                </div>

                {/* Simplify Link */}
                <div
                  className={`mainLinkContainer ${
                    isCurrentLink("/my-documents") && "currentLinkContainer"
                  }`}
                  onClick={() => navigate("/my-documents")}
                >
                  <div className="mainLinkContainerContent">
                    <IoDocuments className="navLinkIcon" />
                    <p className="navLink">My Drafts</p>
                  </div>
                </div>
              </div>

              {/* Main Navigation Section */}
              <div className="mainNavigationSection">
                {/* Tools Section */}
                <div
                  className="navSectionLabel"
                  style={{
                    color: "rgba(255,255,255,0.5)",
                    fontSize: "12px",
                    fontWeight: "500",
                    padding: "0 10px",
                    marginTop: "20px",
                    marginBottom: "10px",
                  }}
                >
                  Tools
                </div>

                {/* Customise Link */}
                <div
                  className={`mainLinkContainer ${
                    isCurrentLink("/chat/customise") && "currentLinkContainer"
                  }`}
                  onClick={() => navigate("/chat/customise")}
                >
                  <div className="mainLinkContainerContent">
                    <BlocksIcon className="navLinkIcon" />
                    <p className="navLink">Customise AI</p>
                  </div>
                </div>

                {/* Simplify Link */}
                <div
                  className={`mainLinkContainer ${
                    isCurrentLink("/simplify") && "currentLinkContainer"
                  }`}
                  onClick={() => navigate("/simplify")}
                >
                  <div className="mainLinkContainerContent">
                    <LuScanLine className="navLinkIcon" />
                    <p className="navLink">Simplify</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="navbarRight">
            <div className="accountInfo">
              {user.plan === paymentPlans.FREE_PLAN && (
                <>
                  <div
                    className="navLinkContainer"
                    onClick={() => {
                      dispatch(
                        updateUser({
                          showAppSumoModal: true,
                        })
                      );
                    }}
                  >
                    <img
                      src="/appsumo_icon.png"
                      style={{ width: "20px", height: "20px" }}
                      className="appsumoLogo"
                    />
                    <p className="navLink">Are you from AppSumo?</p>
                  </div>

                  {user.plan === paymentPlans.FREE_PLAN && (
                    <div className="accountInfoPlanButtons">
                      <div
                        className="upgradePlanButton"
                        onClick={() => {
                          dispatch(
                            updateUser({
                              showUpgradeModal: true,
                            })
                          );
                        }}
                      >
                        <p className="upgradePlanButtonText">
                          Upgrade to{" "}
                          <span className="proText">Airstrip Pro</span>
                        </p>
                        <BsRocketFill className="upgradePlanButtonIcon" />
                      </div>
                    </div>
                  )}
                </>
              )}

              {/* <div className="accountInfoPlan">
                <p
                  className="accountInfoPlanText"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <TbDiscount2 fontSize={20} style={{ marginRight: "4px" }} />{" "}
image.png                  <p>Black Friday 🎉</p>
                </p>
                <p
                  className="accountInfoPlanTextLabel annualProAccountInfoPlanTextLabel"
                  style={{ lineHeight: "1.5em", fontSize: 13, marginTop: 3 }}
                >
                  Our Black Friday sale starts now! Get 55% off your annual plan
                  before it drops to 30%
                </p>

                <div className="accountInfoPlanButtons">
                  <div
                    className="upgradePlanButton annualUpgradePlanButton"
                    onClick={() => {
                      window.location.href =
                        "https://app.useairstrip.com/pricing?plan=pro";
                    }}
                  >
                    <p className="upgradePlanButtonText">
                      Upgrade and Get 55% off →
                    </p>
                  </div>
                </div>

                <p className="accountInfoPlanExpire">
                  Expires in {getHoursRemaining()} Hours
                </p>
              </div> */}

              {/* <div
                className={`navLinkContainer ${
                  isCurrentLink("settings") && "currentLinkContainer"
                }`}
                onClick={() => navigate("/settings")}
              >
                <IoSettings className="navLinkIcon" />
                <p className="navLink">Settings</p>
              </div> */}

              {/* {user.plan === paymentPlans.PRO_PLAN && (
                <div
                  className={`navLinkContainer`}
                  onClick={() => Crisp.chat.open()}
                >
                  <BiSupport className="navLinkIcon" />
                  <p className="navLink">Contact support</p>
                </div>
              )} */}

              <a
                className={`navLinkContainer`}
                // onClick={() => Crisp.chat.open()}
                href={`https://tally.so#tally-open=m6jeDk&tally-layout=modal&tally-width=600&tally-emoji-text=👋&tally-emoji-animation=wave&name=${user.name}&email=${user.email}`}
              >
                <MdEmojiObjects className="navLinkIcon" />
                <p className="navLink">Add your Feedback</p>
              </a>

              <div
                className={`accountInfoContent ${
                  showAccountModal && "openedAccountInfoContent"
                }`}
                onClick={() => setShowAccountModal(!showAccountModal)}
              >
                <div className="accountInfoLeft">
                  <p
                    className={`${
                      showAccountModal && "openedAccountIcon"
                    } accountIcon ${
                      user.plan === paymentPlans.PRO_PLAN && "proAccountIcon"
                    }`}
                  >
                    {user.email[0]}
                    {user.email[1]}
                  </p>
                </div>

                <div className="accountInfoRight">
                  <p className="accountInfoName">
                    {user.name}{" "}
                    {user.plan === paymentPlans.PRO_PLAN && (
                      <div className="proPlanText">Pro</div>
                    )}{" "}
                  </p>
                  <p className="accountInfoCompany">{user.companyName}</p>

                  <LuChevronDown className="accountInfoDownIcon" />
                </div>
              </div>

              {showAccountModal && (
                <div className="accountInfoModal">
                  <div className="accountInfoModalHeader">
                    <p className="accountName">{user.companyName}</p>
                    <p className="accountEmail">{user.email}</p>
                  </div>

                  <div
                    style={{ textDecoration: "none" }}
                    onClick={() => navigate("/settings")}
                    className="accountInfoLogout"
                  >
                    <IoSettingsSharp className="accountInfoLogOutIcon" />

                    <p className="accountInfoLogoutText">Settings</p>
                  </div>

                  <a
                    style={{ textDecoration: "none" }}
                    href={`https://tally.so#tally-open=m6jeDk&tally-layout=modal&tally-width=600&tally-emoji-text=👋&tally-emoji-animation=wave&name=${user.name}&email=${user.email}`}
                    className="accountInfoLogout"
                  >
                    <MdEmojiObjects className="accountInfoLogOutIcon" />

                    <p className="accountInfoLogoutText">Send Feedback</p>
                  </a>

                  {/* {user.plan !== paymentPlans.PRO_PLAN && (
                    <div
                      className="accountInfoLogout"
                      onClick={() => {
                        // // //user)

                        // Crisp.session.setData({ token: user.token })
                        Crisp.chat.show();
                        Crisp.chat.open();
                      }}
                    >
                      <BsChatRightTextFill className="accountInfoLogOutIcon" />

                      <p className="accountInfoLogoutText">Contact Support</p>
                    </div>
                  )} */}

                  <div
                    className="accountInfoLogout signoutButton"
                    onClick={() => logout()}
                  >
                    <FiLogOut className="accountInfoLogOutIcon" />

                    <p className="accountInfoLogoutText">Sign out</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
