import * as React from "react";
import * as Avatar from "@radix-ui/react-avatar";
import "../radix-styles/avatar.css";

const AvatarDemo = ({ name, showImage = true }) => (
  <div style={{ display: "flex", gap: 20 }}>
    <Avatar.Root className="AvatarRoot">
      <Avatar.Image
        className="AvatarImage"
        src={
          "https://static.vecteezy.com/system/resources/previews/009/292/244/non_2x/default-avatar-icon-of-social-media-user-vector.jpg"
        }
      />
      {/* //   <Avatar.Fallback className="AvatarFallback" delayMs={600}>
    //     {name}
    //   </Avatar.Fallback> */}
    </Avatar.Root>
  </div>
);

export default AvatarDemo;

// {showImage && avatar.src ? (
//     <Avatar.Image
//       className="AvatarImage"
//       src={avatar.src}
//       alt={avatar.alt}
//     />
//   ) : null}
