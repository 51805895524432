import React from "react";
import { BsArrowRightShort, BsBuilding, BsPerson } from "react-icons/bs";
import { Oval } from "react-loader-spinner";
import "../Onboarding.css";

const Step1 = ({
  selectedUserType,
  setSelectedUserType,
  onboardingInputs,
  setOnboardingInputs,
  onboardingLoading,
  addOnboardingDetailsAPI,
  setOnboardingStepNumber,
}) => {
  return (
    <>
      <div className="appOnboardingHeader">
        <img
          src="/blueTransparent.png"
          className="appOnboardingLogo"
          alt="Airstrip Logo"
        />
        <h1 className="appOnboardingHeaderText">Welcome to Airstrip AI</h1>
        <p className="appOnboardingHeaderDescription">
          Airstrip helps you get lawyer-level legal documents with the best-in
          class AI. Let's get you setup for that in seconds!
        </p>
      </div>

      {/* <div className="appOnboardingDivider"></div> */}

      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (selectedUserType === "individual") {
            addOnboardingDetailsAPI();
          } else {
            setOnboardingStepNumber(2);
          }
        }}
        className="appOnboardingMain"
      >
        <p className="stepLabel">Step 1 of 3</p>

        <div className="appOnboardingInputContainer">
          <label className="label" htmlFor="userType">
            Select the type of account
          </label>

          <div className="accountTypeSelector">
            <div
              className={`accountTypeOption ${
                selectedUserType === "individual" ? "accountTypeSelected" : ""
              }`}
              onClick={() => setSelectedUserType("individual")}
            >
              <BsPerson size={24} className="accountTypeIcon" />
              <div className="accountTypeText">
                <h3>Individual</h3>
                <p>Suitable for freelancers and personal use</p>
              </div>
              {selectedUserType === "individual" && (
                <div className="accountTypeCheck">✓</div>
              )}
            </div>

            <div
              className={`accountTypeOption ${
                selectedUserType === "company" ? "accountTypeSelected" : ""
              }`}
              onClick={() => setSelectedUserType("company")}
            >
              <BsBuilding size={24} className="accountTypeIcon" />
              <div className="accountTypeText">
                <h3>Company</h3>
                <p>Suitable for startups and small businesses</p>
              </div>
              {selectedUserType === "company" && (
                <div className="accountTypeCheck">✓</div>
              )}
            </div>
          </div>
        </div>

        {selectedUserType === "company" && (
          <div className="appOnboardingInputContainer">
            <label className="label" htmlFor="companyName">
              Company Name
            </label>
            <input
              className="input"
              autoFocus
              placeholder="Acme Inc."
              id="companyName"
              value={onboardingInputs.company}
              onChange={(e) => {
                setOnboardingInputs({
                  ...onboardingInputs,
                  company: e.target.value,
                });
              }}
              required
            />
            {onboardingInputs.company && (
              <p className="input-helper-text">Company name looks good!</p>
            )}
          </div>
        )}

        <div className="appOnboardingMainButtons">
          <button
            type="submit"
            className={`onboardingNextButton ${
              onboardingLoading ? "disabledButton" : ""
            }`}
            disabled={
              onboardingLoading ||
              (selectedUserType === "company" && !onboardingInputs.company)
            }
          >
            <p className="onboardingButtonText">
              {selectedUserType === "individual"
                ? "Complete Setup"
                : "Continue"}
            </p>
            <BsArrowRightShort size={20} />
            {onboardingLoading && (
              <Oval
                height={14}
                width={14}
                color="#ffffff"
                wrapperStyle={{ marginLeft: 5 }}
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#ffffff30"
                strokeWidth={5}
                strokeWidthSecondary={5}
              />
            )}
          </button>
        </div>
      </form>
    </>
  );
};

export default Step1;
