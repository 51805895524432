import React from "react";
import { animated } from "@react-spring/web";
import { useDispatch } from "react-redux";
import { updateUser } from "../../redux/actions/userAction";
import "./Onboarding.css";

const OnboardingModal = ({
  children,
  onboardingModalProps,
  onboardingSuccess,
}) => {
  const dispatch = useDispatch();

  return (
    <div className="appOnboardingModal">
      <animated.div
        style={onboardingModalProps}
        className={`appOnboardingModalContent ${
          onboardingSuccess && "appOnboardingModalContentSuccess"
        }`}
      >
        <div className="appOnboardingContent">{children}</div>
      </animated.div>
      <div
        className="appOnboardingModalOverlay"
        onClick={() => {
          dispatch(
            updateUser({
              isOnboardingCompleted: true,
            })
          );
        }}
      ></div>
    </div>
  );
};

export default OnboardingModal;
