import React, { useState, useEffect, useRef } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { IoMdClose } from "react-icons/io";
import { BiLink, BiShareAlt } from "react-icons/bi";
import { MdEmail, MdCheck, MdContentCopy } from "react-icons/md";
import { Oval } from "react-loader-spinner";
import { shareDocumentAPI, getShareableLinkAPI } from "../../api/apiCalls";
import "./ShareDialog.css";

const ShareDialog = ({ isOpen, onClose, documentId }) => {
  // Share options
  const [activeOption, setActiveOption] = useState("link"); // "email" or "link"

  // Email sharing state
  const [emails, setEmails] = useState("");
  const [emailsArray, setEmailsArray] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [emailSuccess, setEmailSuccess] = useState(false);

  // Link sharing state
  const [shareableLink, setShareableLink] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [copied, setCopied] = useState(false);

  // Common state
  const [error, setError] = useState("");

  const emailInputRef = useRef(null);

  // Reset state when modal closes
  useEffect(() => {
    if (!isOpen) {
      setActiveOption("link");
      setEmails("");
      setEmailsArray([]);
      setEmailSuccess(false);
      setError("");
      setShareableLink("");
      setCopied(false);
    } else {
      // Fetch link when modal opens
      fetchShareableLink();
    }
  }, [isOpen]);

  // Focus email input when switching to email option
  useEffect(() => {
    if (isOpen && activeOption === "email" && emailInputRef.current) {
      setTimeout(() => {
        emailInputRef.current.focus();
      }, 300);
    }
  }, [isOpen, activeOption]);

  // Parse emails from input
  useEffect(() => {
    if (emails.trim()) {
      const emailList = emails
        .split(",")
        .map((email) => email.trim())
        .filter((email) => email !== "");
      setEmailsArray(emailList);
    } else {
      setEmailsArray([]);
    }
  }, [emails]);

  const fetchShareableLink = async () => {
    setIsLoading(true);
    try {
      // Call the API to generate a shareable link
      const response = await getShareableLinkAPI(documentId);

      if (
        response.data &&
        response.data.success &&
        response.data.shareableLink
      ) {
        setShareableLink(response.data.shareableLink);
      } else {
        console.error("Failed to generate shareable link:", response.data);
        setShareableLink("Error generating link. Please try again.");
      }
    } catch (error) {
      console.error("Error fetching shareable link:", error);
      setShareableLink("Error generating link. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleShare = async () => {
    if (emailsArray.length === 0) {
      setError("Please enter at least one email address");
      // Shake the input to indicate error
      const input = emailInputRef.current;
      if (input) {
        input.classList.add("shake-animation");
        setTimeout(() => {
          input.classList.remove("shake-animation");
        }, 600);
      }
      return;
    }

    // Basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const invalidEmails = emailsArray.filter(
      (email) => !emailRegex.test(email)
    );

    if (invalidEmails.length > 0) {
      setError(`Invalid email format: ${invalidEmails.join(", ")}`);
      return;
    }

    setError("");
    setIsSending(true);

    try {
      // Call the API to share the document via email
      const response = await shareDocumentAPI({
        draftId: documentId,
        emails: emailsArray,
      });

      if (response.data && response.data.success) {
        setEmailSuccess(true);
      } else {
        console.error("Failed to share document:", response.data);
        setError("Failed to share document. Please try again.");
      }
    } catch (error) {
      console.error("Error sharing document:", error);
      setError("Failed to share document. Please try again.");
    } finally {
      setIsSending(false);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareableLink);
    setCopied(true);

    // Add visual feedback
    const linkInput = document.querySelector(".linkInput");
    if (linkInput) {
      linkInput.classList.add("highlight-animation");
      setTimeout(() => {
        linkInput.classList.remove("highlight-animation");
      }, 1000);
    }

    setTimeout(() => setCopied(false), 2000);
  };

  const handleEmailChange = (e) => {
    setEmails(e.target.value);
    if (error) setError("");

    // Auto-detect emails and create chips when comma or space is typed
    const text = e.target.value;
    if (text.endsWith(",") || text.endsWith(" ")) {
      const emailList = text
        .split(/[,\s]+/)
        .map((email) => email.trim())
        .filter((email) => email !== "");

      if (emailList.length > 0) {
        const lastEmail = emailList[emailList.length - 1];
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (emailRegex.test(lastEmail) && !emailsArray.includes(lastEmail)) {
          const newEmailsArray = [...emailsArray, lastEmail];
          setEmailsArray(newEmailsArray);
          setEmails("");
        }
      }
    }
  };

  const handleKeyDown = (e) => {
    // Share on Enter if there are emails and we're not already sending
    if (
      e.key === "Enter" &&
      !e.shiftKey &&
      emailsArray.length > 0 &&
      !isSending
    ) {
      e.preventDefault();
      handleShare();
      return;
    }

    // Create email chip on Enter if there's valid content
    if (e.key === "Enter" && !e.shiftKey && emails.trim()) {
      e.preventDefault();
      const email = emails.trim();
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (emailRegex.test(email) && !emailsArray.includes(email)) {
        const newEmailsArray = [...emailsArray, email];
        setEmailsArray(newEmailsArray);
        setEmails("");
      } else if (!emailRegex.test(email)) {
        setError("Please enter a valid email address");
      }
    }
  };

  const removeEmail = (index) => {
    // Get the email that's being removed
    const emailToRemove = emailsArray[index];

    // Create a new array without the email at the specified index
    const newEmailsArray = emailsArray.filter((_, i) => i !== index);

    // Update the emailsArray state
    setEmailsArray(newEmailsArray);

    // If the email to remove is in the current input value, remove it from there too
    if (emails.includes(emailToRemove)) {
      // Remove the email and any trailing comma or space
      const updatedEmails = emails
        .replace(`${emailToRemove},`, "")
        .replace(`${emailToRemove} `, "")
        .replace(emailToRemove, "")
        .trim();

      setEmails(updatedEmails);
    }

    // Clear any error that might be showing
    if (error) setError("");

    // Focus back on input after removing an email
    if (emailInputRef.current) {
      emailInputRef.current.focus();
    }
  };

  return (
    <Dialog.Root open={isOpen} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay className="ShareDialogOverlay" />
        <Dialog.Content className="ShareDialogContent">
          <div className="ShareDialogHeader">
            <div className="ShareDialogIcon">
              <BiShareAlt />
            </div>
            <Dialog.Title className="ShareDialogTitle">
              Share Document
            </Dialog.Title>
            <Dialog.Description className="ShareDialogDescription">
              Choose how you want to share
            </Dialog.Description>
          </div>

          <div className="shareOptionsTabs">
            <button
              className={`shareOptionTab ${
                activeOption === "link" ? "active" : ""
              }`}
              onClick={() => setActiveOption("link")}
            >
              <BiLink />
              <span>Get Link</span>
            </button>
            <button
              className={`shareOptionTab ${
                activeOption === "email" ? "active" : ""
              }`}
              onClick={() => setActiveOption("email")}
            >
              <MdEmail />
              <span>Send Email</span>
            </button>
          </div>

          <div className="ShareDialogBody">
            {activeOption === "email" ? (
              <div className="shareStep emailStep">
                {emailSuccess && (
                  <div className="successMessage">
                    <div className="successIcon">
                      <MdCheck />
                    </div>
                    <p>
                      Sent to {emailsArray.length} recipient
                      {emailsArray.length !== 1 ? "s" : ""}
                    </p>
                  </div>
                )}

                <textarea
                  ref={emailInputRef}
                  className="emailInput"
                  placeholder="ex: jane@example.com, john@google.com"
                  value={emails}
                  onChange={handleEmailChange}
                  onKeyDown={handleKeyDown}
                  disabled={isSending}
                />

                <p className="linkHelpText" style={{ textAlign: "left" }}>
                  Link expires in 2 days.
                </p>

                {error && <p className="errorMessage">{error}</p>}

                <div className="emailChipsContainer">
                  {emailsArray.map((email, index) => (
                    <div key={index} className="emailChip">
                      <span>{email}</span>
                      <IoMdClose
                        className="emailChipRemove"
                        onClick={() => removeEmail(index)}
                        title="Remove email"
                      />
                    </div>
                  ))}
                </div>

                <button
                  className={`shareButton ${
                    emailsArray.length === 0 || isSending ? "disabled" : ""
                  }`}
                  onClick={handleShare}
                  disabled={emailsArray.length === 0 || isSending}
                >
                  {isSending ? (
                    <>
                      <Oval
                        height={16}
                        width={16}
                        color="white"
                        visible={true}
                        ariaLabel="loading"
                        secondaryColor="rgba(255,255,255,0.2)"
                        strokeWidth={4}
                      />
                      <span>Sending...</span>
                    </>
                  ) : (
                    <span>Send</span>
                  )}
                </button>
              </div>
            ) : (
              <div className="shareStep linkStep">
                <div className="shareableLinkContainer">
                  {isLoading ? (
                    <div className="linkLoading">
                      <Oval
                        height={20}
                        width={20}
                        color="#1252f3"
                        visible={true}
                        ariaLabel="loading"
                        secondaryColor="rgba(18, 82, 243, 0.2)"
                        strokeWidth={4}
                      />
                      <span>Creating link...</span>
                    </div>
                  ) : (
                    <>
                      <input
                        type="text"
                        className="linkInput"
                        value={shareableLink}
                        readOnly
                        onClick={(e) => e.target.select()}
                      />
                      <button
                        className={`copyButton ${copied ? "copied" : ""}`}
                        onClick={copyToClipboard}
                        disabled={!shareableLink}
                        title="Copy to clipboard"
                      >
                        {copied ? <MdCheck /> : <MdContentCopy />}
                        {copied ? "Copied" : "Copy"}
                      </button>
                    </>
                  )}
                </div>
                <p className="linkHelpText">
                  Anyone with this link can view the document <br /> Link
                  expires in 2 days.
                </p>
              </div>
            )}
          </div>

          <Dialog.Close asChild>
            <button
              className="ShareDialogCloseButton"
              aria-label="Close"
              title="Close dialog"
            >
              <IoMdClose />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default ShareDialog;
