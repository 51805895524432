import React, { useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { FiChevronLeft } from "react-icons/fi";
import { AiFillEdit } from "react-icons/ai";
import { Oval } from "react-loader-spinner";
import { formatDate } from "../../functions";
import { updateDocument } from "../../redux/actions/documentActions";
import { updateName } from "../../api/apiCalls";
import { useNavigate } from "react-router-dom";

const DocumentHeader = ({ documentInfo, versionId, id }) => {
  const [documentNameInput, setDocumentNameInput] = useState("");
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [saveDocumentNameLoading, setSaveDocumentNameLoading] = useState(false);
  const inputRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (documentInfo && documentInfo?.name) {
      setDocumentNameInput(documentInfo?.name);
    }
  }, [documentInfo]);

  useEffect(() => {
    if (documentInfo) {
      if (documentNameInput !== documentInfo?.name) {
        setShowSaveButton(true);
      } else {
        setShowSaveButton(false);
      }
    }
  }, [documentNameInput, documentInfo]);

  const focusOnDocumentNameInput = () => {
    document.getElementById("finalDocumentHeaderTextInput").focus();
  };

  const updateDocumentName = async () => {
    setSaveDocumentNameLoading(true);

    const response = await updateName({ name: documentNameInput, draftId: id });
    if (response.data) {
      setSaveDocumentNameLoading(false);

      dispatch(
        updateDocument({
          documentInfo: {
            name: documentNameInput,
            ...documentInfo,
          },
        })
      );
    } else {
      setSaveDocumentNameLoading(false);
    }
  };

  return (
    <>
      <FiChevronLeft
        className="finalDocumentHeaderIcon"
        onClick={() => navigate("/my-documents")}
      />

      <div className="finalDocumentHeaderCenter">
        <span
          className="finalDocumentHeaderTextInput"
          id="finalDocumentHeaderTextInput"
          contentEditable="false"
          onInput={(e) => {
            setDocumentNameInput(e.target.textContent);
          }}
          ref={inputRef}
          defaultValue={documentNameInput}
        >
          <p>{documentInfo?.name}</p>
          {!showSaveButton && (
            <AiFillEdit
              onClick={() => focusOnDocumentNameInput()}
              className="documentNameInputChangeIcon"
            />
          )}
        </span>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "5px",
            width: "100%",
          }}
        >
          {showSaveButton && (
            <button
              className={`documentNameSaveButton ${
                saveDocumentNameLoading && "disabledButton"
              }`}
              onClick={() => updateDocumentName()}
              disabled={saveDocumentNameLoading}
            >
              <p className="documentNameSaveButtonText">Save</p>
            </button>
          )}

          <p className="finalDocumentCreatedDate">
            {formatDate(documentInfo?.createdAt)}
          </p>

          <div
            className={`versionIconContainer ${
              (!versionId ||
                documentInfo?.document_drafts?.length - 1 ===
                  parseInt(versionId) - 1) &&
              "latestVersionIconContainer"
            }`}
          >
            <p className="versionText">
              {versionId
                ? parseInt(versionId) - 1 ===
                  documentInfo?.document_drafts?.length - 1
                  ? "Latest"
                  : `v${versionId}`
                : "Latest"}
            </p>
          </div>

          {documentInfo?.isShared && (
            <div
              style={{
                marginLeft: "8px",
                fontSize: "12px",
                color: "#666",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  display: "inline-block",
                  width: "6px",
                  height: "6px",
                  borderRadius: "50%",
                  backgroundColor: "#4285F4",
                  marginRight: "4px",
                }}
              ></span>
              Shared
            </div>
          )}
        </div>

        {documentInfo?.document_drafts?.length === 0 && (
          <>
            <div
              style={{
                background: "rgba(0,0,0,0.06)",
                display: "flex",
                alignItems: "center",
                padding: "10px",
                borderRadius: "10px",
                marginTop: "10px",
              }}
            >
              <Oval
                height="18"
                width="18"
                radius={4}
                strokeWidth={5}
                color="#1253f3"
                secondaryColor="#1253f360"
                ariaLabel="puff-loading"
                wrapperStyle={{}}
                wrapperClass="documentLoading"
                visible={true}
              />
              <p className="documentLoadingText">
                Document is being created...
              </p>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default DocumentHeader;
