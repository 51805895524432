import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getSharedDraftAPI } from "../api/apiCalls";
import { DocumentViewerLoader } from "../components/DocumentViewer/loader";
import * as Toast from "@radix-ui/react-toast";
import {
  BiX,
  BiListUl,
  BiChevronRight,
  BiSolidMessageSquareAdd,
} from "react-icons/bi";
import AuthModal from "../components/AuthModal";
import "./SharedDraftView.css";
import { Stars } from "lucide-react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { HighlightMenu } from "react-highlight-menu";

// Function to process text with sources (copied from DocumentViewer/index.jsx)
const processTextWithSources = (children) => {
  // Handle both string and array of elements (Markdown can pass either)
  const processSingleText = (text) => {
    if (typeof text !== "string") return text;

    const uuidRegex =
      /\[ID: ([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})\]/gi;
    const parts = text.split(uuidRegex);

    return parts.map((part, index) => {
      if (
        part.match(
          /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i
        )
      ) {
        return (
          <sup
            key={index}
            className={`source-ref-document document-${part}`}
            onClick={() => {
              const targetElement = document.getElementById(part);
              console.log(targetElement);
              if (targetElement) {
                targetElement.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            {/* <a
              href={`#source-${part}`}
              title={`Source ${part}`}
              // style={{ color: "#0066cc", textDecoration: "none" }}
            >
              Source
            </a> */}
          </sup>
        );
      }
      return part;
    });
  };

  // Handle arrays of children from Markdown
  if (Array.isArray(children)) {
    return children.map((child, index) =>
      typeof child === "string" ? (
        <React.Fragment key={index}>{processSingleText(child)}</React.Fragment>
      ) : (
        child
      )
    );
  }
  return processSingleText(children);
};

// Custom renderer for Markdown components
const customRenderer = {
  p: ({ children }) => <p>{processTextWithSources(children)}</p>,
  h1: ({ children }) => <h1>{processTextWithSources(children)}</h1>,
  h2: ({ children }) => <h2>{processTextWithSources(children)}</h2>,
  h3: ({ children }) => <h3>{processTextWithSources(children)}</h3>,
  h4: ({ children }) => <h4>{processTextWithSources(children)}</h4>,
  h5: ({ children }) => <h5>{processTextWithSources(children)}</h5>,
  h6: ({ children }) => <h6>{processTextWithSources(children)}</h6>,
  ul: ({ children }) => <ul>{processTextWithSources(children)}</ul>, // Handle <ul>
  li: ({ children }) => <li>{processTextWithSources(children)}</li>, // Handle <li>
  a: ({ children, ...props }) => (
    <a {...props}>{processTextWithSources(children)}</a>
  ),
  blockquote: ({ children }) => (
    <blockquote>{processTextWithSources(children)}</blockquote>
  ),
  strong: ({ children }) => <strong>{processTextWithSources(children)}</strong>,
  em: ({ children }) => <em>{processTextWithSources(children)}</em>,
  code: ({ inline, children }) =>
    inline ? (
      <code>{processTextWithSources(children)}</code>
    ) : (
      <pre>
        <code>{processTextWithSources(children)}</code>
      </pre>
    ),
};

// DocumentViewerMarkdown component - exact copy from DocumentViewer/index.jsx
function DocumentViewerMarkdown({ documentElementsArray, setShowAuthModal }) {
  const containerRef = useRef(null);

  // Custom renderer for Markdown with eachElement.id as the id of the element
  const renderDocument = () => (
    <Markdown
      remarkPlugins={[remarkGfm]}
      className="actualDocumentMarkdown"
      components={customRenderer}
    >
      {documentElementsArray
        ?.map((eachElement) => {
          const text = eachElement?.text ?? "";
          const textWithoutBrackets = text.replace(/\[.*?\]\s*/g, "");
          const id = eachElement?.id ?? "";

          if (eachElement.type === "TITLE") {
            return `# ${textWithoutBrackets} [ID: ${id}]`;
          } else if (eachElement.type === "SUBTITLE") {
            return `## ${textWithoutBrackets} [ID: ${id}]`;
          } else if (eachElement.type === "PARAGRAPH") {
            return `${
              textWithoutBrackets.startsWith("\n")
                ? `${textWithoutBrackets}`
                : `\n${textWithoutBrackets}`
            } [ID: ${id}]`;
          }
          return "";
        })
        .join("\n\n")}
    </Markdown>
  );

  useEffect(() => {
    // After renderDocument is done, process elements to extract id and set as class
    const elements = document.querySelectorAll(".actualDocumentMarkdown *");
    elements.forEach((element) => {
      const match = element.textContent.match(/\[id:(\d+)\]/);
      if (match) {
        const id = match[1];
        element.classList.add(`id-${id}`);
        element.textContent = element.textContent
          .replace(/\[id:\d+\]/, "")
          .trim();
      }
    });
  }, [documentElementsArray]);

  const handleKeydown = (e) => {
    if (
      (e.key === "ArrowRight" && (e.metaKey || e.ctrlKey)) ||
      (e.key === ">" && (e.metaKey || e.ctrlKey))
    ) {
      // Do something
      console.log("Do something");
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeydown);
    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  }, []);

  return (
    <div ref={containerRef} style={{ position: "relative" }}>
      <HighlightMenu
        target=".actualDocumentMarkdown"
        allowedPlacements={["top", "bottom"]}
        style={{ boxShadow: "none !important" }}
        menu={({ selectedText = "", setClipboard, setMenuOpen }) => (
          <div className="highlightMenuContent">
            <div
              className="eachMenuHighlight"
              onClick={() => {
                // In SharedDraftView, we don't have access to dispatch and updateUser
                // So we'll just show a message that this would require signing up
                setShowAuthModal(true);
              }}
            >
              <BiSolidMessageSquareAdd fontSize={18} />
              <p>Ask Pilot</p>
            </div>
          </div>
        )}
      />
      {renderDocument()}
    </div>
  );
}

const SharedDraftView = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [draftData, setDraftData] = useState(null);
  const [documentElements, setDocumentElements] = useState([]);
  const [scrolled, setScrolled] = useState(false);
  const contentRef = useRef(null);
  const [showSignupAlert, setShowSignupAlert] = useState(false);
  const [showMiniSignup, setShowMiniSignup] = useState(false);
  const [tableOfContents, setTableOfContents] = useState([]);
  const [showToc, setShowToc] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Show signup alert after a short delay
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSignupAlert(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const fetchSharedDraft = async () => {
      try {
        setLoading(true);
        // Add a small delay before making the API call to ensure any initialization is complete
        await new Promise((resolve) => setTimeout(resolve, 500));
        const response = await getSharedDraftAPI(token);

        if (response.data) {
          setDraftData(response.data.draft);

          // Set document elements from the draft content
          if (response.data.document_drafts) {
            const content = response.data.document_drafts[0].content;
            setDocumentElements(content);

            // Generate table of contents from titles and subtitles
            const toc = content
              .filter(
                (element) =>
                  element.type === "TITLE" || element.type === "SUBTITLE"
              )
              .map((element, index) => ({
                id: element.id,
                text: element.text,
                type: element.type,
                level: element.type === "TITLE" ? 1 : 2,
                index,
              }));

            setTableOfContents(toc);
          }
        } else {
          setError(
            "This document is no longer available or the link has expired."
          );
        }
      } catch (err) {
        console.error("Error fetching shared draft:", err);
        setError(
          "Unable to load the document. The link may be invalid or expired."
        );

        // If there's an error, try fetching again after a short delay
        // This helps in cases where the initial load fails but a refresh works
        setTimeout(() => {
          if (loading) {
            fetchSharedDraft();
          }
        }, 1500);
      } finally {
        setLoading(false);
      }
    };

    if (token) {
      fetchSharedDraft();
    } else {
      setError("Invalid document link.");
      setLoading(false);
    }
  }, [token]);

  if (loading) {
    return (
      <div className="sharedDocumentLoadingContainer">
        <DocumentViewerLoader text="Loading document" size="large" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="sharedDraftErrorContainer">
        <div className="sharedDraftErrorContent">
          <div className="sharedDraftErrorIcon">⚠️</div>
          <h2>Document Unavailable</h2>
          <p>{error}</p>
          <button className="primaryButton" onClick={() => navigate("/")}>
            Go to Airstrip AI
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="sharedDocumentContainer">
      {/* Floating navbar */}
      <div className={`sharedDocumentNavbar ${scrolled ? "scrolled" : ""}`}>
        <div className="sharedDocumentNavbarContent">
          <div className="sharedDocumentNavbarLeft">
            <div
              className="sharedDocumentLogo"
              style={{
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => navigate("/")}
            >
              <img
                src="/logowhiteblue.png"
                style={{
                  width: "20px",
                  height: "20px",
                  borderRadius: "50px",
                  marginRight: "5px",
                }}
              />
              <span className="lawpilotLogo">Airstrip AI</span>
            </div>
            <div className="sharedDocumentNavbarDivider"></div>
            <h1 className="sharedDocumentNavbarTitle">
              {draftData?.name || "Shared Document"}
            </h1>
          </div>

          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <button
              style={{
                display: "flex",
                alignItems: "center",
                background: "none",
                border: "none",
                color: "var(--text-primary)",
                cursor: "pointer",
                padding: "8px 0",
                fontSize: "14px",
                fontWeight: "500",
              }}
              onClick={() => {
                // Download functionality would go here
                // alert("Download functionality would be implemented here");
                setShowAuthModal(true);
              }}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginRight: "8px" }}
              >
                <path
                  d="M12 16L12 8"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9 13L11.913 15.913V15.913C11.961 15.961 12.039 15.961 12.087 15.913V15.913L15 13"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 15L3 16L3 19C3 20.1046 3.89543 21 5 21L19 21C20.1046 21 21 20.1046 21 19L21 16L21 15"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Download
            </button>

            <button
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#4361ee",
                color: "white",
                border: "none",
                borderRadius: "6px",
                padding: "8px 16px",
                fontSize: "14px",
                fontWeight: "500",
                cursor: "pointer",
                transition: "background-color 0.15s ease",
              }}
              onClick={() => setShowAuthModal(true)}
            >
              <Stars size={16} style={{ marginRight: "8px", opacity: "0.9" }} />
              AI Assist (free)
            </button>
          </div>
        </div>
      </div>

      {/* Table of Contents Toggle Button */}
      <button
        className="tocToggleButton"
        onClick={() => setShowToc(!showToc)}
        aria-label="Toggle Table of Contents"
      >
        <BiListUl />
        <span>Contents</span>
      </button>

      {/* Table of Contents */}
      {showToc && (
        <div className="tableOfContents">
          <div className="tocHeader">
            <h3>Table of Contents</h3>
            <button
              className="tocCloseButton"
              onClick={() => setShowToc(false)}
              aria-label="Close Table of Contents"
            >
              <BiX />
            </button>
          </div>
          <ul className="tocList">
            {tableOfContents.map((item) => (
              <li
                key={item.id}
                className={`tocItem tocLevel${item.level}`}
                onClick={() => {
                  // Find the element in the markdown content
                  const targetElement = document.querySelector(
                    `.actualDocumentMarkdown [id="${item.id}"], .actualDocumentMarkdown sup.document-${item.id}`
                  );

                  if (targetElement) {
                    // Scroll the element into view with smooth behavior
                    targetElement.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                    });

                    // Add a temporary highlight effect
                    targetElement.classList.add("highlight-section");
                    setTimeout(() => {
                      targetElement.classList.remove("highlight-section");
                    }, 2000);
                  }

                  // Close the TOC on mobile
                  if (window.innerWidth < 768) {
                    setShowToc(false);
                  }
                }}
              >
                {item.level === 2 && <BiChevronRight className="tocItemIcon" />}
                <span>{item.text}</span>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Main content */}
      <div className="sharedDocumentContent" ref={contentRef}>
        <div className="sharedDocumentPaper">
          <div className="sharedDocumentBody">
            <DocumentViewerMarkdown
              documentElementsArray={documentElements}
              setShowAuthModal={setShowAuthModal}
            />
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="sharedDocumentFooter">
        <div className="sharedDocumentFooterContent">
          <div className="sharedDocumentWatermark">
            <span className="createdWithText">Created with</span>
            <span className="brandName">Airstrip AI</span>
            <span className="professionalDocText">
              Professional Legal Documents
            </span>
          </div>
          <div
            className="sharedDocumentQualityBadge"
            onClick={() => setShowAuthModal(true)}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.49991 0.877075C3.84222 0.877075 0.877075 3.84222 0.877075 7.49991C0.877075 11.1576 3.84222 14.1227 7.49991 14.1227C11.1576 14.1227 14.1227 11.1576 14.1227 7.49991C14.1227 3.84222 11.1576 0.877075 7.49991 0.877075ZM11.1573 5.79361L7.5145 10.2937C7.43964 10.3867 7.32568 10.4405 7.20255 10.4405C7.07942 10.4405 6.96546 10.3867 6.8906 10.2937L3.84222 6.65902C3.71165 6.50044 3.73165 6.26265 3.89023 6.13208C4.04882 6.00151 4.28661 6.02151 4.41718 6.18009L7.20255 9.51249L10.5829 5.42063C10.7135 5.26204 10.9513 5.24204 11.1099 5.37262C11.2685 5.50319 11.2885 5.74098 11.1573 5.89956V5.79361Z"
                fill="currentColor"
              />
            </svg>
            <span>Create your document wtih AI</span>
          </div>
        </div>
      </div>

      {/* Mini Signup Button */}
      {showMiniSignup && !showSignupAlert && (
        <div
          className="miniSignupButton"
          onClick={() => {
            setShowMiniSignup(false);
            setShowAuthModal(true);
          }}
        >
          <div className="miniSignupIcon">
            <svg
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 7H13V9H11V7ZM11 11H13V17H11V11Z"
                fill="currentColor"
              />
            </svg>
          </div>
          <span className="miniSignupText">Unlock full access</span>
        </div>
      )}

      {/* Signup Alert */}
      <Toast.Provider swipeDirection="down">
        <Toast.Root
          className="signupAlert"
          open={showSignupAlert}
          onOpenChange={(open) => {
            setShowSignupAlert(open);
            if (!open) {
              // When the main alert is closed, show the mini signup
              setShowMiniSignup(true);
            }
          }}
          duration={Infinity}
        >
          <Toast.Close className="signupAlertClose">
            <BiX />
          </Toast.Close>
          <div className="signupAlertContent">
            <div className="signupAlertIcon">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 7H13V9H11V7ZM11 11H13V17H11V11Z"
                  fill="currentColor"
                />
              </svg>
            </div>
            <div className="signupAlertTextContent">
              <Toast.Title className="signupAlertTitle">
                Get more from this document
              </Toast.Title>
              <Toast.Description className="signupAlertDescription">
                Sign up to chat with this document, make edits, export to
                different formats, and access AI-powered legal insights.
              </Toast.Description>
            </div>
            <div className="signupAlertActions">
              <button
                className="signupAlertButton"
                onClick={() => setShowAuthModal(true)}
              >
                <span>Unlock full access</span>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.33337 8H12.6667"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.66663 4L12.6666 8L8.66663 12"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
              <button
                className="signupAlertSecondaryButton"
                onClick={() => {
                  setShowSignupAlert(false);
                  setShowMiniSignup(true);
                }}
              >
                Continue reading
              </button>
            </div>
          </div>
        </Toast.Root>
        <Toast.Viewport className="signupAlertViewport" />
      </Toast.Provider>

      {/* Auth Modal */}
      <AuthModal
        isOpen={showAuthModal}
        onClose={() => setShowAuthModal(false)}
        documentToken={token}
      />
    </div>
  );
};

export default SharedDraftView;
