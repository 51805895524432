import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useSpring } from "@react-spring/web";
import { IoWarning } from "react-icons/io5";
import { Oval } from "react-loader-spinner";
import { Font } from "@react-pdf/renderer";
import MyCustomFont from "../fonts/Inter.ttf";

import { DocumentViewer } from "../components/DocumentViewer";
import { FinalDocumentChat } from "../components/FinalDocumentChat";
import { FinalDocumentContext } from "../components/FinalDocumentChat/FinalDocumentContext";
import {
  DocumentHeader,
  DocumentActions,
  RemindersModal,
} from "../components/FinalDocument";

import {
  getDocumentPaidStatusAPI,
  getDocumentRemindersAPI,
} from "../api/apiCalls";

import "../radix-styles/toast.css";
import "../radix-styles/dropdown.css";
import "./FinalDocument.css";

// Register font for PDF generation
Font.register({ family: "Inter", src: MyCustomFont });

export const FinalDocument = () => {
  const { id, versionId } = useParams();
  const dispatch = useDispatch();

  // Redux state
  const {
    documentInfo,
    signatures,
    allDocuments,
    documentElements,
    unsavedChanges,
  } = useSelector((state) => state.document);
  const user = useSelector((state) => state.user);
  const { token, ...userData } = user;

  // Chat state
  const [chatInfo, setChatInfo] = useState({
    type: {
      label: "chatWithPilot",
      text: "Pilot",
    },
  });
  const [showChat, setShowChat] = useState(false);
  const [chatSectionProps, setChatSectionProps] = useSpring(() => ({
    opacity: 1,
  }));

  // Document state
  const refreshDocument = useState(true);
  const [documentStatus, setDocumentStatus] = useState(null);
  const [streamStatus, setStreamStatus] = useState("");
  const [overviewData, setOverviewData] = useState(documentInfo?.overview);
  const [showQuickOverview, setShowQuickOverview] = useState(false);
  const [reminders, setReminders] = useState([]);
  const [showRemindersModel, setShowRemindersModel] = useState(false);

  // Element update state
  const [updatingElementId, setUpdatingElementId] = useState(null);
  const [updatingTextStream, setUpdatingTextStream] = useState("");
  const [updatingElementData, setUpdatingElementData] = useState(null);

  // Toggle chat visibility
  const toggleChat = (value, chatType) => {
    switch (value) {
      case "open":
        if (chatType.label === "overview") {
          setChatInfo({
            type: chatType,
            data: {
              question: "Give me a quick overview of this document",
              autoSend: true,
            },
          });
        } else {
          setChatInfo({
            type: chatType,
          });
        }

        setShowChat(true);
        setChatSectionProps({
          from: {
            opacity: 0,
          },
          to: {
            opacity: 1,
          },
        });
        break;
      case "close":
        setChatSectionProps({
          from: {
            opacity: 1,
          },
          to: {
            opacity: 0,
          },
        });

        setTimeout(() => {
          setShowChat(false);
        }, 200);
        break;
      default:
        break;
    }
  };

  // Fetch document paid status
  const getDocumentPaidStatus = async () => {
    const response = await getDocumentPaidStatusAPI({
      documentId: documentInfo?.document,
    });

    if (response.data) {
      setDocumentStatus(response.data.status);
    }
  };

  // Fetch document reminders
  const getReminders = async () => {
    const response = await getDocumentRemindersAPI(id);
    setReminders(response.data);
  };

  // Effect to fetch document paid status
  useEffect(() => {
    if (documentInfo?.document) {
      getDocumentPaidStatus();
    }
  }, [documentInfo, id]);

  // Effect to fetch reminders
  useEffect(() => {
    getReminders();
  }, [id]);

  // Effect to handle document overview
  useEffect(() => {
    if (documentInfo && documentInfo?.document_drafts?.length !== 0) {
      if (documentInfo?.overview && documentInfo?.overview.length !== 0) {
        setOverviewData(documentInfo?.overview);
      }
    }
  }, [documentInfo]);

  // Effect to handle scroll behavior for header
  useEffect(() => {
    const handleScroll = () => {
      const header = document.querySelector(".finalDocumentHeader");
      if (header) {
        if (window.scrollY > 100) {
          header.classList.add("finalDocumentHeaderFixed");
        } else {
          header.classList.remove("finalDocumentHeaderFixed");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="finalDocumentContainer">
      {streamStatus === "PENDING" && (
        <div className="finalDocumentAlertInfo">
          <IoWarning className="finalDocumentAlertInfoIcon" />

          <p className="finalDocumentAlertInfoText">
            Don't refresh or close the browser, a Quick overview of this
            document is being created.
          </p>

          <Oval
            height={20}
            width={20}
            color="#1252f3"
            wrapperStyle={{}}
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#1252f320"
            wrapperClass="finalDocumentAlertInfoLoadingIcon"
            strokeWidth={5}
            strokeWidthSecondary={5}
          />
        </div>
      )}

      <div className="finalDocumentHeader">
        {/* Document Header */}
        <DocumentHeader
          documentInfo={documentInfo}
          versionId={versionId}
          id={id}
        />

        {/* Document Actions */}
        {documentInfo && (
          <DocumentActions
            documentInfo={documentInfo}
            id={id}
            userData={userData}
            documentStatus={documentStatus}
          />
        )}
      </div>

      {/* Reminders Modal */}
      <RemindersModal
        isOpen={showRemindersModel}
        onClose={() => setShowRemindersModel(false)}
        reminders={reminders}
      />

      <div className="finalDocumentRight">
        <FinalDocumentContext>
          <DocumentViewer
            updatingElementId={updatingElementId}
            updatingTextStream={updatingTextStream}
            setUpdatingElementId={setUpdatingElementId}
            setUpdatingTextStream={setUpdatingTextStream}
            updatingElementData={[updatingElementData, setUpdatingElementData]}
            documentInfo={documentInfo}
            documentStatus={documentStatus}
            refreshDocument={refreshDocument}
          />

          {documentInfo?.document_drafts?.length !== 0 ? (
            <FinalDocumentChat
              chatSectionProps={chatSectionProps}
              toggleChat={toggleChat}
              chatInfo={chatInfo}
              updatingElementId={updatingElementId}
              updatingTextStream={updatingTextStream}
              setUpdatingElementId={setUpdatingElementId}
              setUpdatingTextStream={setUpdatingTextStream}
              updatingElementData={[
                updatingElementData,
                setUpdatingElementData,
              ]}
              refreshDocument={refreshDocument}
            />
          ) : (
            <div className="placeholder_chat"></div>
          )}
        </FinalDocumentContext>
      </div>
    </div>
  );
};
