import { CheckIcon, ChevronDownIcon } from "@radix-ui/react-icons";
import React, { useState, useEffect } from "react";
import { AiFillExperiment } from "react-icons/ai";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { LuBrain } from "react-icons/lu";
import { Oval } from "react-loader-spinner";
import Markdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import remarkGfm from "remark-gfm";
import { updateUser } from "../redux/actions/userAction";
import { Check } from "lucide-react";

const processTextWithSources = (children) => {
  // Handle both string and array of elements (Markdown can pass either)
  const processSingleText = (text) => {
    if (typeof text !== "string") return text;

    // Updated regex to match [UUID], (ID: UUID), [UUID(extra)], multiple UUIDs, and prefixed UUIDs like 2.1-UUID
    const uuidRegex =
      /\[([a-zA-Z0-9.-]*[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}(?:, ?[a-zA-Z0-9.-]*[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})*)\]|\(ID: ([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})\)/gi;

    const parts = text.split(uuidRegex);

    return parts.map((part, index) => {
      if (part) {
        // Check if the part contains one or more UUIDs
        const uuidMatches = part.match(
          /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gi
        );

        if (uuidMatches) {
          // Map each UUID to a separate <sup> element
          return uuidMatches.map((uuid, uuidIndex) => (
            <sup
              key={`${index}-${uuidIndex}`}
              className="source-ref"
              onClick={() => {
                const targetElement = document.querySelector(
                  `.document-${uuid}`
                );
                console.log(targetElement);
                if (targetElement) {
                  // Add a temporary highlight effect to the target element
                  targetElement.style.backgroundColor = "black"; // Highlight color

                  // Add a temporary highlight effect to the parent element
                  const parentElement = targetElement.parentElement;
                  if (parentElement) {
                    // Scroll slightly above the parent element
                    const parentOffsetTop =
                      parentElement.getBoundingClientRect().top +
                      window.scrollY -
                      200; // Adjusted to scroll slightly above
                    window.scrollTo({
                      top: parentOffsetTop,
                      behavior: "smooth",
                    });

                    parentElement.classList.add("currentSourceSection");
                  }

                  setTimeout(() => {
                    targetElement.style.backgroundColor = ""; // Remove highlight from target
                    if (parentElement) {
                      parentElement.classList.remove("currentSourceSection");
                    }
                  }, 1500); // Highlight duration
                }
              }}
            >
              <a
                onClick={(e) => e.preventDefault()}
                title={`Source ${uuid}`}
                style={{
                  color: "#0066cc",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                Source
              </a>
            </sup>
          ));
        }
      }
      // Return non-UUID parts as plain text
      return <React.Fragment key={index}>{part}</React.Fragment>;
    });
  };

  // Handle arrays of children from Markdown
  if (Array.isArray(children)) {
    return children.map((child, index) =>
      typeof child === "string" ? (
        <React.Fragment key={index}>{processSingleText(child)}</React.Fragment>
      ) : (
        child
      )
    );
  }
  return processSingleText(children);
};

export const AssistantMessage = ({
  content,
  chatIndex,
  stream,
  role = "assistant",
  toggleShowingReasoning,
  toggleShowingResearch,
  showingReasoning,
  showingResearch,
  onConfirm, // New prop to handle confirmation action
}) => {
  const dispatch = useDispatch();

  // NEW: Check for <approve> tag and replace it with an approved card
  const hasApproveTag = content.includes("<approve>");
  const contentWithoutApprove = hasApproveTag
    ? content.replace("<approve>", "")
    : content;

  // Extract unique sections
  const uniqueResearchText = Array.from(
    new Set(
      contentWithoutApprove
        .split(/<research>|<\/research>/)
        .filter((_, idx) => idx % 2 !== 0)
        .map((text) => text.trim())
    )
  ).join(" ");

  const uniqueReasoningText = Array.from(
    new Set(
      contentWithoutApprove
        .split(/<think>|<\/think>/)
        .filter((_, idx) => idx % 2 !== 0)
        .map((text) => text.trim())
    )
  ).join(" ");

  const uniqueToolText = Array.from(
    new Set(
      contentWithoutApprove
        .split(/<tool>|<\/tool>/)
        .filter((_, idx) => idx % 2 !== 0)
        .map((text) => text.trim())
    )
  ).join(" ");

  const uniqueToolResultText = Array.from(
    new Set(
      contentWithoutApprove
        .split(/<tool_result>|<\/tool_result>/)
        .filter((_, idx) => idx % 2 !== 0)
        .map((text) => text.trim())
    )
  ).join(" ");

  const uniqueUpgradeText = Array.from(
    new Set(
      contentWithoutApprove
        .split(/<upgrade>|<\/upgrade>/)
        .filter((_, idx) => idx % 2 !== 0)
        .map((text) => text.trim())
    )
  ).join(" ");

  // MODIFIED: Extract confirmation text by splitting on <confirmation> only
  const confirmationParts = contentWithoutApprove.split(/<confirmation>/);
  const uniqueConfirmationText =
    confirmationParts.length > 1
      ? confirmationParts[1].trim() // Take everything after <confirmation>
      : "";

  // Custom renderer for all elements
  const customRenderer = {
    p: ({ children }) => <p>{processTextWithSources(children)}</p>,
    h1: ({ children }) => <h1>{processTextWithSources(children)}</h1>,
    h2: ({ children }) => <h2>{processTextWithSources(children)}</h2>,
    h3: ({ children }) => <h3>{processTextWithSources(children)}</h3>,
    h4: ({ children }) => <h4>{processTextWithSources(children)}</h4>,
    h5: ({ children }) => <h5>{processTextWithSources(children)}</h5>,
    h6: ({ children }) => <h6>{processTextWithSources(children)}</h6>,
    ul: ({ children }) => <ul>{processTextWithSources(children)}</ul>, // Handle <ul>
    li: ({ children }) => <li>{processTextWithSources(children)}</li>, // Handle <li>
    a: ({ children, ...props }) => (
      <a {...props}>{processTextWithSources(children)}</a>
    ),
    blockquote: ({ children }) => (
      <blockquote>{processTextWithSources(children)}</blockquote>
    ),
    strong: ({ children }) => (
      <strong>{processTextWithSources(children)}</strong>
    ),
    em: ({ children }) => <em>{processTextWithSources(children)}</em>,
    code: ({ inline, children }) =>
      inline ? (
        <code>{processTextWithSources(children)}</code>
      ) : (
        <pre>
          <code>{processTextWithSources(children)}</code>
        </pre>
      ),
  };

  useEffect(() => {
    const buttons = document.querySelectorAll(".latestDocumentButton");
    if (buttons.length > 1) {
      const lastButton = buttons[buttons.length - 1];
      console.log("Last button found:", lastButton); // Log when the last button is found
      lastButton.scrollIntoView({ behavior: "smooth", block: "center" });
      lastButton.classList.add("highlighted");

      // Ensure the class stays for at least 5 seconds
      setTimeout(() => {
        lastButton.classList.remove("highlighted");
      }, 5000); // Remove the class after 5 seconds
    }
  }, []);

  const reasoningAndResearchSection = (
    <div key="reasoningAndResearch" className="reasoningAndResearchSection">
      {uniqueReasoningText && (
        <div className="reasoningSection">
          <h4
            className="reasoningHeader researchHeader"
            onClick={() => toggleShowingReasoning(chatIndex)}
          >
            <div className="reasoningContainer">
              <LuBrain /> <span>Reasoning</span>
            </div>
            <ChevronDownIcon className="headerChevronDown" />
          </h4>
          {(showingReasoning.includes(chatIndex) || stream) && (
            <div className="reasoningContent">
              <Markdown remarkPlugins={[remarkGfm]} components={customRenderer}>
                {uniqueReasoningText}
              </Markdown>
            </div>
          )}
        </div>
      )}

      {uniqueResearchText && (
        <div className="reasoningSection researchSection">
          <h4
            className="reasoningHeader researchHeader"
            onClick={() => toggleShowingResearch(chatIndex)}
          >
            <div className="reasoningContainer">
              <AiFillExperiment /> <span>Research</span>
            </div>
            <ChevronDownIcon className="headerChevronDown" />
          </h4>
          {(showingResearch.includes(chatIndex) || stream) && (
            <div
              className="reasoningContent researchContent"
              style={{ borderBottom: "none" }}
            >
              <Markdown remarkPlugins={[remarkGfm]} components={customRenderer}>
                {uniqueResearchText}
              </Markdown>
            </div>
          )}
        </div>
      )}

      {uniqueToolText && (
        <div
          className="reasoningSection toolSection updateDocumentSection"
          style={{ marginBottom: "30px" }}
        >
          <h4 className="reasoningHeader researchHeader">
            <div className="reasoningContainer">
              {uniqueToolResultText ? (
                <>
                  <BsFillCheckCircleFill fontSize={18} color="green" />
                  <span style={{ marginLeft: "10px" }}>Document updated</span>
                </>
              ) : (
                <>
                  <Oval
                    height={14}
                    width={14}
                    color="black"
                    wrapperStyle={{}}
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#00000020"
                    strokeWidth={5}
                    strokeWidthSecondary={5}
                  />
                  <span style={{ marginLeft: "10px" }}>Updating document</span>
                </>
              )}
            </div>
          </h4>
          <div className="reasoningContent toolContent">
            {uniqueToolResultText ? (
              <div>
                <Markdown
                  remarkPlugins={[remarkGfm]}
                  components={customRenderer}
                >
                  {
                    "Document is updated with your requirements. See the document and confirm the changes."
                  }
                </Markdown>
                <button
                  className="latestDocumentButton"
                  onClick={() => window.location.reload()}
                >
                  View latest document
                </button>
              </div>
            ) : (
              <div className="progressSection">
                <p>Updating the document based on your requirements...</p>
              </div>
            )}
          </div>
        </div>
      )}

      {uniqueUpgradeText && (
        <div className="reasoningSection upgradeSection">
          <h4 className="reasoningHeader researchHeader">
            <div className="reasoningContainer">
              <BsFillCheckCircleFill fontSize={18} color="blue" />
              <span style={{ marginLeft: "10px" }}>Upgrade Available</span>
            </div>
          </h4>
          <div className="reasoningContent upgradeContent">
            <p>
              Upgrade your plan to unlock more features and enhance your
              experience.
            </p>
            <button
              className="upgradeButton"
              onClick={() => {
                // Trigger upgrade modal or redirect to upgrade page
                console.log("Upgrade button clicked");
                dispatch(
                  updateUser({
                    showUpgradeModal: true,
                  })
                );
              }}
            >
              Upgrade Now
            </button>
          </div>
        </div>
      )}
    </div>
  );

  // NEW: Confirmation Section to be rendered below Markdown content
  const confirmationSection = uniqueConfirmationText && (
    <div key="confirmation" className="confirmationSection">
      <div className="confirmationContent">
        <button
          className="approveButton"
          onClick={() => {
            dispatch(
              updateUser({
                chatInput: "<accepted>",
              })
            );
            const chatInputElement = document.querySelector(
              ".finalDocumentChatInputContentArrow"
            );
            console.log(chatInputElement);
            if (chatInputElement) {
              chatInputElement.click();
            }
          }}
        >
          <span>Approve</span>
          <CheckIcon className="approveButtonIcon" />
        </button>
      </div>
      <button
        className="cancelButton"
        onClick={() => {
          dispatch(
            updateUser({
              chatInput: "<accepted>",
            })
          );
          const chatInputElement = document.querySelector(
            ".finalDocumentChatInputContentArrow"
          );
          console.log(chatInputElement);
          if (chatInputElement) {
            chatInputElement.click();
          }
        }}
      >
        Cancel
      </button>
    </div>
  );

  // NEW: Approved Card Section to be rendered when <approve> tag is present
  const approvedCardSection = hasApproveTag && (
    <div key="approved" className="approvedSection">
      <div className="approvedCard">
        <BsFillCheckCircleFill fontSize={18} color="green" />
        <span style={{ marginLeft: "10px" }}>Approved</span>
      </div>
    </div>
  );

  // NEW: Ensure no undefined values are passed to Markdown
  const safeMarkdownContent = (text) => text || "";

  return [
    reasoningAndResearchSection,
    ...contentWithoutApprove
      .split(
        /<think>|<\/think>|<research>|<\/research>|<tool>|<\/tool>|<tool_result>|<\/tool_result>|<upgrade>|<\/upgrade>|<confirmation>/
      )
      .map((part, index) => {
        if (index % 2 === 0) {
          // Prevent rendering undefined or empty content
          if (!part || part.trim() === "") return null;
          return (
            <Markdown
              key={index}
              remarkPlugins={[remarkGfm]}
              components={customRenderer}
            >
              {safeMarkdownContent(part)}
            </Markdown>
          );
        }
        return null;
      }),
    // confirmationSection, // NEW: Add confirmation section below Markdown content
    // approvedCardSection, // NEW: Add approved card section if <approve> tag is present
  ];
};
