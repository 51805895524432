import React, { useState, useRef, useEffect } from "react";
import { BsArrowRight } from "react-icons/bs";
import { BiX, BiCheck } from "react-icons/bi";
import { animated, useSpring } from "@react-spring/web";
import { Oval } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { updateUser } from "../../redux/actions/userAction";
import { registerAPI, signinAPI } from "../../api/apiCalls";
import { isAllowedToContinueForm } from "../../functions";
import "./AuthModal.css";
import { Check } from "lucide-react";

const AuthModal = ({ isOpen, onClose, documentToken }) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("signup");
  const [loading, setLoading] = useState(false);
  const [loadingStep, setLoadingStep] = useState(0); // 0: not loading, 1: creating account, 2: logging in, 3: setting up document
  const [error, setError] = useState(null);
  const [userVerified, setUserVerified] = useState(false);

  const nameInputRef = useRef();
  const emailInputRef = useRef();
  const loginEmailInputRef = useRef();

  const [signupInputs, setSignupInputs] = useState({
    name: "",
    email: "",
    password: "",
  });

  const [loginInputs, setLoginInputs] = useState({
    email: "",
    password: "",
  });

  // Focus the first input field when the modal opens or tab changes
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        if (activeTab === "signup") {
          nameInputRef.current?.focus();
        } else {
          loginEmailInputRef.current?.focus();
        }
      }, 100);
    }
  }, [isOpen, activeTab]);

  // Simple fade-in animation for modal
  const modalAnimation = useSpring({
    opacity: isOpen ? 1 : 0,
    config: {
      duration: 250,
    },
  });

  // Simple animation for success checkmark
  const [checkMarkProps, setCheckMarkProps] = useSpring(() => ({
    opacity: 0,
  }));

  const checkMarkTransition = () => {
    setCheckMarkProps({
      opacity: 1,
      config: { duration: 300 },
    });
  };

  const changeSignupInput = (label, value) => {
    setSignupInputs({
      ...signupInputs,
      [label]: value,
    });
    setError(null);
  };

  const changeLoginInput = (label, value) => {
    setLoginInputs({
      ...loginInputs,
      [label]: value,
    });
    setError(null);
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setError(null);
      setLoadingStep(1); // Creating account

      // Include document token in signup data
      const signupResponse = await registerAPI({
        name: signupInputs.name,
        email: signupInputs.email,
        password: signupInputs.password,
        documentToken: documentToken, // Pass the document token
      });

      if (signupResponse.data) {
        setLoadingStep(2); // Logging in

        // Immediately log in the user after successful signup
        try {
          const loginResponse = await signinAPI({
            email: signupInputs.email,
            password: signupInputs.password,
          });

          if (loginResponse.data) {
            setLoadingStep(3); // Setting up document

            // Update user state with login response data
            dispatch(
              updateUser({
                id: loginResponse.data.user._id,
                name: loginResponse.data.user.name,
                email: loginResponse.data.user.email,
                token: loginResponse.data.token,
                onboardingShown: true,
                isOnboardingCompleted:
                  loginResponse.data.user.isOnboardingCompleted || true,
                companyName: loginResponse.data.user.companyName || "",
                country: loginResponse.data.user.country || "",
                plan: loginResponse.data.user.plan,
              })
            );

            setUserVerified(true);
            checkMarkTransition();

            // Shorter timeout for better UX
            setTimeout(() => {
              onClose();
              window.location.href = "/"; // Redirect to home page
            }, 1000);
          }
        } catch (loginError) {
          console.error("Auto-login failed:", loginError);
          // Even if auto-login fails, show success for signup
          setUserVerified(true);
          checkMarkTransition();

          setTimeout(() => {
            onClose();
            window.location.href = "/login"; // Redirect to login page if auto-login fails
          }, 1000);
        }
      }
    } catch (error) {
      setError(
        error.response?.data?.message || "An error occurred during signup"
      );
    } finally {
      setLoading(false);
      setLoadingStep(0);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setError(null);
      setLoadingStep(2); // Logging in

      const response = await signinAPI({
        email: loginInputs.email,
        password: loginInputs.password,
      });

      if (response.data) {
        setLoadingStep(3); // Setting up document

        dispatch(
          updateUser({
            id: response.data.user._id,
            name: response.data.user.name,
            email: response.data.user.email,
            token: response.data.token,
            onboardingShown: true,
            isOnboardingCompleted: response.data.user.isOnboardingCompleted,
            companyName: response.data.user.companyName,
            country: response.data.user.country,
            plan: response.data.user.plan,
          })
        );

        setUserVerified(true);
        checkMarkTransition();

        setTimeout(() => {
          onClose();
          window.location.href = "/"; // Redirect to home page
        }, 1000); // Shorter timeout for better UX
      }
    } catch (error) {
      setError(error.response?.data?.message || "Invalid email or password");
    } finally {
      setLoading(false);
      setLoadingStep(0);
    }
  };

  // Handle escape key to close modal
  useEffect(() => {
    const handleEscKey = (e) => {
      if (e.key === "Escape" && isOpen) {
        onClose();
      }
    };

    window.addEventListener("keydown", handleEscKey);
    return () => window.removeEventListener("keydown", handleEscKey);
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="authModalOverlay" onClick={onClose}>
      <animated.div
        style={modalAnimation}
        className="authModal"
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside modal
      >
        <button className="authModalCloseButton" onClick={onClose}>
          <BiX />
        </button>

        <div className="authModalHeader">
          <div className="authModalLogo" style={{ marginBottom: "15px" }}>
            <img
              src="/logowhiteblue.png"
              style={{
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                marginRight: "10px",
              }}
            />
          </div>
          <h2 className="authModalTitle">
            Do more with this document using AI
          </h2>
          <p className="authModalDescription">
            Update and chat with contracts using state-of-the-art AI{" "}
            <b style={{ fontStyle: "italic" }}>within *10* minutes.</b>
          </p>
          <p
            style={{
              fontSize: "12px",
              color: "#1252f3",
              backgroundColor: "#1252f316",
              padding: "4px 8px",
              borderRadius: "20px",
              display: "inline-block",
              marginTop: "0px",
            }}
          >
            <Check size={11} /> Used by 1,000 + businesses
          </p>
        </div>

        {/* Tabs removed */}

        <div className="authModalContent">
          {activeTab === "signup" ? (
            <form onSubmit={handleSignup} className="authModalForm">
              <div className="authModalInputRow">
                <div className="authModalInputContainer">
                  <label className="authModalInputLabel" htmlFor="signup-name">
                    Name
                  </label>
                  <input
                    id="signup-name"
                    ref={nameInputRef}
                    type="text"
                    className="authModalInput"
                    placeholder="Enter your name"
                    autoFocus
                    value={signupInputs.name}
                    onChange={(e) => changeSignupInput("name", e.target.value)}
                    required
                    minLength={2}
                  />
                </div>

                <div className="authModalInputContainer">
                  <label className="authModalInputLabel" htmlFor="signup-email">
                    Email
                  </label>
                  <input
                    id="signup-email"
                    ref={emailInputRef}
                    type="email"
                    required
                    className="authModalInput"
                    placeholder="Enter your email"
                    value={signupInputs.email}
                    onChange={(e) => changeSignupInput("email", e.target.value)}
                  />
                </div>
              </div>

              <div className="authModalInputContainer">
                <label
                  className="authModalInputLabel"
                  htmlFor="signup-password"
                >
                  Password
                </label>
                <input
                  id="signup-password"
                  type="password"
                  className="authModalInput"
                  placeholder="Create a secure password"
                  minLength={7}
                  value={signupInputs.password}
                  onChange={(e) =>
                    changeSignupInput("password", e.target.value)
                  }
                  required
                />
              </div>

              {error && <p className="authModalErrorText">{error}</p>}

              <button
                disabled={!isAllowedToContinueForm(signupInputs) || loading}
                type="submit"
                className={`authModalButton ${
                  !isAllowedToContinueForm(signupInputs) && "disabledButton"
                }`}
              >
                {userVerified ? (
                  <>
                    <p className="authModalButtonText">Success!</p>
                    <animated.div
                      style={checkMarkProps}
                      className="authModalCheckIconContainer authModalSuccessIcon"
                    >
                      <BiCheck className="authModalCheckIcon" />
                    </animated.div>
                  </>
                ) : loading ? (
                  <>
                    <p className="authModalButtonText">
                      {loadingStep === 1 && "Creating account..."}
                      {loadingStep === 2 && "Logging in..."}
                      {loadingStep === 3 && "Setting up document..."}
                      {loadingStep === 0 && "Processing..."}
                    </p>
                    <Oval
                      height={20}
                      width={20}
                      color="white"
                      wrapperStyle={{ marginLeft: 5 }}
                      wrapperClass="authModalButtonIcon"
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#ffffff7b"
                      strokeWidth={3}
                      strokeWidthSecondary={3}
                    />
                  </>
                ) : (
                  <>
                    <p className="authModalButtonText">Sign up</p>
                    <BsArrowRight className="authModalButtonIcon" />
                  </>
                )}
              </button>

              <div className="authModalSwitchContainer">
                <p className="authModalSwitchText">
                  Already have an account?{" "}
                  <button
                    type="button"
                    className="authModalSwitchButton"
                    onClick={() => {
                      setActiveTab("login");
                      setError(null);
                    }}
                  >
                    Login
                  </button>
                </p>
              </div>
            </form>
          ) : (
            <form onSubmit={handleLogin} className="authModalForm">
              <div className="authModalInputContainer">
                <label className="authModalInputLabel" htmlFor="login-email">
                  Email
                </label>
                <input
                  id="login-email"
                  ref={loginEmailInputRef}
                  type="email"
                  className="authModalInput"
                  placeholder="Enter your email"
                  autoFocus
                  value={loginInputs.email}
                  onChange={(e) => changeLoginInput("email", e.target.value)}
                  required
                />
              </div>

              <div className="authModalInputContainer">
                <label className="authModalInputLabel" htmlFor="login-password">
                  Password
                </label>
                <input
                  id="login-password"
                  type="password"
                  className="authModalInput"
                  placeholder="Enter your password"
                  value={loginInputs.password}
                  onChange={(e) => changeLoginInput("password", e.target.value)}
                  required
                />
              </div>

              {error && <p className="authModalErrorText">{error}</p>}

              <button
                disabled={!isAllowedToContinueForm(loginInputs) || loading}
                type="submit"
                className={`authModalButton ${
                  !isAllowedToContinueForm(loginInputs) && "disabledButton"
                }`}
              >
                {userVerified ? (
                  <>
                    <p className="authModalButtonText">Success!</p>
                    <animated.div
                      style={checkMarkProps}
                      className="authModalCheckIconContainer authModalSuccessIcon"
                    >
                      <BiCheck className="authModalCheckIcon" />
                    </animated.div>
                  </>
                ) : loading ? (
                  <>
                    <p className="authModalButtonText">
                      {loadingStep === 2 && "Logging in..."}
                      {loadingStep === 3 && "Setting up document..."}
                      {loadingStep !== 2 &&
                        loadingStep !== 3 &&
                        "Processing..."}
                    </p>
                    <Oval
                      height={20}
                      width={20}
                      color="white"
                      wrapperStyle={{ marginLeft: 5 }}
                      wrapperClass="authModalButtonIcon"
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#ffffff7b"
                      strokeWidth={3}
                      strokeWidthSecondary={3}
                    />
                  </>
                ) : (
                  <>
                    <p className="authModalButtonText">Login</p>
                    <BsArrowRight className="authModalButtonIcon" />
                  </>
                )}
              </button>

              <div className="authModalSwitchContainer">
                <p className="authModalSwitchText">
                  Don't have an account?{" "}
                  <button
                    type="button"
                    className="authModalSwitchButton"
                    onClick={() => {
                      setActiveTab("signup");
                      setError(null);
                    }}
                  >
                    Sign up
                  </button>
                </p>
              </div>
            </form>
          )}
        </div>
      </animated.div>
    </div>
  );
};

export default AuthModal;
